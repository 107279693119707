/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef"
import DetailItemsList from "../../details/DetailItemsList"

export default class AdmAdresseInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.more=false]
   **/  
  constructor(options) {
    let defaultOptions = {
      buttonText: "Husnummeret"
    }
     
    super(Object.assign(defaultOptions, options))
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler

  }

  isApplicable(result) {
    return (result.source === "Dawa" && result.typeId === "adresse")
  }

  async myHandler(result) {

    let items = []
    let detailItemsList = new DetailItemsList({header: this.getbuttonText()})
    detailItemsList.append({
      type: "labelvalue",
      value: result.title
    })
    detailItemsList.append({
      type: "labelvalue",
      label: "Id",
      value: result.data.properties.id
    })
    let value = "(" + result.data.properties.vejstykke.kode + ")-(" + result.data.properties.kommune.kode + ") " + result.data.properties.kommune.navn
    detailItemsList.append({
      type: "labelvalue",
      label: "Vejkode og kommune",
      value: value 
    })
    
    let data = await result.searcher.getEnhedsAdresserForAdresse(result.data.properties.id)
    detailItemsList.append({
      type: "labelvalue",
      label: "Antal adresser på dette husnummer",
      value: data.features.length
    })

    detailItemsList.append({
      type: "labelvalue",
      label: "Deprecated",
      value: "AdmAdresseInfoProvider - Brug HusnummerInfo istedet"
    })
    
    items.push(detailItemsList.asItem())
    return items
  }

  /*
  const propertiesExample = {
    "href": "https://dawa.aws.dk/adgangsadresser/0a3f507a-c19e-32b8-e044-0003ba298018",
    "id": "0a3f507a-c19e-32b8-e044-0003ba298018",
    "kvh": "01014680__2A",
    "status": 1,
    "darstatus": 3,
    "vejstykke": {
      "href": "https://dawa.aws.dk/vejstykker/101/4680",
      "navn": "Masnedøgade",
      "adresseringsnavn": "Masnedøgade",
      "kode": "4680"
    },
    "husnr": "2A",
    "navngivenvej": {
      "href": "https://dawa.aws.dk/navngivneveje/6b946154-1daf-40ab-aefd-0f3b1760a29d",
      "id": "6b946154-1daf-40ab-aefd-0f3b1760a29d"
    },
    "supplerendebynavn": null,
    "supplerendebynavn2": null,
    "postnummer": {
      "href": "https://dawa.aws.dk/postnumre/2100",
      "nr": "2100",
      "navn": "København Ø"
    },
    "stormodtagerpostnummer": null,
    "kommune": {
      "href": "https://dawa.aws.dk/kommuner/0101",
      "kode": "0101",
      "navn": "København"
    },
    "ejerlav": {
      "kode": 2000173,
      "navn": "Udenbys Klædebo Kvarter, København"
    },
    "esrejendomsnr": "271846",
    "matrikelnr": "935",
    "historik": {
      "oprettet": "2000-02-05T20:26:09.000",
      "ændret": "2018-07-04T18:00:00.000",
      "ikrafttrædelse": "2000-02-05T20:26:09.000",
      "nedlagt": null
    },
    "adgangspunkt": {
      "id": "0a3f507a-c19e-32b8-e044-0003ba298018",
      "koordinater": [
        724493.65,
        6179460.59
      ],
      "højde": 12.2,
      "nøjagtighed": "A",
      "kilde": 5,
      "tekniskstandard": "TD",
      "tekstretning": 200,
      "ændret": "2002-04-05T00:00:00.000"
    },
    "vejpunkt": {
      "id": "11e8bea6-af45-11e7-847e-066cff24d637",
      "kilde": "Ekstern",
      "nøjagtighed": "B",
      "tekniskstandard": "V0",
      "koordinater": [
        724472.161,
        6179451.408
      ],
      "ændret": "2018-05-03T14:08:02.125"
    },
    "DDKN": {
      "m100": "100m_61794_7244",
      "km1": "1km_6179_724",
      "km10": "10km_617_72"
    },
    "sogn": {
      "href": "https://dawa.aws.dk/sogne/7044",
      "kode": "7044",
      "navn": "Sankt Jakobs"
    },
    "region": {
      "href": "https://dawa.aws.dk/regioner/1084",
      "kode": "1084",
      "navn": "Region Hovedstaden"
    },
    "landsdel": {
      "href": "https://dawa.aws.dk/landsdele/DK011",
      "nuts3": "DK011",
      "navn": "Byen København"
    },
    "retskreds": {
      "href": "https://dawa.aws.dk/retskredse/1101",
      "kode": "1101",
      "navn": "Københavns Byret"
    },
    "politikreds": {
      "href": "https://dawa.aws.dk/politikredse/1470",
      "kode": "1470",
      "navn": "Københavns Politi"
    },
    "opstillingskreds": {
      "href": "https://dawa.aws.dk/opstillingskredse/0001",
      "kode": "0001",
      "navn": "Østerbro"
    },
    "afstemningsområde": {
      "href": "https://dawa.aws.dk/afstemningsomraader/101/5",
      "nummer": "5",
      "navn": "1. Vest"
    },
    "storkreds": {
      "href": "https://dawa.aws.dk/storkredse/1",
      "nummer": "1",
      "navn": "København"
    },
    "valglandsdel": {
      "href": "https://dawa.aws.dk/valglandsdele/A",
      "bogstav": "A",
      "navn": "Hovedstaden"
    },
    "zone": "Byzone",
    "jordstykke": {
      "href": "https://dawa.aws.dk/jordstykker/2000173/935",
      "ejerlav": {
        "kode": 2000173,
        "navn": "Udenbys Klædebo Kvarter, København",
        "href": "https://dawa.aws.dk/ejerlav/2000173"
      },
      "matrikelnr": "935",
      "esrejendomsnr": "271846"
    },
    "bebyggelser": [
      {
        "id": "12337669-d966-6b98-e053-d480220a5a3f",
        "kode": null,
        "type": "bydel",
        "navn": "Østerbro",
        "href": "https://dawa.aws.dk/bebyggelser/12337669-d966-6b98-e053-d480220a5a3f"
      },
      {
        "id": "290f85b8-8c7a-6fd1-e053-d480220af996",
        "kode": 18368,
        "type": "by",
        "navn": "København",
        "href": "https://dawa.aws.dk/bebyggelser/290f85b8-8c7a-6fd1-e053-d480220af996"
      }
    ],
    "brofast": true
  }
}
*/
}