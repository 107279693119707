/**
 * @module
 */
import icons from '../resources/icons'
import EsSearcher from './EsSearcher'

/**
 *
 * @extends module:js/searchers/EsSearcher
 * @example <caption>YAML Declaration: <a href='../examples/builder/'>Example</a></caption>
 _type: Septima.Search.PlanSearcher
 _options:
   searchindexToken: septimaSEARCHDEMO-A7OLGHG2J4
   kommunekode: 101

 * @example <caption> JS options: <a href='../examples/simple.dk/'>Example</a></caption>

 planSearcherOptions = {
    searchindexToken: 'TOKEN'
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.PlanSearcher(planSearcherOptions))
 *
 * @example <caption>ES6:</caption>
 * import PlanSearcher from './searchers/PlanSearcher'
 * controller.addSearcher(new PlanSearcher(planSearcherOptions))
 */

export default class PlanSearcher extends EsSearcher {
  /**
   *
   * @param {Object} options PlanSearcher expects these properties:
   * @param {string} options.searchindexToken Please contact Septima to obtain a valid token
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {string} [options.singular=Vedtaget lokalplan]
   * @param {string} [options.plural=Vedtagne lokalplaner]
   * @api
   */
  constructor(options) {

    options.fields = [
      'plannavn',
      'plannr',
      'anvendelsegenerel',
      'anvendelsespecifik',
      'planid',
      'wkt',
      'doklink',
      'komnr',
      'datovedt'
    ]
    options.searchindexServiceUrl = 'https://new-searchindex.septima.dk/'
    options.index = options.searchindexToken + '/pdk/pdk_vedtaget'
    
    
    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += ''
      let municipalities = options.kommunekode.split(' ')
      options.filterTerm = { 'komnr' : municipalities }
    }else if (options.filter) {
      options.filterTerm = options.filter
    }
    if (typeof options.singular === 'undefined')
      options.singular = "Vedtaget lokalplan"
    if (typeof options.plural === 'undefined')
      options.plural = "Vedtagne lokalplaner"

    options.idField = "properties.planid"
    options.source = "Plansystem"
    options.typeId = "vedtagetlokalplan"

    super(Object.assign({
      iconURI:icons.searchers.planer.result
    },
    options))

  }

  createQuery(queryString) {
    //http://www.elasticsearch.org/guide/en/elasticsearch/guide/current/_cross_fields_queries.html
    let esQuery
    esQuery = {
      "bool": {
        "should": [
          {
            "multi_match": {
              "query": queryString,
              "type": "cross_fields",
              "operator": "and",
              "fields": ["text", "plannavn", "planid", "plannr^2"]
            }
          },
          {
            "match_phrase": {
              "plannavn": {
                "boost": 5,
                "query": queryString
              }
            }
          },
          {
            "match": {
              "plannavn": {
                "boost": 4,
                "operator": "or",
                "query": queryString
              }
            }
          },

          {
            "match_phrase": {
              "text": {
                "boost": 5,
                "query": queryString
              }
            }
          },
          {
            "match_phrase": {
              "anvendelsespecifik": {
                "boost": 2,
                "query": queryString
              }
            }
          },
          {
            "match_phrase": {
              "anvendelsegenerel": {
                "boost": 2,
                "query": queryString
              }
            }
          }
        ]
      }
    }
    return esQuery
  }
  
  async get(id) {
    let cqlUrlurl = 'https://geoserver.plandata.dk/geoserver/wfs?servicename=WFS&typename=pdk:theme_pdk_lokalplan_vedtaget_v&request=GetFeature&version=1.0.0&outputformat=json&CQL_FILTER=planid=' + id
    let data = await this.fetch(cqlUrlurl)
    let queryResult = this.createQueryResult()
    let feature = data.features[0]
    let description = ""
    if (typeof feature.properties.anvendelsespecifik == "undefined") 
      description = 'Plannummer ' + feature.properties.plannr
    else 
      description = 'Plannummer ' + feature.properties.plannr + ' - ' + feature.properties.anvendelsespecifik
    
    let result = queryResult.addResult(this.source, this.typeId, feature.properties.plannavn, description, feature.geometry, feature)
    result.id = id
    return result
  }
  
  async sq(query) {
    let querywkt = query.wkt
    let params = {
      servicename: 'WFS',
      typename: 'pdk:theme_pdk_lokalplan_vedtaget_v',
      request: 'GetFeature',
      version: '1.0.0',
      outputformat: 'json',
      CQL_FILTER: 'intersects(geometri,'+querywkt+')'
    }
    //let cqlUrlurl = 'https://geoserver.plandata.dk/geoserver/wfs?servicename=WFS&typename=pdk:theme_pdk_lokalplan_vedtaget_v&request=GetFeature&version=1.0.0&outputformat=json&CQL_FILTER=intersects(geometri,'+querywkt+')'
    let cqlUrlurl = 'https://geoserver.plandata.dk/geoserver/wfs'
    let data = await this.fetch(cqlUrlurl, {data: params, method: 'post'})
    let queryResult = this.createQueryResult()
    for (let feature of data.features) {
      let description = ""
      if (typeof feature.properties.anvendelsespecifik == "undefined") 
        description = 'Plannummer ' + feature.properties.plannr
      else 
        description = 'Plannummer ' + feature.properties.plannr + ' - ' + feature.properties.anvendelsespecifik
      
      let result = queryResult.addResult(this.source, this.typeId, feature.properties.plannavn, description, feature.geometry, feature)
      result.id = feature.properties.planid
      result.distance = 0
    }
    return queryResult
  }

  getGeometry(hit) {
    return this.translateWktToGeoJsonObject(hit.fields['properties.wkt'][0])
  }

  getDescription(hit) {
    if (typeof hit.fields['properties.anvendelsespecifik'] == "undefined") 
      return 'Plannummer ' + hit.fields['properties.plannr'][0]
    else 
      return 'Plannummer ' + hit.fields['properties.plannr'][0] + ' - ' + hit.fields['properties.anvendelsespecifik'][0]
  }

  getDisplayName(hit) {
    return hit.fields['properties.plannavn'][0]
  }

  async getPlanIdsForPoint(point) {
    const coord = '' + point.coordinates[0] + ' ' + point.coordinates[1]
    let cqlFilter = 'DWITHIN(geometri,POINT(' + coord + '),0,meters)'
    let cqlUrlurl = 'https://geoserver.plandata.dk/geoserver/wfs?servicename=WFS&typename=pdk:theme_pdk_lokalplan_vedtaget_v&request=GetFeature&version=1.0.0&outputformat=json&CQL_FILTER=' + cqlFilter
    let data = await this.fetch(cqlUrlurl)
    let planIds = []
    for (let feature of data.features) 
      planIds.push(feature.properties.planid)
    return planIds
  }

  async getPlansForPoint(point) {
    let ids = await this.getPlanIdsForPoint(point)
    const ps = ids.map(id => this.get(id), this)
    return await Promise.all(ps)
  }

}
