import { JSONPath } from 'jsonpath-plus/dist/index-es'

const jsonpath = {
  nodes(json, path) {
    return JSONPath({
      path,
      json,
      resultType: 'all'
    })
  },
  value(json, path, newValue) {
    const nodes = JSONPath({
      path,
      json,
      resultType: 'all'
    })
    if (nodes.length === 0)
      return
    if (newValue) {
      const pathArray = JSONPath.toPathArray(path)
      const lastPathSegment = pathArray[pathArray.length - 1]
      nodes[0].parent[lastPathSegment] = newValue
      return newValue
    }
    return nodes[0].value
  },
  normalize(path) {
    JSONPath.cache = {}
    return JSONPath.toPathString(JSONPath.toPathArray(path))
  }
}

export default class ClassRegistry  {
  constructor(prefix) {
    this.prefix = prefix ? prefix : ""
    this.registry = new Map()
    this.injectedRegistries = []
    this.types = null
    this.instances = {}
  }
  
  setTypes(types) {
    this.types = types
  }
  
  spawn(prefix) {
    let c = new ClassRegistry(prefix)
    c.parent = this
    return c
  }
  
  inject(otherRegistry) {
    this.injectedRegistries.push(otherRegistry)
  }
  
  
  addClass(class2Add, className) {
    if (typeof className !== 'undefined')
      this.registry.set(className, class2Add)
    else
      throw new Error('ClassRegistry.takeClass called without className')
  }
  
  hasClass(longClassName) {
    //Do I, a parent or an injected classRegistry have the class. Yes is a guarantee that you may call getClass successfully

    //Does an injected registry have?
    for (let injectedRegistry of this.injectedRegistries)
      if (injectedRegistry.hasClass(longClassName))
        return true

    //Do I have
    if (longClassName.indexOf(this.prefix) === 0) {
      let shortName = longClassName.substring(this.prefix.length)
      if (this.registry.has(shortName))
        return true
    }
    
    //Does my parent have
    if (typeof this.parent !== 'undefined')
      return this.parent.hasClass(longClassName)
    return false
  }
  
  getClass(longClassName) {

    for (let injectedRegistry of this.injectedRegistries) {
      let c = injectedRegistry.getClass(longClassName)
      if (c)
        return c
    }

    if (longClassName.indexOf(this.prefix) === 0) {
      let shortName = longClassName.substring(this.prefix.length)
      if (this.registry.has(shortName))
        return this.registry.get(shortName)
    }
    
    if (typeof this.parent !== 'undefined') {
      let c = this.parent.getClass(longClassName)
      if (c)
        return c
    }
  }
  
  forClass(longClassName, options) {
    for (let injectedRegistry of this.injectedRegistries)
      if (injectedRegistry.hasClass(longClassName)) {
        let c = injectedRegistry.getClass(longClassName)
        return this.create(longClassName, c, options)
      }
    if (this.hasClass(longClassName)) {
      let c = this.getClass(longClassName)
      return this.create(longClassName, c, options)
    } 
    throw new Error('Class not found. Class ' + longClassName + ' is not defined')
  }

  create (longClassName, c, options = {}) {
    //Create an instance with a proper id as option
    if (typeof this.instances[longClassName] === 'undefined')
      this.instances[longClassName] = []

    let classCount = this.instances[longClassName].length

    if (typeof options.id === 'undefined')
      options.id = longClassName + "_" + classCount

    let instance = new c(options)

    this.instances[longClassName].push(instance)
    
    return instance
  }


  getClasses() {
    let classesMap = this._getClasses()
    let classes = []
    classesMap.forEach(function(info, className) {
      info.className = className
      classes.push(info)
    })
    return classes
  }
  
  _getClasses() {
    let classes = new Map()
    if (typeof this.parent !== 'undefined')
      this.parent._getClasses().forEach((info, className)=>classes.set(className, info))
    
    this.registry.forEach((type, className)=> {
      let info = {prefix : this.prefix}
      if ((this.types && this.types[className])) {
        info = this.types[className]
        info.prefix = this.prefix
      }
      classes.set(className, info)
    })
    
    for (let injectedRegistry of this.injectedRegistries)
      injectedRegistry._getClasses().forEach((info, className)=>classes.set(className, info))
    
    return classes
  }

  parse(object) {
    let typeNodes = [] // of jsonpath.node
    let refNodes = [] // of jsonpath.node
    let subTypes = [] // {parent: jsonpath.node, child: jsonpath.node, childPath}
    let subRefs = [] // {parent: jsonpath.node, child: jsonpath.node}
    let reffedTypes = [] // {referringNode: jsonpath.node, refersToPath: path}
    //Object may contain "_type", and "_ref" tags
    let cleanObject = JSON.parse(JSON.stringify(object))
    typeNodes = jsonpath.nodes(cleanObject, '$..[?(@._type)]')
    refNodes = jsonpath.nodes(cleanObject, '$..[?(@._ref)]')
    for (let refNode of refNodes) {
      let refersTo = jsonpath.value(cleanObject, refNode.value['_ref'])
      if (typeof refersTo === 'undefined' || refersTo === null) 
        throw new Error("refnode at " + refNode.path + " with value " + refNode.value['_ref'] + " doesn't point to anything")
      else if (typeof refersTo._type === 'undefined')
        jsonpath.value(cleanObject, refNode.path, refersTo)
      else
        reffedTypes.push({referringNode: refNode, refersToPath: jsonpath.normalize(refNode.value['_ref'])})
    }
    for (let typeNode of typeNodes) {
      let subRefNodes = jsonpath.nodes(typeNode.value, '$..[?(@._ref)]')
      for (let subRefNode of subRefNodes)
        subRefs.push({parent: typeNode, child: subRefNode})
      let subTypeNodes = jsonpath.nodes(typeNode.value, '$..[?(@._type)]')
      for (let subTypeNode of subTypeNodes) {
        let childPath = [...JSONPath.toPathArray(typeNode.path), ...JSONPath.toPathArray(subTypeNode.path).slice(1)]
        childPath = JSONPath.toPathString(childPath)
        subTypes.push({parent: typeNode, child: subTypeNode, childPath: childPath})
      }
    }
    return {object2Return: cleanObject, typeNodes, refNodes, subTypes, subRefs, reffedTypes}
  }
  
  get classes() {
    let parentClasses = this.parent ? this.parent.classes : {}
    return Object.assign({}, parentClasses, this.registry)
  }
  
  resolve(object) {
    //Hack since jsonpath cant find first level children
    let parseResult = this.parse(object)
    let typenodeInstances = []
    while (parseResult.typeNodes.length > 0) { 
      let typeNodeCount = parseResult.typeNodes.length
      for (let i = parseResult.typeNodes.length - 1; i >= 0; i--) {
        let typeNode = parseResult.typeNodes[i]
        let optionsChildren = this.optionChildren(typeNode, parseResult)
        if (optionsChildren.length === 0) {
          let instance = this.instantiate(typeNode, parseResult)
          //Remove from typeNodes
          parseResult.typeNodes.splice(i, 1)
          let children = this.children(typeNode, parseResult)
          if (children.length === 0)
            this.insertType(typeNode, instance, parseResult)
          else
            //Insert into collection for later insert
            typenodeInstances.push({typeNode, instance})
        }
      }
      if (parseResult.typeNodes.length === typeNodeCount) {
        // No nodes have been resolved in this pass -> Throw Error
        let unresolvablePaths = parseResult.typeNodes.map((typeNode)=> {
          return{"path": typeNode.path, "children": this.children(typeNode, parseResult)} 
        } )
        throw new Error("Can't resolve: Unresolvable nodes: " + JSON.stringify(unresolvablePaths, null, 2))
      }
    }
    //TBD Eliminate refs to non-types
    while (typenodeInstances.length > 0) {
      let typenodeInstanceCount = typenodeInstances.length
      for (let i = typenodeInstances.length - 1; i >= 0; i--) {
        let {typeNode, instance} = typenodeInstances[i]
        let children = this.children(typeNode, parseResult)
        if (children.length === 0) {
          this.insertType(typeNode, instance, parseResult)
          typenodeInstances.splice(i, 1)
        }
      }
      if (typenodeInstances.length === typenodeInstanceCount) {
        // No nodes have been resolved in this pass -> Throw Error
        let unresolvablePaths = typenodeInstances.map((typenodeInstance)=> {
          return{"path": typenodeInstance.typeNode.path, "children": this.children(typenodeInstance.typeNode, parseResult)}
        } )
        throw new Error("Can't insert: Uninsertable nodes: " + JSON.stringify(unresolvablePaths, null, 2))
      }
    }
    //We got to here - this means all typenodes have been converted to typenodeInstances 
    return parseResult.object2Return
  }

  children(typeNode, parseResult) {
    let children = []
    let pathToCheck = typeNode.path
    for (let subType of parseResult.subTypes)
      if (subType.parent.path === pathToCheck)
        children.push(subType.child.path)
    for (let subRef of parseResult.subRefs)
      if (subRef.parent.path === pathToCheck)
        children.push(subRef.child.path)
    return children
  }
  
  optionChildren(typeNode, parseResult) {
    let children = []
    let pathToCheck = typeNode.path
    for (let subType of parseResult.subTypes)
      if (subType.parent.path === pathToCheck && JSONPath.toPathArray(subType.child.path)[1] === '_options')
        children.push(subType.child.path)
    for (let subRef of parseResult.subRefs)
      if (subRef.parent.path === pathToCheck && JSONPath.toPathArray(subRef.child.path)[1] === '_options')
        children.push(subRef.child.path)
    return children
  }

  insertType(typeNode, instance, parseResult) {
    this.setInstanceProperties(instance, typeNode.value)
    // set the type member to the instance
    let typeNodePath = typeNode.path
    jsonpath.value(parseResult.object2Return, typeNodePath, instance)
    // Remove the subType info
    parseResult.subTypes = parseResult.subTypes.filter(subType => subType.childPath !== typeNodePath)
  }
  
  instantiate(typeNode, parseResult) {
    let typeNodePath = typeNode.path

    //Create an instance
    let instance = this.forClass(typeNode.value['_type'], typeNode.value._options)

    for (var i = parseResult.reffedTypes.length - 1; i >= 0; i--) {
      let reffedType = parseResult.reffedTypes[i]
      //{referringNode: jsonpath.node, refersToPath: path}
      if (reffedType.refersToPath === typeNodePath) {
        //The reffedType is the typeNode
        let referringPath = reffedType.referringNode.path
        // set the _ref member to the instance
        jsonpath.value(parseResult.object2Return, referringPath, instance)
        // Remove the reffed info
        parseResult.reffedTypes.splice(i, 1)
        //parseResult.subRefs = parseResult.subRefs.filter(subRef => jsonpath.stringify(subRef.child.path) !== referringPath)
        parseResult.subRefs = parseResult.subRefs.filter(subRef => jsonpath.normalize(subRef.child.value["_ref"]) !== typeNodePath)
      }
    }
    return instance
  }
  
  setInstanceProperties(instance, typeNodeValue) {
    Object.keys(typeNodeValue).forEach(propertyName => {
      if (propertyName !== '_type' && propertyName !== '_options')
        instance[propertyName] = typeNodeValue[propertyName]
    })
  }

}
