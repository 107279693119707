/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef"
import icons from "../../resources/icons"
/**
 * Viser link til jordforureningsattest på jordrapport.miljoeportal.dk for Dawa.adresse og Kortforsyningen.matrikelnumre resultater
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.DawaSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.JordForureningsattestLinkHandler
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * dawaSearcher.addDetailHandlerDef(new Septima.Search.JordForureningsattestLinkHandler())
 *
 * @example <caption>ES6:</caption>
 * import JordForureningsattestLinkHandler from './searchers/detailhandlers/JordForureningsattestLinkHandler'
 * dawaSearcher.addDetailHandlerDef(new JordForureningsattestLinkHandler())
 * @api
 **/
export default class JordForureningsattestLinkHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {boolean} [options.more=true]
   **/
  constructor(options = {}) {
    
    let defaultOptions = {
      buttonText: "Jordforureningsattest",
      buttonImage: icons.pdf,
      more: true
    }
    super(Object.assign(defaultOptions, options))
    
    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return ((result.source === "Dawa" && result.typeId === "adresse") || (result.source === "Kortforsyningen" && result.typeId === "matrikelnumre"))
    }
  }

  async myHandler(result) {
    var items = []
    let url
    if (result.source === "Dawa" && result.typeId === "adresse") 
      url = "http://jordrapport.miljoeportal.dk/?elav=" + result.data.properties.ejerlav.kode + "&matrnr="+ result.data.properties.jordstykke.matrikelnr
    else 
      url = "http://jordrapport.miljoeportal.dk/?elav=" + result.data.elavskode + "&matrnr=" + result.data.matrnr
      
    items.push(
      { type: 'link',
        icon: this.more ? this.buttonImage : icons.details.link,
        link: url,
        linkTitle: "Se jordforureningsattest"})
    items.push(
      { type: 'labelvalue',
        label: "JordForureningsattestLinkHandler",
        value: "Deprecated - brug OffentligeLinksForAdresseMatrikel istedet "})
    return items
  }
}