import icons from '../resources/icons'
import ResultRenderer from './ResultRenderer'
import domutil from '../../../lib/domutil'


export default class {

  constructor() {
    this.resultActionListeners = null
  }

  setResultActionListeners(listeners) {
    this.resultActionListeners = listeners
  }

  formatItems(items) {
    let list = []
    let item
    let area
    if (items.length > 0 ) {
      item = items[0]
      area = this.formatItem(item)
      if (Array.isArray(area))
        area.forEach((childRow) =>{
          list.push(childRow)
        })
      else
        list.push(area)
    }
    for (let i=1; i<items.length; i++) {
      item = items[i]
      area = this.formatItem(item)
      if (Array.isArray(area))
        area.forEach((childRow) =>{
          list.push(childRow)
        })
      else
        list.push(area)
    }
    return list
  }

  formatItem(detailItem) {
    let area
    if (detailItem.type == "labelvalue")
      area = this.formatLabelValue(detailItem)
    else if (detailItem.type === "link")
      area = this.formatLink(detailItem)
    else if (detailItem.type === "textarea")
      area = this.formatTextArea(detailItem)
    else if (detailItem.type === "area")
      area = this.formatArea(detailItem)
    else if (detailItem.type === "button")
      area = this.formatButton(detailItem)
    else if (detailItem.type === "dom-object")
      area = detailItem.object
    else if (detailItem.type === "image")
      area = this.formatImage(detailItem)
    else if (detailItem.type === "result")
      area = this.formatResult(detailItem)
    else if (detailItem.type === "list")
      area = this.formatList(detailItem)
    else if (detailItem.type === "error")
      area = this.formatError(detailItem)
    return area
  }

  formatList(list) {
    let areas = []
    if (list.header || list.image)
      areas.push( this.formatArea({
        icon: list.image,
        value: list.header
      }))
    for (let item of list.items) {
      if (item.type === "labelvalue") {
        let areaOptions = {
          value: item.value
        }
        if (item.label && !list.isHomogenous)
          areaOptions.label = item.label 
        areas.push( this.formatArea(areaOptions))
      } else if (item.type === "result") {
        item.islistitem = list.isHomogenous
        areas.push(this.formatResult(item))
      }else if (item.type === "link") {
        let area =  this.formatArea({
          value: item.linkTitle,
          clickable: true
        })
        area.addEventListener('click', ()=>{
          window.open(item.link)
          return false
        })
        areas.push(area)
      }
    }

    return areas

  }

  formatLabelValue(listItem) {
    if (!listItem.value)
      return this.formatArea({
        icon: listItem.icon,
        value: listItem.label
      })
    else
      return this.formatArea({
        icon: listItem.icon,
        label: listItem.label,
        value: listItem.value,
        pre: listItem.valueformat === "pre",
      })
  }

  formatError(errorItem) {
    return this.formatArea({
      icon: errorItem.icon,
      label: errorItem.label,
      value: errorItem.value
    })
  }

  formatButton(listItem) {
    return this.formatArea({
      icon: listItem.icon,
      value: listItem.title,
      clickable: true
    })
  }

  formatTextArea(listItem) {
    return this.formatArea({
      icon: listItem.icon,
      label: listItem.label,
      value: listItem.text
    })
  }

  formatLink(listItem) {
    const area = this.formatArea({
      icon: listItem.icon,
      value: listItem.linkTitle,
      fixedIcons: listItem.fixedIcons,
      liveIcons: listItem.liveIcons,
      label: listItem.label,
      clickable: true
    })
    area.addEventListener('click', ()=>{
      window.open(listItem.link)
      return false
    })
    return area
  }

  formatImage(listItem) {
    let area = domutil.createElement('div', 'ssDetailsGrid')
    if (typeof listItem.icon !== 'undefined') {
      area.append(this.createIconCol(domutil.createImageElement(listItem.icon)))
      let value = domutil.createElement('div', 'ssValue')
      value.append(this.createImageCol(listItem.value, listItem.label))
      area.append(value)
    }else{
      let value = domutil.createElement('div', 'ssValue')
      value.append(this.createImageCol(listItem.value, listItem.label))
      area.append(value)
      domutil.addClass(area, 'ssListIndent')
    }
    return area
  }

  formatResult(listItem) {
    let result = listItem.result
    let area = domutil.createElement('div', 'ssDetailsGrid')

    if (typeof result.image !== 'undefined')
      area.append(this.createIconCol(domutil.createImageElement(result.image)))

    const distanceLabel = this.getDistanceLabel(result)
    let htmlTitle = result.title
    if (result.description)
      htmlTitle += ' - ' + result.description

    if (distanceLabel) {
      let label = domutil.createElement('div', 'ssLabel')
      domutil.addClass(label, 'ssTruncate')
      label.append(distanceLabel)
      area.append(label)
      let value = domutil.createElement('div', 'ssValue', result.title)
      domutil.setAttribute(value, "title", htmlTitle)
      domutil.addClass(value, "ssTruncate")
      area.append(value)
    }else{
      if (listItem.label) {
        let label = domutil.createElement('div', 'ssLabel', (listItem.label ? listItem.label : ""))
        area.append(label)
      }
      let value = domutil.createElement('div', 'ssValue', result.title)
      domutil.setAttribute(value, 'title', htmlTitle)
      domutil.addClass(value, 'ssTruncate')
      area.append(value)
    }

    if (typeof this.resultActionListeners.hover !== 'undefined') {
      domutil.addClass(area, 'ssHoverable')
      area.addEventListener("mouseenter", ()=>{
        this.resultHover(result)
      })
      area.addEventListener("mouseleave", ()=>{
        this.resultHover(null)
      })
    }

    let detailsIconCol = null

    if (result.searcher)
      if (!result.searcher.hasdetailHandlerDefs(result)) {
        let customButtonDefs = result.searcher.getCustomButtonDefs(result)

        for (let customButtonDef of customButtonDefs) {
          let button = ResultRenderer.createButton(result, customButtonDef)
          let iconCol = this.createIconCol(button)
          domutil.addClass(iconCol, 'ssGhostBtn')
          area.append(iconCol)
        }
      } else {
        result._doDetail = true
        let detailsIcon = domutil.createImageElement(icons.list.detailsIcon)
        detailsIconCol = this.createIconCol(detailsIcon)
        domutil.addClass(detailsIconCol, 'ssFixedBtn')
      }

    if (typeof this.resultActionListeners.focus !== 'undefined') {
      let focusIcon = domutil.createImageElement(icons.globe)
      focusIcon.addEventListener('click', ()=>{
        this.resultFocus(result)
        return false
      })
      const focusIconCol = this.createIconCol(focusIcon)
      domutil.addClass(focusIconCol, 'ssFixedBtn')
      area.append(focusIconCol)
    }

    if (detailsIconCol !== null)
      area.append(detailsIconCol)

    domutil.addClass(area, 'ssClickable')
    if (this.resultActionListeners && this.resultActionListeners.select) {
      area.addEventListener('click', ()=>{
        this.selectResult(result)
      })
    }

    return area
  }

  createImageCol(url, label) {
    let image = domutil.createImageElement(url, label ? label :"")
    return image
  }

  formatArea(options) {
    // Basale layout
    //options: {icon:, label, value, liveIcons, fixedIcons, truncate, hoverable, clickable, islistheader}
    let area = domutil.createElement('div', 'ssDetailsGrid')

    if (typeof options.icon !== 'undefined')
      area.append(this.createIconCol(domutil.createImageElement(options.icon)))

    if (options.label) {
      let label = domutil.createElement('div', 'ssLabel', options.label)
      domutil.setAttribute(label, "title", options.label)

      if (options.truncate)
        domutil.addClass(label, 'ssTruncate')

      area.append(label)
    }
    let value = domutil.createElement('div', 'ssValue')
    if (options.value) {
      if (options.pre)
        value = domutil.createElementFromHtmlString("<div class='ssValue'><pre>" + options.value + "</pre></div>")
      else
        value = domutil.createElement('div', 'ssValue', options.value)
      domutil.setAttribute(value, "title", options.value)

      if (options.truncate)
        domutil.addClass(value, 'ssTruncate')

    }
    area.append(value)

    if (options.liveIcons)
      for (let liveIcon of options.liveIcons) {
        let liveIconCol = this.createIconCol(domutil.createImageElement(liveIcon))
        domutil.addClass(liveIconCol, 'ssGhostBtn')
        area.append(liveIconCol)
      }


    let detailsIconCol = null
    if (options.clickable)
      domutil.addClass(area, "ssClickable")

    if (options.fixedIcons)
      for (let fixedIcon of options.fixedIcons) {
        let fixedIconCol = this.createIconCol(domutil.createImageElement(fixedIcon))
        domutil.addClass(fixedIconCol, "ssFixedBtn")
        area.append(fixedIconCol)
      }

    if (detailsIconCol !== null)
      area.append(detailsIconCol)

    if (typeof options.icon === 'undefined')
      domutil.addClass(area, "ssListIndent")

    if (options.islistheader)
      domutil.addClass(area, "ssHeader")

    return area
  }

  createIconCol(icon) {
    let iconCol
    if (typeof icon !== 'undefined') {
      iconCol = domutil.createElement('div', 'ssIcon')
      iconCol.append(icon)
    }
    return iconCol
  }

  selectResult(result) {
    if (this.resultActionListeners) {
      if (this.resultActionListeners.hover)
        this.resultActionListeners.hover(null)
      if (this.resultActionListeners.select)
        this.resultActionListeners.select(result)
    }
  }

  resultHover(result) {
    if (this.resultActionListeners)
      this.resultActionListeners.hover(result)
  }

  resultFocus(result) {
    if (this.resultActionListeners) {
      if (this.resultActionListeners.hover)
        this.resultActionListeners.hover(null)

      if (this.resultActionListeners.focus)
        this.resultActionListeners.focus(result)

    }
  }

  getDistanceLabel(thisResult) {
    let distance = thisResult.distance
    let presentationDistanceValue
    let presentationDistanceUnit
    let label = null
    if (thisResult.route) {
      if (distance !== 0) {
        presentationDistanceValue = distance > 999 ? (distance / 1000).toFixed(1) : distance
        presentationDistanceUnit = distance > 999 ? 'km' : 'm'

        let t = this.secondsToTime(thisResult.route.time)
        let time = ""

        if (t.h === 0)
          if (t.m > 0)
            time = t.m + ' <span class="ssSmaller">min</span>'
          else
            time = '&lt;1 <span class="ssSmaller">min</span>'

        else if (t.h === 1)
          time = t.h + ' <span class="ssSmaller">time</span> ' + t.m + ' <span class="ssSmaller">min</span>'
        else if (t.h > 1)
          time = t.h + ' <span class="ssSmaller">timer</span> ' + t.m + ' <span class="ssSmaller">min</span>'


        label = domutil.createElementFromHtmlString('<span>' + presentationDistanceValue + ' <span class="ssSmaller">' + presentationDistanceUnit + '</span> ' + time + '</span>')
      }
    } else {
      if (typeof distance === 'undefined' || distance === null || distance === 0) {
        // do nothing
      } else {
        presentationDistanceValue = distance > 999 ? (distance / 1000).toFixed(1) : distance
        presentationDistanceUnit = distance > 999 ? 'km' : 'm'
        label = domutil.createElementFromHtmlString('<span>' + presentationDistanceValue + ' <span class="ssSmaller">' + presentationDistanceUnit + '</span></span>')
      }
    }
    return label
  }

  secondsToTime(secs) {
    if (secs < 0)
      secs += 24 * 60 * 60


    const hours = (Math.floor(secs / (60 * 60))) % 24

    const divisor_for_minutes = secs % (60 * 60)
    const minutes = Math.floor(divisor_for_minutes / 60)

    const divisor_for_seconds = divisor_for_minutes % 60
    const seconds = Math.ceil(divisor_for_seconds)

    return {
      "h": hours,
      "m": minutes,
      "s": seconds
    }
  }

}
