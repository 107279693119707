/**
 * @module
 */
import icons from  '../../resources/icons'
import DetailsHandlerDef from "../../details/DetailsHandlerDef"
/**
 * Viser links til plandokument på plansystem,dk, Odeum og/eller DKPlan
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.PlanSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.DokLinksForLokalplan
 *     _options:
 *       odeumClientName: Horsens
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * planSearcher.addDetailHandlerDef(new Septima.Search.DokLinksForLokalplan({odeumClientName: "Horsens"}))
 *
 * @example <caption>ES6:</caption>
 * import DokLinksForLokalplan from './searchers/detailhandlers/DokLinksForLokalplan'
 * planSearcher.addDetailHandlerDef(new DokLinksForLokalplan({odeumClientName: "Horsens"}))
 * @api
 **/
export default class DokLinksForLokalplan extends DetailsHandlerDef  {
  /**
   * @param {Object} options
   * @param {boolean} [options.more=true]
   * @param {string} [options.odeumClientName]
   * @param {string} [options.dkplanName]
   **/
  constructor(options) {
    let defaultOptions = {
      buttonText: "Plandokument",
      buttonImage: icons.searchers.planer.result,
      isApplicable: (result) => {
        return (result.source.toLowerCase() === "plansystem" && result.typeId === "vedtagetlokalplan")
      }
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.options = {showPlansystemLink: true}
    if (typeof options !== 'undefined')
      this.options = Object.assign(this.options, options)
  }

  async myHandler(result) {
    var items = []
    if (this.options.showPlansystemLink)
      items.push(
        { type: 'link',
          icon: icons.details.link,
          link: result.data.properties.doklink,
          linkTitle: "Se plandokument i Plansystem"})
      
    if (this.options.odeumClientName) {
      let planId = result.data.properties.planid
      let url = 'http://plandk2.mapcentia.com/apps/custom/planurl/public/index.php/api/v1/url/'+this.options.odeumClientName+'/lokalplaner.lpplandk2_join/' + planId
      items.push(
        { type: 'link',
          icon: icons.details.link,
          link: url,
          linkTitle: "Se plandokument i ODEUM (Du viderestilles til Plansystem.dk, hvis Odeum ikke har lokalplanen)"})
    }

    if (this.options.dkplanName) {
      let planNr = result.data.properties.plannr
      let url = 'http://'+this.options.dkplanName+'.viewer.dkplan.niras.dk/dkplan/dkplan.aspx?LokalplanNr=' + planNr
      items.push(
        { type: 'link',
          icon: icons.details.link,
          link: url,
          linkTitle: "Se plandokument i DKPlan"})
    }

    return items
  }
}