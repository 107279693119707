/**
 * @module
 */
import QueryResult from '../QueryResult'
import DetailsHandlerDef from './DetailsHandlerDef'
import {getWKTParser} from "../util/getWKTParser"
import {getString} from "../resources/strings"
import DetailItemsList from "./DetailItemsList"
/**
 * Decorates a result with results that spatially overlap. Does so by querying the sq method of underlying searchers
 * @api
 */
export default class SqDetailsHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.headerListItem] A detailsItem which will be shown before the lists of results
   * @param {Object} [options.footerListItem] A detailsItem which will be shown after the lists of results
   * @param {Object} [options.noResultsListItem] A detailsItem which will be shown if no results are found
   * @param {Object} [options.proxySearcher] A searcher will be queried with sq, the results of this is then used in the sq against the target
   * api
   **/
  constructor(options) {
    super(options)
    this.headerListItem = null
    this.footerListItem = null
    this.noResultsListItem = null
    this.proxySearcher = null
    this.wktParser = getWKTParser()
    this._searchers = []
    this.isApplicableFunction = (result) => result.type.hasGeometry
      
    if (typeof options !== 'undefined') {
      if (options.headerListItem)
        this.headerListItem = options.headerListItem

      if (options.footerListItem)
        this.footerListItem = options.footerListItem

      if (options.noResultsListItem)
        this.noResultsListItem = options.noResultsListItem

      if (options.proxySearcher)
        this.proxySearcher = options.proxySearcher

      if (options.searchers)
        this.searchers = options.searchers

      if (options.isApplicable)
        this.isApplicableFunction = options.isApplicable
    } 

    this.handlerFunction = this.myHandler
  }

  set searchers(sCollection) {
    for (let searcher of sCollection) 
      this.addSearcher(searcher)
  }

  get searchers() {
    return this._searchers
  }

  addSearcher(searcher) {
    this._searchers.push(searcher)
  }

  async extractQueryGeometries(result) {
    let queryGeometry = result.geometry
      
    if (this.proxySearcher) {
      let resultWKT = result.searcher.translateGeoJsonObjectToWkt(result.geometry)
      let sqResult = await this.proxySearcher.sq({limit: 1, wkt: resultWKT})
      let results = sqResult.getAllResults()
      if (results.length > 0) 
        queryGeometry = results[0].geometry
      else
        return null
    }
    return queryGeometry
  }

  async myHandler(result) {
    let queryGeometry = await this.extractQueryGeometries(result)
    let items
    if (queryGeometry !== null) {
      items = await this.doSq(result, queryGeometry)
      return items
    } else {
      return [this.noResultsListItem]
    }
  }

  async doSq(result, queryGeometry) {

    const promises = []
    for (let searcher of this.searchers)
      promises.push(this.doSearcherSq(searcher, queryGeometry))


    let searcherResults = await Promise.all(promises)

    const queryResult = new QueryResult(null)
    for (let searcherResult of searcherResults)
      queryResult.addResults(searcherResult.getResults())

    let groupAndFilterResponse = this.groupAndFilterResults(queryResult)
    let types = groupAndFilterResponse.types
    let allResults = groupAndFilterResponse.allResults

    await this.completeAllResults(allResults)
    let items = this.buildOutput(types)
    return items
  }

  async doSearcherSq(searcher, queryGeometry) {
    const query = {}
    let queryGeometries = []
    if (queryGeometry) 
      if (queryGeometry.type.toLowerCase() === 'point' || queryGeometry.type.toLowerCase() === 'polygon')
        queryGeometries.push(queryGeometry)
      else if (queryGeometry.type.toLowerCase() === 'multipolygon')
        for (let coordinates of queryGeometry.coordinates)
          queryGeometries.push({
            type: "Polygon",
            coordinates
          })
      else if (queryGeometry.type.toLowerCase() === 'multipoint')
        for (let coordinates of queryGeometry.coordinates)
          queryGeometries.push({
            type: "Point",
            coordinates
          })

    let promises = []
    for (let queryGeometry of queryGeometries) {
      let thisQuery = Object.assign({}, query)
      thisQuery.wkt = this.wktParser.convert(queryGeometry)
      promises.push(searcher.sq(thisQuery))
    } 
    let searcherResults = await Promise.all(promises)
    const queryResult = new QueryResult(null)
    for (let searcherResult of searcherResults)
      queryResult.addResults(searcherResult.getResults())
    
    return queryResult
  }
  
  async completeAllResults(results) {
    if (results.length > 0) {
      let completePromises = []
      for (let result of results) 
        completePromises.push(result.complete())
      await Promise.all(completePromises)
    }
  }

  groupAndFilterResults(queryResult) {
    const results = queryResult.getResults()
    const types = []
    const allResults = []
    let currentType = null
    for (let result of results) {
      if (currentType === null || result.typeId !== currentType.type.id) {
        currentType = {type: result.type, results: []}
        if (result.hasOwnProperty("image") && result.image !== null && result.image !== "")
          currentType.image = result.image
        else
          currentType.image = this.defaultTargetImage
        types.push(currentType)
      }
      currentType.results.push(result)
      allResults.push(result)
    }
    return {types: types, allResults: allResults}
  }

  buildOutput(typesWithResults) {
    let items = []

    if (typesWithResults.length === 0) {
      if (this.noResultsListItem !== null) 
        items.push(this.noResultsListItem)
      else
        items.push({
          type: "labelvalue",
          value: getString("noResults")
        })
        
    } else {
      if (this.headerListItem !== null) 
        items.push(this.headerListItem)
        
      for (let typeWithResults of typesWithResults) 
        items.push(this.buildTypeItem(typeWithResults))

      if (this.footerListItem !== null) 
        items.push(this.footerListItem)
        
    }
    return items
  }

  buildTypeItem(typeWithResults) {
    let detailItemsList = new DetailItemsList({itemType: "result", header: typeWithResults.results.length > 1 ? typeWithResults.type.plural : typeWithResults.type.singular, image: typeWithResults.image})
      
    for (let result of typeWithResults.results)
      detailItemsList.append({
        type: "result",
        result: result
      })
    return detailItemsList.asItem()
  }

}
