/**
 * @module
 */
import domutil from '../../../lib/domutil'
import autocomplete from '../../../lib/autocomplete'
// import '../../../lib/jquery.caret.1.02.js'
import { getString } from '../resources/strings'
import icons from '../resources/icons'
import ResultRenderer from './ResultRenderer'

/**
 *
 * Built-in UI for Septima Search
 *
 * @example
 * var view = new View(
 *   {
 *     input : 'septimasearch',
 *     placeholder : 'Search'
 *   });
 * @api
 */
export default class {
  /**
   *
   * @param {Object} options
   * @param options.input {string|Object} Name of DOM container in which to draw the input field or</br>>DOM container or</br>jQuery object representing DOM container
   * @param [options.limit=15] {number}
   * @param options.controller {module:js/Controller}
   * @param [options.detailsLayout] {number}
   */

  constructor(options) {
    if (options === undefined || options.input === undefined)
      throw "View(options): Options.input is missing."

    this.options = options
    if(options.input instanceof Element || options.input instanceof HTMLDocument)
      this.container = options.input
    else
      this.container = document.getElementById(options.input)

    this.limit = 15
    if (options.limit !== undefined)
      this.limit = options.limit

    if (options.controller === undefined)
      throw "View(options): controller is missing."
    else
      this.myController = options.controller

    this.myController.addOnSelectHandler(this.onControllerSelect.bind(this))


    this.detailsLayout = null
    if (options.detailsLayout) {
      this.detailsLayout = options.detailsLayout
      if (this.detailsLayout.setResultActionListeners) {
        let resultActionListeners = []
        if (typeof options.onHover !== 'undefined')
          resultActionListeners['hover'] = (result) => {
            options.onHover(result)
          }

        if (typeof options.onFocus !== 'undefined')
          resultActionListeners['focus'] = (result) => {
            options.onFocus(result)
          }

        if (typeof options.onDetailsClicked !== 'undefined')
          resultActionListeners['detailsClicked'] = (result, panelId) => {
            options.onDetailsClicked(result, panelId)
          }

        resultActionListeners['select'] = (result)=> {
          this.resultSelect(result)
        }

        this.detailsLayout.setResultActionListeners(resultActionListeners)

      }
    }

    this.doMouseOver = true
    if (options.mouseover === false)
      this.doMouseOver = options.mouseover

    this.charLimit = 0
    if (options.charLimit)
      this.charLimit = options.charLimit

    this.placeHolderText = getString("search")
    if (options.placeHolderText)
      this.placeHolderText = options.placeHolderText

    this.detailsButtonCaption = getString("doDetails")
    this.showDescriptionInMore = true

    this.showNewQueryIcon = true

    //Vars
    this.visibleInterests = []
    this.isVisible = false
    this.advancedIsVisible = false
    this.$gui = null
    this.$suggestionContainer = null
    this.$suggestionList = null
    this.header = null
    this.fixedDisplay = null
    this.fixedDisplayHideFunction = null
    this.fixedDisplayText = null
    this.hasFixedDisplay = false
    this.myInputField = null
    //Target related stuff
    this.target = null
    this.targetBit = null
    this.targetLabel = null
    this._sources = {}

    this.resultList = null
    this.searchBackGround = null
    this.details = null

    this.hasStarted = false
    this.draw()
    this.hasStarted = true
  }

  /**
  * Gets fired when the user selects a result by clicking or using the
  * keyboard to select an element.
  *
  * @param {Object} result
  *   The result object that was selected.
  */
  async bacResultSelect(result) {
    if (this.details!==null)
      this.details.close()
    this.myController.onSelect(result)
  }

  async resultSelect(result) {
    this.myController.onSelect(result)
  }

  async onControllerSelect(result) {
    if (result.isNewQuery())
      setTimeout(() => this.doTextSearch(result.newquery, result.source, result.typeId), 50)
    else if ( this.shouldDetailsBeShown(result))
      this.doDetail(result)
  }

  //Public Functions
  setMaxHeight(intHeight) {
    if (intHeight !== null && intHeight > 100 && this.resultList !== null)
      this.resultList.style["max-height"] = intHeight + 'px'
  }

  top() {
    if (this.myInputField !== null)
      return domutil.offset(this.myInputField).top + 60
    else
      return null
  }

  closeFixedText() {
    this.fixedDisplay.hide()
    this.fixedDisplayHideFunction()
    this.header.show()
    this.hasFixedDisplay = false
    this.evaluateResultsVisibilityInterest()
  }

  /**
	 * Display a fixed text which can be closed by the user. When the text is closed the hidFunction will be called
	 * @param {string} text. Text to be displayed in the search box
	 * @param {function} hideFunction. Function to call when user closes the text.
	 */
  showFixedText(text, hideFunction) {
    this.header.hide()
    this.fixedDisplayText.html(text)
    this.fixedDisplay.show()
    this.hasFixedDisplay = true
    this.evaluateResultsVisibilityInterest()
    this.fixedDisplayHideFunction = hideFunction
  }

  /**
	 * @private
	 */
  async draw() {
    this.$gui = this.createGui(this.container)
    this.bac = autocomplete(
      this.myInputField,
      {
        cacheLimit: 0,
        selectKeys: [13],
        crossOrigin: true,
        charLimit: this.charLimit,
        mouseOver: this.doMouseOver,
        noResultsText: getString("noResults")
      }, {
        fetchRemoteData:      (query, completeCallback, timeout, crossOrigin)=>this.fetchRemoteData(query, completeCallback, timeout, crossOrigin),
        themeResult:          (result)=>this.themeResult(result),
        insertSuggestionList: (results, input)=>this.insertSuggestionList(results, input),
        select:               (result)=>this.bacResultSelect(result),
        show:                 ()=>this.bacEventShow(),
        hide:                 ()=>this.bacEventHide(),
        getGroup:             (result)=>this.getGroup(result),
        beginFetching:        ()=>this.beginFetching(),
        finishFetching:       ()=>this.finishFetching(),
        afterClear:           ()=>this.afterClear(),
        backSpace:            ()=>this.backSpace()
      })

    // this.bac = this.myInputField.data('better-autocomplete')

    const groups = []
    for (let searcher of this.myController.getSearchers())
      groups.push(searcher.getId())
    this.loadSources()
    this.bac.createGroups(groups)
  }

  async loadSources() {
    let s = await this.myController.getSources()
    //returns array [{source: "sss", types: [resultType..]}, ]
    //Convert to object {sss: {typeId: type, }, }
    for (let sourceEntry of s) {
      this._sources[sourceEntry.source] = {}
      for (let type of sourceEntry.types)
        this._sources[sourceEntry.source][type.id] = type
    }
  }

  /**
   * From a given result object, return it's group name (if any). Used for
   * grouping results together.
   *
   * @param {Object} result
   *   The result object.
   *
   * @returns {String}
   *   The group name, may contain HTML. If no group, don't return anything.
   */
  getGroup(result) {
    //TODO
    if (result.searcher)
      return result.searcher.getId()
    else
      return ""
  }


  /**
  * Called when remote fetching begins.
  *
  * <br /><br /><em>Default behavior: Adds the CSS class "fetching" to the
  * input field, for styling purposes.</em>
  *
  * @param {Object} $input
  *   The input DOM element, wrapped in jQuery.
  */
  beginFetching() {
    domutil.addClass(this.searchBackGround, "ssSearchBackgroundLoading")
  }

  /**
  * Called when fetching is finished. All active requests must finish before
  * this function is called.
  *
  * <br /><br /><em>Default behavior: Removes the "fetching" class.</em>
  *
  * @param {Object} $input
  *   The input DOM element, wrapped in jQuery.
  */
  finishFetching() {
    domutil.removeClass(this.searchBackGround, "ssSearchBackgroundLoading")
  }

  afterClear() {
    this.clearSuggestions()
  }

  backSpace() {
    if (this.targetLabel.innerHTML.length > 0) {
      this.removeTarget()
      this.bac.repeatSearch()
    }
  }

  clearAll() {
    this.doTextSearch("")
  }

  focus() {
    this.myInputField.focus()
  }

  blur(force) {
    this.myInputField.blur()
    if (force)
      this.hideResults()
    document.activeElement.blur()
  }

  refresh() {
    if (this.hasStarted)
      this.bac.repeatSearch()
  }

  doTextSearch(searchText, source, typeId) {
    this.clearSuggestions()
    this.myController.cancelFetches()
    if (source)
      this.showTarget(source, typeId)
    else
      this.removeTarget()

    this.bac.doSearch(searchText)
  }

  clearSuggestions() {
    domutil.removeClass(this.$suggestionList, "ssSuggestionsContent")
    while(this.$suggestionList.firstChild) {
      this.$suggestionList.removeChild(this.$suggestionList.firstChild)
    }
  }

  setResults(results) {
    this.bac.renderResults(results)
  }

  selectHighLighted() {
    this.bac.select()
  }
  //Private functions

  /**
   * Insert the results list into the DOM and position it properly.
   * @param {Object} $results
   *   The UL list element to insert, wrapped in jQuery.
   * @param {Object} $input
   *   The text input element, wrapped in jQuery.
  */
  insertSuggestionList(results) {
    this.resultList = results
    this.resultscontainer.append(results)
  }

  /**
   *
   * Fetch remote result data and return it using completeCallback when
   * fetching is finished. Must be asynchronous in order to not freeze the
   * Better Autocomplete instance.
   *
   * @param {String} url
   *   The URL to fetch data from.
   *
   * @param {Function} completeCallback
   *   This function must be called, even if an error occurs. It takes zero
   *   or one parameter: the data that was fetched.
   *
   * @param {Number} timeout
   *   The preferred timeout for the request. This callback should respect
   *   the timeout.
   *
   * @param {Boolean} crossOrigin
   *   True if a cross origin request should be performed.
   */
  fetchRemoteData(query, completeCallback, timeout) {
    this.clearSuggestions()
    if (this.details !== null)
      this.details.clear()

    if (query.length === 0)
      domutil.removeClass(this.searchBackGround, "ssSearchBackgroundHasContent")
    else
      domutil.addClass(this.searchBackGround, "ssSearchBackgroundHasContent")

    if (this.target !== null)
      this.myController.fetchData(query, (result, isLast)=>{
        completeCallback (result, isLast)
      }, this.limit, timeout, this.target.source, this.target.typeId)
    else
      this.myController.fetchData(query, (result, isLast)=>{
        completeCallback (result, isLast)
      }, this.limit, timeout)
  }

  themeResult_org(result) {
    let extraButtonDefs
    //if (result.hasOwnProperty("newquery") && result.newquery !== null){
    if (result.isNewQuery()) {
      extraButtonDefs = [{"buttonText": "", "buttonImage": icons.list.newQueryIcon, fixed: true}]
      return ResultRenderer.themeResult(result, [], extraButtonDefs)
    }else{
      if (this.details!==null && result.hasdetailHandlerDefs()) {
        extraButtonDefs = [
          {
            "buttonText": this.detailsButtonCaption,
            "buttonImage": icons.list.detailsIcon,
            "callBack": (result)=>{
              this.doDetail(result)
            },
            fixed: true
          }]
        return ResultRenderer.themeResult(result, [], extraButtonDefs)
      }else{
        let customButtonDefs = []
        if (result.searcher)
          customButtonDefs = result.searcher.getCustomButtonDefs(result)
        if (this.details!==null && customButtonDefs.length > 4) {
          extraButtonDefs = [
            {
              "buttonText": this.detailsButtonCaption,
              "buttonImage": icons.list.detailsIcon,
              "callBack": (result)=>{
                this.doDetail(result)
              },
              fixed: true
            }]
          return ResultRenderer.themeResult(result, [], extraButtonDefs)
        }else {
          return ResultRenderer.themeResult(result, customButtonDefs)
        }
      }
    }
  }

  /**
  * Given a result object, theme it to HTML.
  *
  * @param {Object} result
  *   The result object that should be rendered.
  *
  * @returns {String}
  *   HTML output, will be wrapped in a list element.
  */
  themeResult(result) {
    let extraButtonDefs
    if (result.isNewQuery()) {
      extraButtonDefs = [{"buttonText": "", "buttonImage": icons.list.newQueryIcon, fixed: true}]
      return ResultRenderer.themeResult(result, [], extraButtonDefs)
    }else {
      if (this.shouldDetailsBeShown(result)) {
        extraButtonDefs = [
          {
            "buttonText": this.detailsButtonCaption,
            "buttonImage": icons.list.detailsIcon,
            "callBack": (result) => {
              this.doDetail(result)
            },
            fixed: true
          }]
        return ResultRenderer.themeResult(result, [], extraButtonDefs)
      } else {
        if (result.searcher) {
          let customButtonDefs = result.searcher.getCustomButtonDefs(result)
          return ResultRenderer.themeResult(result, customButtonDefs)
        }
      }
    }
  }

  shouldDetailsBeShown(result) {
    return this.details!==null && (result.hasdetailHandlerDefs() || (result.searcher && result.searcher.getCustomButtonDefs(result).length > 4) )
  }

  //Everything related to target
  removeTarget() {
    this.targetLabel.innerHTML =""
    domutil.hide(this.targetBit)
    domutil.setAttribute(this.targetBit, "aria-hidden", true)
    this.targetBit.className = "ssTargetbit"
    this.target = null
    this.myInputField.style.width = (this.header.getBoundingClientRect().width - 60) + "px"
  }

  async showTarget(source, typeId) {
    if (typeof this._sources[source] === 'undefined' || typeof this._sources[source][typeId] === 'undefined')
      await this.loadSources()
    if (typeof this._sources[source] !== 'undefined' && typeof this._sources[source][typeId] !== 'undefined') {
      let resultType = this._sources[source][typeId]
      this.targetLabel.innerHTML = resultType.plural
      domutil.addClass(this.targetBit, "ssTargetbit_" + typeId.toLowerCase().replace(/ /g, ""))
      domutil.show(this.targetBit)
      domutil.setAttribute(this.targetBit, "aria-hidden", false)
      this.target = {source, typeId}
      this.myInputField.style.width = (this.header.getBoundingClientRect().width - this.targetBit.getBoundingClientRect().width - 60) + "px"
    }
  }

  /**
	 * private internal method of DefaultView
	 * @private
	 */
  createGui($container) {
    domutil.addClass($container, "septimasearch")
    this.header = domutil.createElement("div", "ssSearchHeader")
    this.searchBackGround = domutil.createElement("div", "ssSearchBackground")
    this.header.append(this.searchBackGround)

    this.targetBit = domutil.createElement("span", "ssTargetbit")
    this.targetLabel = domutil.createElement("span", "ssTargetlabel")
    const targetCloseButton = domutil.createElement("span", "ssTargetclosebutton")
    domutil.setAttribute(targetCloseButton, "aria-label", "Ryd emne")
    domutil.setAttribute(targetCloseButton, "role", "button")
    targetCloseButton.addEventListener("click", ()=>{
      this.removeTarget()
      this.bac.repeatSearch()
    })
    this.targetBit.append(this.targetLabel)
    this.targetBit.append(targetCloseButton)
    this.searchBackGround.append(this.targetBit)

    this.myInputField = domutil.createElement("input", "ssInput")
    domutil.setAttributes(this.myInputField, 
      {
        placeholder: this.placeHolderText,
        autocomplete: "off",
        role: "textbox",
        "aria-autocomplete": "list",
        "aria-haspopup": "true"
      }
    )
    this.searchBackGround.append(this.myInputField)
    const searchmenu = domutil.createElement("div", "ssSearchMenu")
    this.$suggestionList = domutil.createElement("div", "ssSuggestions")
    searchmenu.append(this.$suggestionList)
    this.header.append(searchmenu)

    const searchIcon = domutil.createElement("div", "ssSearchIcon")
    domutil.setAttribute(searchIcon, "aria-hidden", true)
    this.searchBackGround.append(searchIcon)

    const spinner = domutil.createElement("div", "ssSpinner")
    domutil.setAttribute(spinner, "aria-hidden", true)
    spinner.append(domutil.createElement("div", "bounce1"))
    spinner.append(domutil.createElement("div", "bounce2"))
    spinner.append(domutil.createElement("div", "bounce3"))

    this.searchBackGround.append(spinner)

    const closeButton = domutil.createElement("div", "ssCloseButton")
    this.searchBackGround.append(closeButton)

    this.resultscontainer = domutil.createElement("div", "ssSearchResult")

    $container.append(this.header)
    $container.append(this.resultscontainer)

    if (this.detailsLayout !== null)
      this.details = this.createDetailsView($container, this.detailsLayout)

    if (this.doMouseOver) {
      $container.addEventListener("mouseleave", ()=>{
        this.guiMouseLeave()
      })
      this.myInputField.addEventListener("mouseenter", ()=>{
        this.guiMouseEnter()
      })
    }

    this.myInputField.addEventListener("focus", ()=>{
      this.inputFocus()
    })
    this.myInputField.addEventListener("blur", ()=>{
      this.inputBlur()
    })

    closeButton.addEventListener("click", ()=>{
      this.clearAll()
    })
    domutil.setAttribute(closeButton, "aria-label", "Ryd søgefeltet")
    domutil.setAttribute(closeButton, "role", "button")

    this.removeTarget()
  }

  //Control showing and hiding of GUI
  setResultsVisibilityInterest(party, interested) {
    let partyFound = false
    for (let visibleInterest of this.visibleInterests)
      if (visibleInterest.party == party) {
        partyFound = true
        visibleInterest.interested = interested
      }

    if (!partyFound)
      this.visibleInterests.push({party: party, interested: interested})

    if (interested && !this.hasFixedDisplay) {
      this.showResults()
    }else{
      let anyInterested = false
      for (let visibleInterest of this.visibleInterests)
        if (visibleInterest.interested)
          anyInterested = true


      if (anyInterested && !this.hasFixedDisplay)
        this.showResults()
      else
        this.hideResults()

    }
  }

  createDetailsView(container, layout) {
    const details = {parent: this}
    details.layout = layout
    details.results = []

    details.container = domutil.createElement("div", "ssDetails")
    const detailul = domutil.createElement("ul", "better-autocomplete")
    details.container.append(detailul)

    details.header = domutil.createElement("li", "ssDetailHeader")
    detailul.append(details.header)

    details.content = domutil.createElement("li", "ssDetailBody")
    domutil.addClass(details.content, "ssActive")

    detailul.append(details.content)

    container.append(details.container)
    domutil.removeClass(details.container, "ssActive")

    details.show = function() {
      if (typeof details.result !== 'undefined' && details.result !== null)
        domutil.addClass(details.container, "ssActive")
    }

    details.hide = function() {
      domutil.removeClass(details.container, "ssActive")
    }

    details.close = function() {
      if(details.result !== undefined && details.result !== null) {
        details.results = []
        details.hide()
        details.clear()
      }
    }

    details.clear = function() {
      if(details.result !== undefined && details.result !== null) {
        details.result = null
        details.layout.destroyUI()

        while(details.header.firstChild) {
          details.header.removeChild(details.header.firstChild)
        }
        while(details.content.firstChild) {
          details.content.removeChild(details.content.firstChild)
        }
      }
    }

    details.back = function() {
      if (details.results.length > 0) {
        const result = details.results.pop()
        details.parent.resultSelect(result)
        details.render(result)
      }
    }

    details.setResult = function(result) {
      if (details.result !== result) {
        if(details.result !== undefined && details.result !== null)
          details.results.push(details.result)

        details.render(result)
      }
    }

    details.render = async function(result) {
      details.clear()
      details.result = result
      let backButton
      let title
      if (details.results.length > 0) {
        title = details.results[details.results.length - 1].title
        backButton = domutil.createElement("div", "ssBackButton")
        let icon = domutil.createImageElement(icons.details.back, title)
        backButton.append(icon)
        let titleEl = domutil.createElement("div", "ssTxt")
        titleEl.innerText = title
        backButton.append(titleEl)
        backButton.addEventListener("click", ()=>{
          details.back()
        })
      }else{
        backButton = domutil.createElement("div", "ssBackButton")
        let icon = domutil.createImageElement(icons.details.back, getString("close"))
        backButton.append(icon)
        let titleEl = domutil.createElement("div", "ssTxt")
        titleEl.innerText = getString("close")
        backButton.append(titleEl)
        backButton.addEventListener("click", ()=>{
          details.close()
        })
      }
      details.header.append(backButton)

      let output = domutil.createElement("div", "ssResult")
      domutil.setAttribute(output, "aria-hidden", true)
      let imageContainer = domutil.createElement("div", "ssResultImage")
      if (result.hasOwnProperty("image") && result.image !== null && result.image !== "") {
        imageContainer.append(domutil.createImageElement(result.image))
        output.append(imageContainer)
      }else{
        let searcher = result.searcher
        if (searcher.hasOwnProperty("iconURI") && searcher.iconURI !== null && searcher.iconURI !== "")
          result.image = searcher.iconURI
        else
          result.image = icons.result.defaultIcon

        imageContainer.append(domutil.createImageElement(result.image))
        output.append(imageContainer)
      }
      output.append(imageContainer)

      let titleEl = domutil.createElement("div", "ssResultTitle")
      titleEl.innerText = result.title
      output.append(titleEl)

      if (!this.parent.showDescriptionInMore && result.hasOwnProperty("description") && result.description !== null) {
        let description = domutil.createElement("div", "ssResultDescription")
        description.innerText = result.description
        domutil.setAttribute(description, "title", result.description + "' \"" )
        output.append(description)
        domutil.setAttribute(details.header, "aria-label", result.description)
      } else {
        let resultDescription = domutil.createElement("div", "ssResultDescription")
        domutil.addClass(resultDescription, 'hide')
        output.append(resultDescription)
      }

      details.header.append(output)

      let ui = await details.layout.getUI(result)
      details.content.append(ui)
      details.show()
    }

    details.container.addEventListener("mouseenter", ()=>{
      // details.mouseEnter()
    })
    details.container.addEventListener("mouseleave", ()=>{
      // details.mouseLeave()
    })

    return details

  }

  doDetail(result) {
    if (this.details!==null)
      if (result !== undefined) {
        this.details.setResult(result)
        if (this.isVisible)
          this.blur(true)
        else
          this.details.show()
      }
  }

  showResults() {
    if (!this.isVisible) {
      if (this.details !== null)
        this.details.hide()
      domutil.addClass(this.container, "ssActive")
      this.isVisible = true
    }
  }

  hideResults() {
    for (let visibleInterest of this.visibleInterests)
      visibleInterest.interested = false

    if (this.isVisible) {
      domutil.removeClass(this.container, "ssActive")
      this.isVisible = false
      if (this.details!==null)
        this.details.show()
    }
  }

  //Callbacks
  guiMouseEnter() {
    this.setResultsVisibilityInterest('gui', true)
    return true
  }

  guiMouseLeave() {
    this.setResultsVisibilityInterest('gui', false)
    return true
  }

  inputFocus() {
    this.setResultsVisibilityInterest('input', true)
  }

  inputBlur() {
    this.setResultsVisibilityInterest('input', false)
  }

  bacEventShow() {
    this.setResultsVisibilityInterest('bac', true)
    this.bac.show()
  }

  bacEventHide() {
    this.setResultsVisibilityInterest('bac', false)
  }

}
