import { getString, setLocale, setLocaleByIdentifier, defaultLocale } from './resources/strings'
import icons from './resources/icons'
import { Class, inherit, extend, bind } from './utils'
import { getWKTParser } from './util/getWKTParser'
//import Log from './Log'
import Controller from './Controller'
import Query from './Query'
import QueryResult from './QueryResult'
import Result from './Result'
import ControllerBuilder from './ControllerBuilder'
import Searcher from './searchers/Searcher'
import ArcGisSearcher from './searchers/ArcGisSearcher'
import ClientSearcher from './searchers/ClientSearcher'
import CVR_enhedSearcher from './searchers/CVR_enhedSearcher'
import DataSearcher from './searchers/DataSearcher'
import DawaSearcher from './searchers/DawaSearcher'
import DawaStednavnSearcher from './searchers/DawaStednavnSearcher'
import GeoStednavnSearcher from './searchers/GeoStednavnSearcher'
import EsSearcher from './searchers/EsSearcher'
import GeoSearch from './searchers/GeoSearch'
import PlanSearcher from './searchers/PlanSearcher'
import PlanSystemSearcher from './searchers/PlanSystemSearcher'
import S4IndexSearcher from './searchers/S4IndexSearcher'
import S3Searcher from './searchers/S3Searcher'
import SqSearcher from './searchers/SqSearcher'
import IsoChroneSearcher from './searchers/IsoChroneSearcher'

import SearchableData from './searchers/local/SearchableData'
import SearchableGeoJson from './searchers/local/SearchableGeoJson'

import CoordinateSearchProvider from './searchers/providers/CoordinateSearchProvider'

import DummyMoreHandler from './searchers/detailhandlers/DummyMoreHandler'
import DemoDetailsHandler from "./searchers/detailhandlers/DemoDetailsHandler"
import TinglysningerForAdresseHandler from "./searchers/detailhandlers/TinglysningerForAdresseHandler"
import DokLinksForLokalplan from "./searchers/detailhandlers/DokLinksForLokalplan"

import View from './defaultview/View'
import DefaultView from './defaultview/DefaultView'
import AccordionDetailsLayout from './defaultview/AccordionDetailsLayout'
import DetailItemsRenderer from './defaultview/DetailItemsRenderer'
import SqDetailsHandler from './details/SqDetailsHandler'
import DetailsHandlerDef from './details/DetailsHandlerDef'
import ComposedDetailsHandler from './details/ComposedDetailsHandler'
import DetailItemsList from "./details/DetailItemsList"
import DetailsSection from "./details/DetailsSection"

import RouteCalculator from './util/RouteCalculator'
import allTypes from './AllTypes'

import 'core-js'
import 'whatwg-fetch'
import './util/ie11'

import JordForureningsattestLinkHandler from "./searchers/detailhandlers/JordForureningsattestLinkHandler"
import CvrLinkHandler from "./searchers/detailhandlers/CvrLinkHandler"
import CvrInfoProvider from "./searchers/detailhandlers/CvrInfoProvider"

import RegnskaberForCvrNummerHandler from "./searchers/detailhandlers/RegnskaberForCvrNummer"
import S4InfoHandler from "./searchers/detailhandlers/S4InfoHandler"
import FilarkivLinkForAdresseMatrikelHandler from './searchers/detailhandlers/FilarkivLinkForAdresseMatrikelHandler'
import LifaWebOisLinkForAdresseMatrikelHandler from './searchers/detailhandlers/LifaWebOisLinkForAdresseMatrikelHandler'
import LifaLinkForAdresseMatrikelHandler from './searchers/detailhandlers/LifaLinkForAdresseMatrikelHandler'

import OffentligeLinksForAdresseMatrikel from './searchers/detailhandlers/OffentligeLinksForAdresseMatrikel'
import * as reproject from './util/reproject'
import HusnummerInfoProvider from "./searchers/detailhandlers/HusnummerInfoProvider"
import PlanInfoProvider from "./searchers/detailhandlers/PlanInfoProvider"
import TjekDitNetForAdresseProvider from "./searchers/detailhandlers/TjekDitNetForAdresseProvider"
import AdmAdresseInfoProvider from "./searchers/detailhandlers/AdmAdresseInfoProvider"
import InfoForKommuneProvider from "./searchers/detailhandlers/InfoForKommuneProvider"
import InfoForOpstillingsKredsProvider from "./searchers/detailhandlers/InfoForOpstillingskredsProvider"
import InfoForPolitidistriktProvider from "./searchers/detailhandlers/InfoForPolitidistriktProvider"
import InfoForPostdistriktProvider from "./searchers/detailhandlers/InfoForPostdistriktProvider"
import InfoForRetskredsProvider from "./searchers/detailhandlers/InfoForRetskredsProvider"
import ClassSearcher from "./searchers/ClassSearcher"
import CvrVirksomhederForAdresseProvider from "./searchers/detailhandlers/CvrVirksomhederForAdresseProvider"
import JordStykkeInfoProvider from "./searchers/detailhandlers/JordStykkeInfoProvider"
import DagiInfoProvider from "./searchers/detailhandlers/DagiInfoProvider"

import ThrowingDetailsProvider from "./searchers/detailhandlers/ThrowingDetailsProvider"

const Search = {
  locale: defaultLocale,
  icons,
  getString,
  setLocale,
  setLocaleByIdentifier,
  Controller,
  ControllerBuilder,
  Query,
  QueryResult,
  Result,
  Searcher: Searcher,
  arcGisSearcher: ArcGisSearcher,
  ArcGisSearcher,
  ClientSearcher,
  CVR_enhedSearcher,
  DataSearcher,
  DawaSearcher,
  DawaStednavnSearcher,
  GeoStednavnSearcher,
  EsSearcher,
  GeoSearch,
  PlanSearcher,
  PlanSystemSearcher,
  S4IndexSearcher,
  S3Searcher,
  SqSearcher,
  SearchableData,
  SearchableGeoJson,
  View,
  DefaultView,
  AccordionDetailsLayout,
  DetailItemsRenderer,
  SqDetailsHandler,
  DetailsHandlerDef,
  RouteCalculator,
  CoordinateSearchProvider,
  IsoChroneSearcher,
  getWKTParser,
  classRegistry: allTypes,
  DummyMoreHandler,
  DemoDetailsHandler,
  TinglysningerForAdresseHandler,
  CvrVirksomhederForAdresseProvider,
  DokLinksForLokalplan,
  ComposedDetailsHandler,
  DetailItemsList,
  DetailsSection,
  JordForureningsattestLinkHandler,
  CvrLinkHandler,
  CvrInfoProvider,
  RegnskaberForCvrNummerHandler,
  S4InfoHandler,
  FilarkivLinkForAdresseMatrikelHandler,
  LifaWebOisLinkForAdresseMatrikelHandler,
  LifaLinkForAdresseMatrikelHandler,
  PlanInfoProvider,
  HusnummerInfoProvider,
  AdmAdresseInfoProvider,
  OffentligeLinksForAdresseMatrikel,
  TjekDitNetForAdresseProvider,
  reproject,
  InfoForKommuneProvider,
  JordStykkeInfoProvider,
  InfoForOpstillingsKredsProvider,
  InfoForPolitidistriktProvider,
  InfoForPostdistriktProvider,
  InfoForRetskredsProvider,
  DagiInfoProvider,
  ClassSearcher,
  ThrowingDetailsProvider
}

const version = 'BUILDVERSION'

export {
  Class,
  inherit,
  extend,
  bind,
  Search,
  version
}
