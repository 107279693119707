export default class DetailsSection {
  constructor(options) {
    this.header = null
    if (options && options.header)
      this.header = options.header

    this.image = null
    if (options && options.image)
      this.image = options.image


    this.id = "" + Math.floor(Math.random() * 999)
    
    this.items = []
  }
  
  append(detailItem) {
    this.items.push(detailItem)
  }
  
  asItems() {
    let startItem = {
      type: "section_start",
      id: this.id
    }
    
    if (this.header)
      startItem.header = this.header

    if (this.image)
      startItem.image = this.image

    let endItem = {
      type: "section_end",
      id: this.id
    }
    
    return [startItem, ...this.items, endItem]
  }

  toJSON() {
    return this.asItem()
  }
}
