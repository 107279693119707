/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef"
import icons from "../../resources/icons"
/**
 * Viser søgelink til JO FilArkivet for for Dawa.adresse og Kortforsyningen.matrikelnumre resultater
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:</caption>
 * _type: Septima.Search.DawaSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.FilarkivLinkForAdresseMatrikelHandler
 *     _options:
 *       kommunenavn: Horsens
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * dawaSearcher.addDetailHandlerDef(new Septima.Search.FilarkivLinkForAdresseMatrikelHandler({kommunenavn: "Horsens"}))
 *
 * @example <caption>ES6:</caption>
 * import FilarkivLinkForAdresseMatrikelHandler from './searchers/detailhandlers/FilarkivLinkForAdresseMatrikelHandler'
 * dawaSearcher.addDetailHandlerDef(new FilarkivLinkForAdresseMatrikelHandler({kommunenavn: "Horsens"}))
 * @api
 **/
export default class FilarkivLinkForAdresseMatrikelHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {boolean} [options.more=true]
   * @param {string} options.kommunenavn kommunenavn som aftalt med JO
   **/
  constructor(options= {}) {
    if (typeof options.kommunenavn === 'undefined')
      throw new Error ("FilarkivLinkForAdresseMatrikelHandler kaldt uden options.kommunenavn")

    let defaultOptions = {
      buttonText: "Søg i Filarkiv",
      buttonImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4xNkRpr/UAAAG6SURBVDhPrVQ9awJBEH33oacIUfFEME30YiCmsFAQBP+OpSBaWuQHaO1/ECz8DX6AhW20jJ0oJqfxNHqnl50VL2Ug3oNhZ2Zn380Ob0+wGeACHKJarcYTzWaTr3+hWq3ytdFo8BVExJJE9i+js7yZW0iuRhwic1yBwIxYb4ZD5PP5UCgUMBgMUCqV4PV60Wq1sNvtEAwGIUoSPLKMxWIBNRqFsTUQiURwx/aM7RYS43glIkrW63VEWVEmk0EgEEA6nUa/30ciqSEcDuM5/YKdYSAev6cjSKWeMJ/Psd/v4czodDphvV4jFouh2+2i3W5z0uve+XyG/qkjkdAgyxJMy+Q5VVVxPB4vRIIgwGBfoutI7AqiKHIj/wIBkijha7PBcrmApj3iZFmc6GO1gmkeIVNZpVJBLpfDdDrFcDhEuVyGxQo7nQ6noTGSVLyKgsnkDQ+JJCQ2L+pqNnu/lJAGQqGQXSwWbY/Hw3WRzWbtfD7v6IR1aiuKYvv8fiemWj+L2W1+daTrOnq9HmvRpBDj8Rij0Yj7BJrB4XDANxvqNaZaGjJ1ykHyvkXddJbg2qN17Tfi2ltziQj4ARTUDixARs2vAAAAAElFTkSuQmCC',
      isApplicable: (result) => {
        return ((result.source === "Dawa" && result.typeId === "adresse") || (result.source === "Kortforsyningen" && result.typeId === "matrikelnumre"))
      }
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.kommunenavn = options.kommunenavn
  }

  async myHandler(result) {
    let items = []
    if (result.source === "Dawa" && result.typeId === "adresse") {
      let url = "https://" + this.kommunenavn + ".filarkiv.dk/Link/Search/Adresse/" + result.data.properties.vejstykke.kode + "-" + result.data.properties.husnr
      items.push(
        { type: 'link',
          icon: this.more ? this.buttonImage : icons.details.link,
          link: url,
          linkTitle: "Søg på adresse i FilArkiv"})
    }else{
      let url = "https://" + this.kommunenavn + ".filarkiv.dk/Link/Search/Matrikelnr/" + result.id
      items.push(
        { type: 'link',
          icon: this.more ? this.buttonImage : icons.details.link,
          link: url,
          linkTitle: "Søg på matrikelnummer i FilArkiv"})
    }
    return items
  }

}
