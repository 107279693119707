import CVR_enhedSearcher from "./searchers/CVR_enhedSearcher"
import DawaSearcher from "./searchers/DawaSearcher"
import DawaStednavnSearcher from "./searchers/DawaStednavnSearcher"
import GeoStednavnSearcher from "./searchers/GeoStednavnSearcher"
import GeoSearch from "./searchers/GeoSearch"
import PlanSearcher from "./searchers/PlanSearcher"
import PlanSystemSearcher from "./searchers/PlanSystemSearcher"
import IsoChroneSearcher from "./searchers/IsoChroneSearcher"
import FkgSearcher from "./searchers/FkgSearcher"
import TinglysningerForAdresseHandler from "./searchers/detailhandlers/TinglysningerForAdresseHandler"
import DokLinksForLokalplan from "./searchers/detailhandlers/DokLinksForLokalplan"
import LifaLinkForAdresseMatrikelHandler from "./searchers/detailhandlers/LifaLinkForAdresseMatrikelHandler"
import JordForureningsattestLinkHandler from "./searchers/detailhandlers/JordForureningsattestLinkHandler"
import CvrLinkHandler from "./searchers/detailhandlers/CvrLinkHandler"
import CvrInfoProvider from "./searchers/detailhandlers/CvrInfoProvider"
import RegnskaberForCvrNummerHandler from "./searchers/detailhandlers/RegnskaberForCvrNummer"
import PlanInfoProvider from "./searchers/detailhandlers/PlanInfoProvider"
import FkgInfoProvider from "./searchers/detailhandlers/FkgInfoProvider"
import HusnummerInfoProvider from "./searchers/detailhandlers/HusnummerInfoProvider"
import AdmAdresseInfoProvider from "./searchers/detailhandlers/AdmAdresseInfoProvider"
import OffentligeLinksForAdresseMatrikel from "./searchers/detailhandlers/OffentligeLinksForAdresseMatrikel"
import TjekDitNetForAdresseProvider from "./searchers/detailhandlers/TjekDitNetForAdresseProvider"
import ClassRegistry from "./ClassRegistry"
import InfoForKommuneProvider from "./searchers/detailhandlers/InfoForKommuneProvider"
import InfoForOpstillingsKredsProvider from "./searchers/detailhandlers/InfoForOpstillingskredsProvider"
import InfoForPolitidistriktProvider from "./searchers/detailhandlers/InfoForPolitidistriktProvider"
import InfoForPostdistriktProvider from "./searchers/detailhandlers/InfoForPostdistriktProvider"
import InfoForRetskredsProvider from "./searchers/detailhandlers/InfoForRetskredsProvider"
import * as types from '../../types.json'
import CvrVirksomhederForAdresseProvider from "./searchers/detailhandlers/CvrVirksomhederForAdresseProvider"
import JordStykkeInfoProvider from "./searchers/detailhandlers/JordStykkeInfoProvider"
import DagiInfoProvider from "./searchers/detailhandlers/DagiInfoProvider"

const dkTypes = new ClassRegistry("Septima.Search.")
dkTypes.setTypes(types)

const typesToAdd = {
  CVR_enhedSearcher,
  DawaSearcher,
  DawaStednavnSearcher,
  GeoStednavnSearcher,
  GeoSearch,
  PlanSearcher,
  PlanSystemSearcher,
  IsoChroneSearcher,
  FkgSearcher,
  TinglysningerForAdresseHandler,
  DokLinksForLokalplan,
  LifaLinkForAdresseMatrikelHandler,
  JordForureningsattestLinkHandler,
  CvrLinkHandler,
  DagiInfoProvider,
  CvrInfoProvider,
  RegnskaberForCvrNummerHandler,
  JordStykkeInfoProvider,
  PlanInfoProvider,
  FkgInfoProvider,
  HusnummerInfoProvider,
  AdmAdresseInfoProvider,
  OffentligeLinksForAdresseMatrikel,
  TjekDitNetForAdresseProvider,
  InfoForKommuneProvider,
  InfoForOpstillingsKredsProvider,
  InfoForPolitidistriktProvider,
  InfoForPostdistriktProvider,
  InfoForRetskredsProvider,
  CvrVirksomhederForAdresseProvider
}

for (let [key, value] of Object.entries(typesToAdd))
  dkTypes.addClass(value, key)

export default dkTypes