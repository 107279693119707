import WKT from 'ol/format/WKT'
import GeoJSON from 'ol/format/GeoJSON'

function parse(wkt) {
  const geometry = new WKT().readGeometry(wkt)
  const geojsonObject = new GeoJSON().writeGeometryObject(geometry)
  return geojsonObject
}

function convert(geojson) {
  const geometry = new GeoJSON().readGeometry(geojson)
  const wkt = new WKT().writeGeometry(geometry)
  return wkt
}

function getInteriorPoint(geometry) {
  // Accepts wkt and geoJson - returns same type
  let olGeom
  if (isObject(geometry)) {
    //geojson
    olGeom = new GeoJSON().readGeometry(geometry)
  } else {
    olGeom = new WKT().readGeometry(geometry)
  }
  let interiorPoint = olGeom.getInteriorPoint()
  if (isObject(geometry)) {
    //geojson
    return new GeoJSON().writeGeometryObject(interiorPoint)
  } else {
    return new WKT().writeGeometry(interiorPoint)
  }
}

function isObject(val) {
  if (val === null) {
    return false
  }
  return ( (typeof val === 'function') || (typeof val === 'object') )
}

export function getWKTParser() {
  return {
    parse,
    convert,
    getInteriorPoint
  }
}
