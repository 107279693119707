import QueryResult from './QueryResult'

export default function DataFetcher(controller, completeCallback) {
  this.completeCallback = completeCallback ? completeCallback : ()=>{}
  this.async = true
  this.controller = controller
  this.searchers = []
  this._isActive = true
  this.onCancelHandlers = []
  this.searcherStates = []
  
  this.finalQueryResult = new QueryResult(null)
}

DataFetcher.prototype.fetch = function(queries) {
  //Do not move to async await. Needs to be parallel
  return new Promise((resolve)=>{
    for (let query of queries) {
      let searcher = query.searcher
      this.searcherStates.push({"searcherId": searcher.getId(), "state": "pending"})
      //searcher._fetchData(query).then(
      searcher.asyncFetchData(query).then(
        (queryResult) => {
          this.fetchSuccess(queryResult)
          if (!this.anyPending())
            resolve(this.finalQueryResult)
        },
        (error) => {
          this.fetchError(searcher, error)
          if (!this.anyPending())
            resolve(this.finalQueryResult)
        }
      )
    } 
  })
}

DataFetcher.prototype.cancel = function() {
  for (let onCancelHandler of this.onCancelHandlers) 
    onCancelHandler()
  this.clean()
}

DataFetcher.prototype.clean = function() {
  if (this._isActive) {
    this._isActive = false
    this.controller = null
    this.onCancelHandlers = []
    this.searcherStates = []
    this.completeCallback([], true)
  }
}

DataFetcher.prototype.fetchSuccess = function(queryResult) {
  if (this._isActive) {
    const searcher = queryResult.getSearcher()
    const searcherId = searcher.getId()
    for (let searcherState of this.searcherStates) 
      if (searcherState.searcherId == searcherId) {
        searcherState.state = "complete"
        searcherState.queryResult = queryResult
        if (this.async) 
          this.completeCallback(queryResult.getAllResults(), false)
      }
    
    if (!this.anyPending()) {
      for (let thisSearcherState of this.searcherStates) 
        if (thisSearcherState.state == "complete") {
          let result = thisSearcherState.queryResult
          this.finalQueryResult.addNewQueries(result.getNewQueries())
          this.finalQueryResult.addResults(result.getResults())
        }

      this.completeCallback(this.finalQueryResult, true)
      this.clean()
    }
  }
}

DataFetcher.prototype.anyPending = function() {
  for (let searcherState of this.searcherStates) 
    if (searcherState.state === "pending") 
      return true
  return false
}

DataFetcher.prototype.fetchError = function(searcher, errorThrown) {
  if (this._isActive) {
    const searcherId = searcher.getId()
    for (let searcherState of this.searcherStates) 
      if (searcherState.searcherId == searcherId) 
        searcherState.state = "error"
      
    
    this.controller.fetchError(this, searcher, errorThrown)
    if (!this.anyPending()) {
      for (let thisSearcherState of this.searcherStates) 
        if (thisSearcherState.state == "complete") {
          let result = thisSearcherState.queryResult
          this.finalQueryResult.addNewQueries(result.getNewQueries())
          this.finalQueryResult.addResults(result.getResults())
        }

      this.completeCallback(this.finalQueryResult, true)
      this.clean()
    }
  }
}
