/**
 * @module
 */

import icons from '../resources/icons'
import EsSearcher from './EsSearcher'
//import DawaSearcher from "./DawaSearcher"

/**
 *
 * Searches Septima virk.dk data service<br/><br/>
 * Supports GET<br/>
 * SQ support<br/>
 * @extends module:js/searchers/EsSearcher
 * @example <caption>YAML Declaration: <a href='../examples/builder/'>Example</a></caption>
 _type: Septima.Search.CVR_enhedSearcher
 _options:
   searchindexToken: septimaSEARCHDEMO-A7OLGHG2J4
   kommunekode: 101

 * @example <caption> JS options: <a href='../examples/simple.dk/'>Example</a></caption>

 cvrSearcherOptions = {
    searchindexToken: 'TOKEN'
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.CVR_enhedSearcher(cvrSearcherOptions))
 *
 * @example <caption>ES6:</caption>
 * import CVR_enhedSearcher from './searchers/CVR_enhedSearcher'
 * controller.addSearcher(new CVR_enhedSearcher(cvrSearcherOptions))
 *
 * @api
 */

export default class CVR_enhedSearcher extends EsSearcher {
  /**
   *
   * @param {Object} options CVR_enhedSearcher expects these properties:
   * @param {string} options.searchindexToken Please contact Septima to obtain a valid token
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {string} [options.singular=Produktionsenhed]
   * @param {string} [options.plural=Produktionsenheder]
   * @api
   */

  constructor(options) {
    options.fields = options.fields || ['navn_tekst',
      'beliggenhedsadresse_vejnavn',
      'beliggenhedsadresse_postdistrikt',
      'beliggenhedsadresse_postnr',
      'pnr',
      'wkt',
      'septima_addressaccessidentifier',
      'virksomhed_cvrnr',
      'hovedbranche_tekst',
      'telefonnummer_kontaktoplysning',
      'email_kontaktoplysning']
    options.searchindexServiceUrl = 'https://new-searchindex.septima.dk/'
    options.index = options.searchindexToken + '/cvr/produktionsenheder'

    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += ''
      let municipalities = options.kommunekode.split(' ')
      options.filterTerm = { 'beliggenhedsadresse_kommune_kode' : municipalities }
    }else if (options.filter) {
      options.filterTerm = options.filter
    }

    options.source = "cvr"
    options.typeId = "produktionsenhed"
    if (typeof options.singular === 'undefined')
      options.singular = 'Produktionsenhed'
    if (typeof options.plural === 'undefined')
      options.plural = 'Produktionsenheder'
    options.idField = 'pnr'


    super(Object.assign({
      iconURI:icons.searchers.cvr
    },
    options))
    this.searchindexToken = options.searchindexToken

  }

  createQuery(queryString) {
    let esQuery
    esQuery ={
      "bool": {
        "should": [
          {"multi_match": {
            "query":       queryString,
            "type":        "cross_fields",
            "operator":    "and",
            "fields":      ["hovedbranche_tekst","navn_tekst","pnr", "virksomhed_cvrnr"]
          }},
          {
            "match_phrase": {
              "navn_tekst": {
                "boost": 5,
                "query": queryString
              }
            }
          },
          {
            "match": {
              "navn_tekst": {
                "boost": 4,
                "operator": "or",
                "query": queryString
              }
            }
          },
          {
            "match": {
              "beliggenhedsadresse_vejnavn": {
                "boost": 3,
                "query": queryString
              }
            }
          },
          {
            "match_phrase": {
              "hovedbranche_tekst": {
                "boost": 2,
                "query": queryString
              }
            }
          }
        ]
      }
    }
    return esQuery
  }

  async sq(q) {
    const wkt = q.wkt
    const attributeFilter = q.attributeFilter || this.attributeFilter

    let cql_filter = `intersects(septima_geometri,${wkt})`
    if (this.distanceWithin)
      cql_filter = `dwithin(septima_geometri,${wkt},${this.distanceWithin},meters)`
    if (q.ignoreGeometry)
      cql_filter = '1=1'
    if (attributeFilter) {
      const cql_attribute_filter = Object
        .keys(attributeFilter)
        .map(key => `${key} in (${attributeFilter[key].join(',')})`)
        .join(' or ')
      cql_filter += ` and (${cql_attribute_filter})`
    }

    let params = {
      servicename: 'WFS',
      typename: 'stamdata:produktionsenheder',
      request: 'GetFeature',
      version: '1.0.0',
      outputformat: 'json',
      cql_filter
    }

    const url = 'http://g.septima.dk/geoserver/stamdata/wfs'
    const fc = await this.fetch(url, { data: params, method: 'post' })

    const queryResult = this.createQueryResult()

    fc.features.forEach(f => {
      let title = f.properties.navn_navn
      let description = f.properties.hovedbranche_branchetekst
      let geometry = f.geometry
      let result = queryResult.addResult(this.source, "produktionsenhed", title, description, geometry, f)
      result.id = f.properties.pnummer
      result.distance = 0
    })

    return queryResult
  }

  getGeometry(hit) {
    if (hit.fields.wkt !== undefined) 
      return this.translateWktToGeoJsonObject(hit.fields.wkt[0])
    return null
  }
  
  async getVirksomhed(cvrNummer) {
    let virksomhedsUrl = this.searchindexServiceUrl + "/" + this.searchindexToken + "/cvr/virksomheder/" + cvrNummer
    let virksomhed = await this.fetch (virksomhedsUrl)
    return virksomhed
  }
  
  async getProduktionsEnheder(cvrNummer) {
    let produktionsenhederUrl = this.searchindexServiceUrl + "/" + this.searchindexToken + "/cvr/produktionsenheder/_search?size=1000&sort=navn_tekst:asc&q=virksomhed_cvrnr:" + cvrNummer
    let produktionsenhederResponse = await this.fetch (produktionsenhederUrl)
    let queryResult = this.createQueryResult()
    for (let produktionsenhed of produktionsenhederResponse.hits.hits) {
      let displayName = produktionsenhed._source.navn_tekst
      let description = produktionsenhed._source.beliggenhedsadresse_vejnavn + ' ' + produktionsenhed._source.beliggenhedsadresse_postnr + ' ' + produktionsenhed._source.beliggenhedsadresse_postdistrikt
      let resultGeometry = this.translateWktToGeoJsonObject(produktionsenhed._source.wkt)
      let result = queryResult.addResult(this.source, this.typeId, displayName, description, resultGeometry, null)
      result.id = produktionsenhed._source.pnr
      result.isComplete = false
    }
    return queryResult
  }
  
  async getVirksomhederForAdresse(vejkode, husnummer, komkode) {
    //let url = this.searchindexServiceUrl + "/" + this.searchindexToken + `/cvr/produktionsenheder/_search?q=beliggenhedsadresse_vejkode:${vejkode} AND beliggenhedsadresse_husnummerfra:${husnummer} AND beliggenhedsadresse_kommune_kode=${komkode.substr(-3)}`
    let virksomhederUrl = this.searchindexServiceUrl + "/" + this.searchindexToken + "/cvr/produktionsenheder/_search?q=beliggenhedsadresse_vejkode:"+vejkode+" AND beliggenhedsadresse_husnummerfra:"+husnummer+" AND beliggenhedsadresse_kommune_kode=" + komkode.substr(-3)
    let produktionsenhederResponse = await this.fetch (virksomhederUrl)
    let queryResult = this.createQueryResult()
    for (let produktionsenhed of produktionsenhederResponse.hits.hits) {
      let displayName = produktionsenhed._source.navn_tekst
      let description = produktionsenhed._source.beliggenhedsadresse_vejnavn + ' ' + produktionsenhed._source.beliggenhedsadresse_postnr + ' ' + produktionsenhed._source.beliggenhedsadresse_postdistrikt
      let resultGeometry = this.translateWktToGeoJsonObject(produktionsenhed._source.wkt)
      let result = queryResult.addResult(this.source, this.typeId, displayName, description, resultGeometry, null)
      result.id = produktionsenhed._source.pnr
      result.isComplete = false
    }
    return queryResult
  }


  getDescription(hit) {
    return hit.fields.beliggenhedsadresse_vejnavn[0] + ' ' + hit.fields.beliggenhedsadresse_postnr[0] + ' ' + hit.fields.beliggenhedsadresse_postdistrikt[0]
  }

  getDisplayName(hit) {
    return hit.fields.navn_tekst[0]
  }
  
}
