import ArcGisSearcher from "./searchers/ArcGisSearcher"
import DataSearcher from "./searchers/DataSearcher"
import S4IndexSearcher from "./searchers/S4IndexSearcher"
import S3Searcher from "./searchers/S3Searcher"
import SqSearcher from "./searchers/SqSearcher"
import SearchableData from "./searchers/local/SearchableData"
import SearchableGeoJson from "./searchers/local/SearchableGeoJson"
import SqDetailsHandler from "./details/SqDetailsHandler"
import ComposedDetailsHandler from "./details/ComposedDetailsHandler"
import S4InfoHandler from "./searchers/detailhandlers/S4InfoHandler"
import DemoDetailsHandler from "./searchers/detailhandlers/DemoDetailsHandler"
import DummyMoreHandler from "./searchers/detailhandlers/DummyMoreHandler"
import Controller from "./Controller"
import ClassRegistry from "./ClassRegistry"
import * as types from '../../types.json'
import ClientSearcher from "./searchers/ClientSearcher"
import ThrowingDetailsProvider from "./searchers/detailhandlers/ThrowingDetailsProvider"

const coreTypes = new ClassRegistry("Septima.Search.")
coreTypes.setTypes(types)

const typesToAdd = {
  ArcGisSearcher,
  DataSearcher,
  ClientSearcher,
  S4IndexSearcher,
  S3Searcher,
  SqSearcher,
  SearchableData,
  SearchableGeoJson,
  SqDetailsHandler,
  ComposedDetailsHandler,
  S4InfoHandler,
  DemoDetailsHandler,
  ThrowingDetailsProvider,
  DummyMoreHandler,
  Controller
}

for (let [key, value] of Object.entries(typesToAdd))
  coreTypes.addClass(value, key)

export default coreTypes