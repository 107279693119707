import pino from 'pino'

const defaultOptions = {
  timeout: 5000,
  jsonpCallback: 'callback',
  jsonpCallbackFunction: null,
}

function generateCallbackFunction() {
  return `jsonp_${Date.now()}_${Math.ceil(Math.random() * 100000)}`
}

function clearFunction(functionName) {
  // IE8 throws an exception when you try to delete a property on window
  // http://stackoverflow.com/a/1824228/751089
  try {
    delete window[functionName]
  } catch (e) {
    window[functionName] = undefined
  }
}

function removeScript(scriptId) {
  const script = document.getElementById(scriptId)
  if (script)
    document.getElementsByTagName('head')[0].removeChild(script)
}

function fetchJsonp(_url, options = {}) {
  // to avoid param reassign
  let url = _url
  const timeout = options.timeout || defaultOptions.timeout
  const jsonpCallback = options.jsonpCallback || defaultOptions.jsonpCallback

  let timeoutId

  return new Promise((resolve, reject) => {
    const callbackFunction = options.jsonpCallbackFunction || generateCallbackFunction()
    const scriptId = `${jsonpCallback}_${callbackFunction}`

    window[callbackFunction] = (response) => {
      resolve({
        ok: true,
        // keep consistent with fetch API
        json: () => Promise.resolve(response),
      })

      if (timeoutId) clearTimeout(timeoutId)

      removeScript(scriptId)

      clearFunction(callbackFunction)
    }

    // Check if the user set their own params, and if not add a ? to start a list of params
    url += (url.indexOf('?') === -1) ? '?' : '&'

    const jsonpScript = document.createElement('script')
    jsonpScript.setAttribute('src', `${url}${jsonpCallback}=${callbackFunction}`)
    if (options.charset)
      jsonpScript.setAttribute('charset', options.charset)
    jsonpScript.id = scriptId
    document.getElementsByTagName('head')[0].appendChild(jsonpScript)

    timeoutId = setTimeout(() => {
      reject(new Error(`JSONP request to ${_url} timed out`))

      clearFunction(callbackFunction)
      removeScript(scriptId)
      window[callbackFunction] = () => {
        clearFunction(callbackFunction)
      }
    }, timeout)

    // Caught if got 404/500
    jsonpScript.onerror = () => {
      reject(new Error(`JSONP request to ${_url} failed`))

      clearFunction(callbackFunction)
      removeScript(scriptId)
      if (timeoutId) clearTimeout(timeoutId)
    }
  })
}


export function inherit(C, P) {
  const F = function() {
  }
  F.prototype = P.prototype
  C.prototype = new F()
  let i, l, o
  for (i = 2, l = arguments.length; i < l; i++) {
    o = arguments[i]
    if (typeof o === "function") 
      o = o.prototype
    
    extend(C.prototype, o)
  }
}

export function extend(destination, source) {
  destination = destination || {}
  if (source) {
    for (let property in source) {
      let value = source[property]
      if (value !== undefined) 
        destination[property] = value
      
    }
    if (source.hasOwnProperty && source.hasOwnProperty("toString")) 
      destination.toString = source.toString
    
  }
  return destination
}

export function Class() {
  const len = arguments.length
  const P = arguments[0]
  const F = arguments[len - 1]

  const C = typeof F.initialize == "function" ?
    F.initialize :
    function() {
      P.apply(this, arguments)
    }

  if (len > 1) {
    const newArgs = [C, P].concat(
      Array.prototype.slice.call(arguments).slice(1, len - 1), F)
    inherit.apply(null, newArgs)
  } else {
    C.prototype = F
  }
  return C
}

// export const Events = Class({
//
//   _listeners: [],
//
//   initialize: function () {
//     this._listeners = [];
//   },
//
//   /**
//    * APIMethod: on Add an event listener
//    *
//    * Parameters: event - {String} Name of the event to listen to callback -
//    * {Function} Function to call when event fired
//    *
//    * Returns: listener - {object} Reference to the listener
//    */
//   on: function (event, callback) {
//     const listener = {event: event, callback: callback};
//     this._listeners.push(listener);
//     return listener;
//   },
//
//   /**
//    * APIMethod: off Remove an event listener
//    *
//    * Parameters: listener - {object} Reference to the listener returned by
//    * addListener
//    */
//   off: function (listener) {
//     for (let i = 0; i < this._listeners.length; i++) {
//       if (this._listeners[i] == listener) {
//         this._listeners.splice(i, 1);
//         break;
//       }
//     }
//   },
//
//   /**
//    * APIMethod: fireEvent Fire an event with a optional number of arguments
//    *
//    * Parameters: event - {String} Name of the event to listen to parameters -
//    * {Object} Optional object parsed to the listener
//    */
//   fireEvent: function (event) {
//     for (let i = 0; i < this._listeners.length; i++) {
//       if (this._listeners[i].event == event) {
//         if (typeof this._listeners[i].callback !== 'undefined') {
//           this._listeners[i].callback.apply(this, arguments);
//         }
//       }
//     }
//   }
// });

export function bind(func, object) {
  // create a reference to all arguments past the second one
  const args = Array.prototype.slice.apply(arguments, [2])
  return function() {
    // Push on any additional arguments from the actual function call.
    // These will come after those sent to the bind call.
    const newArgs = args.concat(
      Array.prototype.slice.apply(arguments, [0])
    )
    return func.apply(object, newArgs)
  }
}

export async function fetch2(url, options = {}, loggerArg) {
  let baseLogger = loggerArg || pino({ level: 'error' })
  const myLogger = baseLogger.child({module: 'utils', function: 'fetch2'})
  let expects = "json"
  if (options && options.expects)
    expects = options.expects
  options.headers = {}
  options.method = (options.method ? options.method.toLowerCase() : 'get')
  if (options.data) {
    if (options.method === 'post') {
      options.body = formData(options.data)
      options.headers = {
        'Accept': 'application/json, application/xml, text/play, text/html, */*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }
    } else {
      options.headers = {
        'Accept': (expects === 'xml')? 'application/xml' : 'application/json'
      }
      url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(options.data)
    }
    delete options.data
  }
  options.headers['Accept'] = 'application/json, application/xml, text/play, text/html, application/javascript, */*'
  if (options.Authorization) {
    if (options.Authorization.Basic && options.Authorization.Basic.username && options.Authorization.Basic.password)
      options.headers["Authorization"] = 'Basic ' + base64Encode(options.Authorization.Basic.username + ":" + options.Authorization.Basic.password)
    delete options.Authorization
  }
  options.crossDomain = true
  if (options && options.expects)
    delete options.expects
  const start = Date.now()  
  let response = await fetch(url, options)
  if (!response.ok)
    myLogger.warn('Server response not ok')
  const duration = Date.now() - start
  let logObject = {}
  logObject[options.method] = url
  logObject.duration = duration
  myLogger.debug(logObject)
  if (expects === "xml")  
    return await response.text()
  else
    return await response.json()
}

function formData(obj) {
  let formData = []
  Object.keys(obj).forEach((key) => {
    const name = encodeURIComponent(key)
    const value = encodeURIComponent(obj[key])
    formData.push(name + '=' + value)
  })
  return formData.join('&')
}

export async function fetch3(url, options = {}, loggerArg) {
  if (typeof window === 'undefined') {
    return await fetch2(url, options, loggerArg)
  } else {
    let baseLogger = loggerArg || pino({ level: 'error' })
    const myLogger = baseLogger.child({module: 'utils', function: 'fetch3'})

    if (options.data) {
      url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams(options.data)
      delete options.data
    }
    const start = Date.now()
    let response = await fetchJsonp(url, options)
    const duration = Date.now() - start
    let logObject = {}
    logObject[options.method] = 'get (via jsonp)'
    logObject.duration = duration
    myLogger.debug(logObject)
    return await response.json()
  }
}

function queryParams(params) {
  return Object.keys(params)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    //.map(k => k + '=' + params[k])
    .join('&')
}

function base64Encode(str) {
  try {
    return btoa(str)
  } catch(err) {
    return Buffer.from(str).toString('base64')
  }
}
