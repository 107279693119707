import DetailsHandlerDef from "../../details/DetailsHandlerDef"
import DetailItemsList from "../../details/DetailItemsList"

export default class DagiInfoProvider extends DetailsHandlerDef {
  constructor(options = {}) {
    if (!options.geoSearcher)
      throw "DagiInfoProvider needs a geoSearcher"

    let defaultOptions = {
      buttonText: "Administrativ inddeling",
      isApplicable: (result) => (result.geometry !== null)
    }
    super(Object.assign(defaultOptions, options))
    this.handlerFunction = this.myHandler
    this.geoSearcher = options.geoSearcher
    this.typeIds = ['kommuner_result', 'opstillingskredse_result', 'politikredse_result', 'postdistrikter_result', 'regioner_result', 'retskredse_result', 'sogne_result']
    if (options.fields)
      this.typeIds = options.fields 
    
    //this.typeIds = ['kommuner', 'kommuner_result', 'opstillingskredse', 'opstillingskredse_result', 'politikredse', 'politikredse_result', 'postdistrikter', 'postdistrikter_result', 'regioner', 'regioner_result', 'retskredse', 'retskredse_result', 'sogne', 'sogne_result']
    //this.typeIds = ['kommuner', 'opstillingskredse', 'politikredse', 'postdistrikter', 'regioner', 'retskredse', 'sogne']
  }

  async myHandler(result) {
    let items = []
    let sqPromises = []
    for (let typeId of this.typeIds) {
      let resultSpecPos = typeId.indexOf("_result")
      if (resultSpecPos > -1)
        typeId = typeId.substring(0, resultSpecPos)
      let query = {
        geometry: result.geometry,
        target: {type: typeId}
      }
      sqPromises.push(this.geoSearcher.sq(query))
    }
    await Promise.all(sqPromises)
    for (let promise of sqPromises) {
      let sqResults = await promise
      let results = sqResults.getResults()
      if (results.length > 0) {
        let typeId = results[0].typeId
        let itemType = "labelvalue"
        if (this.typeIds.indexOf(typeId + "_result") > -1)
          itemType = "result"
        let singular = results[0].type.singular
        let header = singular
        if (results.length > 1)
          header = results[0].type.plural
        let detailItemsList = new DetailItemsList({itemType, header})
        for (let result of results) {
          let item = {
            type: itemType
          }
          if (itemType === "labelvalue")
            item.value = result.title
          else
            item.result = result

          detailItemsList.append(item)
        }
        items.push(detailItemsList.asItem())
      } // if results
    } // for each promise
    return items
  }
  
}