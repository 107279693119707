/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef"
import icons from  '../../resources/icons'
/**
 * Viser info for Vedtagne lokalplaner, byggefelter, delområder, kommuneplanrammer og regionplaner
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration:<a href='../examples/plansystemsearcher/'>Example</a></caption>
    plansystemsearcher:
      _type: Septima.Search.PlanSystemSearcher
      _options:
        minimumShowCount: 3
        targets:
          - vedtagetlokalplan
          - kommuneplanramme
          - regionplan
          - byggefelt
          - delområde
        searchindexToken: septimaSEARCHDEMO-A7OLGHG2J4
      detailhandlers:
        - _type: "Septima.Search.PlanInfoProvider"
          _options:
            fields:
              lp: [plannr', 'plannavn', 'anvendelsegenerel', 'doklink', 'anvspec', 'datovedt', 'datoforsl']
 * @example <caption> JS options: </caption>
 options = {
    fields: {
      lp: [plannr', 'plannavn', 'anvendelsegenerel', 'doklink', 'anvspec', 'datovedt', 'datoforsl']
    }
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * planSearcher.addDetailHandlerDef(new Septima.Search.PlanInfoProvider(options))
 *
 * @example <caption>ES6:</caption>
 * import PlanInfoProvider from './searchers/detailhandlers/PlanInfoProvider'
 * planSearcher.addDetailHandlerDef(new PlanInfoProvider(options))
 * @api
 **/

export default class PlanInfoProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.fields] Felter, der skal medtages i den viste info
   * @param {string[]} [options.fields.lp=["anvendelsegenerel", "plannr"]] For lokalplaner
   * @param {string[]} [options.fields.pl = ["anvendelsegenerel", "plannr", "anvspec", "sforhold", "notomranv", "datoikraft", "datoforsl", "datovedt"]] For kommuneplanrammer
   * @param {string[]} [options.fields.bf = ["delnr", "doklink", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]] For byggefelter
   * @param {string[]} [options.fields.do = ["delnr", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]] For delområder
   * @param {string[]} [options.fields.rp = ["amt", "doklink"]] For regionplaner
   **/
  constructor(options= {}) {
    let defaultOptions = {
      buttonText: "Plan-info",
      more: true
    }
    super(Object.assign(defaultOptions, options))

    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.handler

    //Default fields
    this.fields = {}
    this.fields.lp = ["anvendelsegenerel", "plannr"]
    this.fields.kpl = ["anvendelsegenerel", "plannr", "anvspec", "sforhold", "notomranv", "datoikraft", "datoforsl", "datovedt"]
    this.fields.bf = ["delnr", "doklink", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]
    this.fields.do = ["delnr", "planid", "lp_plannavn", "lp_plannr", "lokplan_id", "anvspec", "datoikraft", "datoforsl", "datovedt"]
    this.fields.rp = ["amt", "doklink"]

    //Read fields from options
    if (typeof options !== 'undefined' && typeof options.fields !== 'undefined') {
      if (typeof options.fields.lp !== 'undefined')
        this.fields.lp = options.fields.lp
      if (typeof options.fields.kpl !== 'undefined')
        this.fields.kpl = options.fields.kpl
      if (typeof options.fields.bf !== 'undefined')
        this.fields.bf = options.fields.bf
      if (typeof options.fields.do !== 'undefined')
        this.fields.do = options.fields.do
      if (typeof options.fields.rp !== 'undefined')
        this.fields.rp = options.fields.rp
    }
  }

  isApplicable(result) {
    return (result.source === "plansystem")
  }
  
  async handler(result) {
    let anvspecvals = []
      
    if (result.typeId === "vedtagetlokalplan" || result.typeId === "kommuneplanramme" || result.typeId === "byggefelt" || result.typeId === "delområde") 
      Object.entries(result.data.properties).map(obj => {
        const key = obj[0]
        const value = obj[1]
        if (key.startsWith('anvspec') && value)
          anvspecvals.push(result.searcher.getAnvendelsesTekstFraKode(value))
      })
 
    let items = []
    if (result.typeId === "vedtagetlokalplan")
      for (let field of this.fields.lp) { 
        let item = this.createLpItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)
        
      } else if (result.typeId === "kommuneplanramme")
      for (let field of this.fields.kpl) {
        let item = this.createKplItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)
        
      } else if (result.typeId === "byggefelt")
      for (let field of this.fields.bf) {
        let item = await this.createBfItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)
        
      } else if (result.typeId === "delområde")
      for (let field of this.fields.do) {
        let item = await this.createDoItem(result, field, anvspecvals.join(', '))
        if (typeof item !== 'undefined')
          items.push(item)
        
      } else if (result.typeId === "regionplan")
      for (let field of this.fields.rp) {
        let item = await this.createRpItem(result, field)
        if (typeof item !== 'undefined')
          items.push(item)
      }
    
    return items

  }

  createRpItem(result, field) {
    let props = result.data.properties
    if (field === 'amt')
      return {
        type: 'labelvalue',
        label: 'Amt',
        value:  props.amtnavn.replace('Ã¸','ø')
      }
    else if (field === 'doklink')
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.dokumentna,
        target: '_blank',
        icon: icons.details.link,
      }
    else if (field === 'dokumentnavn')
      return {
        type: 'labelvalue',
        label: 'Dokumentnavn',
        value: props.dokumentna

      }
    return
  }

  async createDoItem(result, field, anvspec) {

    let props = result.data.properties

    if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        target: '_blank',
        icon: icons.details.link,

      }
    } else if (field === 'lp_plannavn') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan',
        value: props.lp_plannavn

      }
    } else if (field === 'lokplan_id') {
      let planResult = await result.searcher.get(props.lokplan_id, 'vedtagetlokalplan')
      return {
        type: 'result',
        result: planResult
      }
    } else if (field === 'lp_plannr') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan nr',
        value: props.lp_plannr
      }
    } else if (field === 'delnr') {
      return {
        type: 'labelvalue',
        label: 'Delnr',
        value: props.delnr

      }
    } else if (field === 'anvspec') {
      return     {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'sforhold') {
      return  {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold
      }
    } else if (field === 'notomranv') {
      return  {
        type: 'labelvalue',
        label: 'Rammetekst områdets anvendelse',
        value: props.notomranv
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return  {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return  {
        type: 'labelvalue',
        label: 'Dato i forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return  {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return
  }  
  
  async createBfItem(result, field, anvspec) {

    let props = result.data.properties

    if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        icon: icons.details.link,

      }
    } else if (field === 'lp_plannavn') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan',
        value: props.lp_plannavn

      }
    } else if (field === 'lokplan_id') {
      let planResult = await result.searcher.get(props.lokplan_id, 'vedtagetlokalplan')
      return {
        type: 'result',
        result: planResult
      }
    } else if (field === 'lp_plannr') {
      return {
        type: 'labelvalue',
        label: 'Lokalplan nr',
        value: props.lp_plannr
      }
    } else if (field === 'delnr') {
      return {
        type: 'labelvalue',
        label: 'Delnr',
        value: props.delnr

      }
    } else if (field === 'anvspec') {
      return     {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'sforhold') {
      return  {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold
      }
    } else if (field === 'notomranv') {
      return  {
        type: 'labelvalue',
        label: 'Rammetekst områdets anvendelse',
        value: props.notomranv
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return  {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return  {
        type: 'labelvalue',
        label: 'Dato i forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return  {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return
  }
  
  createKplItem(result, field, anvspec) {

    let props = result.data.properties
    if (field === 'anvendelsegenerel') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse generel',
        value: props.anvendelsegenerel || 'Anvendelse ikke reguleret'
      }
    } else if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        target: '_blank',
        icon: icons.details.link,

      }
    } else if (field === 'plannavn') {
      return {
        type: 'labelvalue',
        label: 'Plannavn',
        value: props.plannavn

      }
    } else if (field === 'anvspec') {
      return     {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'plannr') {
      return {
        type: 'labelvalue',
        label: 'Plannr',
        value: props.plannr || 'ingen plannr'
      }
    } else if (field === 'sforhold') {
      return  {
        type: 'labelvalue',
        label: 'Særlige forhold',
        value: props.sforhold || 'Ingen særlige forhold angivet'
      }
    } else if (field === 'notomranv') {
      return  {
        type: 'labelvalue',
        label: 'Rammetekst områdets anvendelse',
        value: props.notomranv || 'Ingen rammetekst angivet'
      }
    } else if (field === 'datoikraft') {
      let strDate = "" + props.datoikraft
      return  {
        type: 'labelvalue',
        label: 'Dato i kraft',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return  {
        type: 'labelvalue',
        label: 'Dato i forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return  {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return
  }
  createLpItem(result, field, anvspec) {
  
    let props = result.data.properties
    if (field === 'anvendelsegenerel') {
      return {
        type: 'labelvalue',
        label: 'Anvendelse generel',
        value: props.anvendelsegenerel || 'Anvendelse ikke reguleret'
      }
    } else if (field === 'doklink') {
      return {
        type: 'link',
        label: 'Plandokument',
        link: props.doklink,
        linkTitle: props.plannavn,
        target: '_blank',
        icon: icons.details.link,
  
      }
    } else if (field === 'plannavn') {
      return {
        type: 'labelvalue',
        label: 'Plannavn',
        value: props.plannavn
  
      }
    } else if (field === 'anvspec') {
      return     {
        type: 'labelvalue',
        label: 'Anvendelse specifik',
        value: anvspec
      }
    } else if (field === 'plannr') {
      return {
        type: 'labelvalue',
        label: 'Plannr',
        value: props.plannr || 'ingen plannr'
      }
    } else if (field === 'datoforsl') {
      let strDate = "" + props.datoforsl
      return  {
        type: 'labelvalue',
        label: 'Dato forslag',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    } else if (field === 'datovedt') {
      let strDate = "" + props.datovedt
      return  {
        type: 'labelvalue',
        label: 'Dato vedtaget',
        value: strDate.substr(0, 4) + "-" + strDate.substr(4, 2) + "-" + strDate.substr(6, 2),
        valueformat: "iso-date"
      }
    }
    return 
  }

}