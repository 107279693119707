/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef"
import icons from "../../resources/icons"
import * as reproject from "../../util/reproject"
import {getWKTParser} from "../../util/getWKTParser"
/**
 * Viser link til relevante offentlige hjemmesider for enten en adresse eller an matrikel
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.DawaSearcher
 * _options:
 *   kommunekode: '101'
 *   allowDetails: true
 * detailhandlers:
 *   - _type: Septima.Search.OffentligeLinksForAdresseMatrikel
 *     _options:
 *        links: ["jfa","bbrmeddelelse","ejendomsinfo.dk","boligejer.dk","vurdering","bbkort","ois"]
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * dawaSearcher.addDetailHandlerDef(new Septima.Search.OffentligeLinksForAdresseMatrikel({more: true}))
 *
 * @example <caption>ES6:</caption>
 * import OffentligeLinksForAdresseMatrikel from './searchers/detailhandlers/OffentligeLinksForAdresseMatrikel'
 * dawaSearcher.addDetailHandlerDef(new OffentligeLinksForAdresseMatrikel({more: false}))
 * @api
 **/
export default class OffentligeLinksProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} [options.links=["jfa","bbrmeddelelse","ejendomsinfo.dk","boligejer.dk","vurdering","bbkort","ois"]] Hvilke links skal vises.
   * Muligheder: "jfa","bbrmeddelelse","ejendomsinfo.dk","boligejer.dk","vurdering","bbkort","ois", "geokoder", "retbbr", "skraafoto", "skraafoto_js", "sdfekort", "plankort"
   **/


  constructor(options= {}) {
    if (!options.buttonText)
      options.buttonText = "Offentlige links"

    super(options)
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler

    this.links = ["jfa","bbrmeddelelse","ejendomsinfo.dk","boligejer.dk","vurdering","bbkort","ois"]
    if (typeof options.links !== 'undefined') {
      if (options.links.length > 0 && options.links[0] === "*")
        this.links = ["jfa","bbrmeddelelse","ejendomsinfo.dk","boligejer.dk","vurdering","bbkort","ois", "geokoder", "retbbr", "skraafoto_js", "sdfekort", "plankort"]
      else
        this.links = options.links
    }
  }

  isApplicable() {
    return false
  }

  async myHandler() {
    return []
  }

  createLinks(result, komnr, esrnr, pointGeom, elavskode, matrnr) {
    let items = []
    for (var link of this.links) {
      let item = this.createItem(result, link, komnr, esrnr, pointGeom, elavskode, matrnr)
      if (item)
        items.push(item)
    }
    return items
  }

  createItem(result, link, komnr, esrnr, pointGeom, elavskode, matrnr) {

    if (link === 'bbrmeddelelse' && komnr && esrnr) {
      let buttonImage= icons.pdf
      return {type: 'link',
        icon: this.more ? buttonImage : icons.details.link,
        link: `https://ejendomsinfo.dk/bbrmeddelelse?id=${komnr},${esrnr}`,
        linkTitle: "Se BBR meddelelse"}
    } else if (link === 'bbkort' && komnr && esrnr) {
      return {type: 'link',
        icon: icons.globe,
        link: `https://kort.bbr.dk/link/esr/${komnr}/${esrnr}`,
        //link: `http://kort.bbr.dk/#kommunekode=${komnr.substr(1, 3)}+ejendomsnummer=${esrnr}`,
        linkTitle: `BBRkort (ESR ${esrnr})`}
    } else if (link === 'ejendomsinfo.dk' && komnr && esrnr) {
      return {type: 'link',
        icon: icons.exlink,
        link: `https://ejendomsinfo.dk/ejendomsdata/0/3/0/${esrnr}|${komnr}|`,
        linkTitle:`Ejendomsinfo.dk (ESR ${esrnr})`}
    } else if (link==='boligejer.dk' && komnr && esrnr) {
      return {type: 'link',
        icon: icons.exlink,
        link: `http://boligejer.dk/ejendomsdata/0/10/0/${esrnr}|${komnr}`,
        linkTitle: `Boligejer.dk (ESR ${esrnr})`}
    } else if (link==='vurdering' && komnr && esrnr) {
      return {type: 'link',
        icon: icons.exlink,
        link: `http://www.vurdering.skat.dk/borger/ejendomsvurdering/Vis.do?KMNR=${komnr.substr(1, 3)}&sideNavn=vstarte&EJDNR=${esrnr}&POSTNR=&VEJKODE=&VEJNAVN`,
        linkTitle: `Ejendomsvurdering på skat.dk (ESR ${esrnr})`}
    } else if (link==='jfa' && elavskode && matrnr) {
      let url = "http://jordrapport.miljoeportal.dk/?elav=" +elavskode + "&matrnr="+ matrnr
      let buttonImage= icons.pdf
      return { type: 'link',
        icon: this.more ? buttonImage : icons.details.link,
        link: url,
        linkTitle: "Se jordforureningsattest fra Miljøportalen"}
    } else if (link==='ois' && komnr && esrnr) {
      return { type: 'link',
        icon: icons.exlink,
        link: "http://www.ois.dk/ui/linkpartner/linkpartner.aspx?CBKORT=TRUE&komnr=" + komnr + "&ejdnr=" + esrnr,
        linkTitle: "OIS.dk"}
    } else if (link === 'geokoder' && pointGeom) {
      let pointCoord =`${pointGeom.coordinates[0]},${pointGeom.coordinates[1]}`
      return  {type: 'link',
        icon: icons.bbrhouse,
        link: `https://www.geokoderen.dk/#centrum=${pointCoord}`,
        linkTitle: `Vis i Geokoderen`}
    } else if (link === 'retbbr' && komnr && esrnr) {
      return {type: 'link',
        icon: icons.bbrhouse,
        link: `https://ret2.bbr.dk/oversigt/0/7/0/${komnr}|${esrnr}`,
        linkTitle: `Vis i retBBR (ESR ${esrnr})`}
    } else if ((link === 'skraafoto' || link === 'skraafoto_js') && pointGeom) {
      let latLon = this.convert2582GeometryTowgs84LatLon(pointGeom)
      let endpoint = "https://skraafoto.kortforsyningen.dk/oblivisionjsoff"
      if (link === 'skraafoto_js')
        endpoint = "https://skraafoto.kortforsyningen.dk/oblivisionjs"
      let url = endpoint + "/index.aspx?project=Denmark&lon=" + latLon.lon + "&lat=" + latLon.lat
      return { type: 'link',
        icon: icons.exlink,
        link: url,
        linkTitle: "Se skråfoto fra SDFE"}
    } else if(link === 'sdfekort' && result.geometry) {
      let wktParser = getWKTParser()
      let wkt  = wktParser.convert(result.geometry)
      let url
      if (pointGeom) {
        let pointWKT = wktParser.convert(pointGeom)
        let label = result.title + (result.description ? ("-" + result.description) : "")
        url = `http://sdfekort.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens+matrikelkort&layers=theme-gst-dtkskaerm_daempet+theme-gst-matrikelkort-roed+theme-gst-forvaltning-husnummer&label=${label}&labelPos=${pointWKT}`
      } else {
        url = `http://sdfekort.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens+matrikelkort&layers=theme-gst-dtkskaerm_daempet+theme-gst-matrikelkort-roed+theme-gst-forvaltning-husnummer`
      }
      return { type: 'link',
        icon: icons.exlink,
        link: url,
        linkTitle: "Se i SDFE Kortviser"}
    }else if(link === 'plankort' && result.geometry) {
      let wktParser = getWKTParser()
      let wkt  = wktParser.convert(result.geometry)
      let url
      if (pointGeom) {
        let pointWKT = wktParser.convert(pointGeom)
        let label = result.title + (result.description ? ("-" + result.description) : "")
        url = `http://kort.plandata.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens&layers=theme-gst-dtkskaerm_daempet+theme-gst-matrikelkort-roed&label=${label}&labelPos=${pointWKT}`
      } else {
        url = `http://kort.plandata.dk/spatialmap?wkt=${wkt}&selectorgroups=admgraens&layers=theme-gst-dtkskaerm_daempet+theme-gst-matrikelkort-roed`
      }
      return { type: 'link',
        icon: icons.exlink,
        link: url,
        linkTitle: "Se på kort.plandata.dk"}
    }
  }

  convert2582GeometryTowgs84LatLon(geometry) {
    let crss = {
      "EPSG:25832": "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
      "EPSG:4326": "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"
    }
    let geoJsonWgs84 = reproject.reproject(geometry, "EPSG:25832", "EPSG:4326", crss)
    return {lon: geoJsonWgs84.coordinates[0], lat: geoJsonWgs84.coordinates[1]}
  }

}
