import { fetch2} from '../utils'

const serviceUrl = "https://dawa.aws.dk"

export class Query {
  constructor(options) {
    this.options = Object.assign({}, options)
    if (typeof this.options.kommunekode !== 'undefined') 
      this.options.kommunekode = this.options.kommunekode.toString()
  }
	
  async query() {
    //Hvis der er indtastet en gyldig vejkode så returnér newQuery med vejen
    if (this.queryCouldBeVejkode(this.options.query)) {
      let getOptions = {
        kode: this.options.query
      }
      if (typeof this.options.kommunekode !== 'undefined' && this.options.kommunekode !== "*") 
        getOptions.kommunekode = this.options.kommunekode
        
      let vejStykker = await new VejStykkeGetter().get(getOptions)
      if (typeof vejStykker!== 'undefined') {
        let veje = []
        for (let vejStykke of vejStykker) 
          veje.push({type: "vej", vejnavn: vejStykke.properties.navn, postnummer: vejStykke.properties.postnumre[0].nr, postdistrikt: vejStykke.properties.postnumre[0].navn})
          
        return veje
      }
    }
    this.adresseQuery = new AdresseQuery(this.options)
    this.vejQuery = new VejQuery(this.options)
    await Promise.all([this.adresseQuery.query(), this.vejQuery.query()])
    let searchResult = await this.buildSearchResult()
    return searchResult
  }
    
  queryCouldBeVejkode(query) {
    if (query.length === 4 ) {
      let parseResult = parseInt(query)
      if( !isNaN(parseResult)) 
        return true
        
    }
    return false
  }

  async buildSearchResult() {
    let adrCount = this.adresseQuery.getAdresseCount()
    const vejCount = this.vejQuery.getVejCount()
    const vejnavnCount = this.vejQuery.getVejnavnCount()
    const limit = this.options.limit
    
    let result = []

    if (adrCount === 0) {
      return result
    } else if (vejCount > 1) {
      if (vejCount <= limit || vejnavnCount == 1)
        result =  this.vejQuery.buildVejResult(limit)
      else if (vejnavnCount > 0)
        result =  this.vejQuery.buildVejnavnResult(limit)
    }else {
      result = this.adresseQuery.buildAdresseResult(limit)
      if (this.options.showStreets)
      //Vis altid vej(ene) (Sikrer, at man kan vælge vejen)
        if (vejCount === 1)
          result = this.vejQuery.buildVejResult(limit).concat(result)
        else if (typeof this.options.kommunekode !== 'undefined' && this.options.kommunekode !== "*")
          result = this.adresseQuery.buildVejResult().concat(result)
    }
    return result
  }
}

export class AdresseQuery {

  constructor(options) {
    this.response = []
    this.options = options
  }
	
  async query() {
    if (this.options.query === "" && 1 === 2) {
      return
    }else{
      const callParameters = {
        q: this.options.query === "" ? "a" : this.options.query,
        type: "adgangsadresse",
        side: 1,
        per_side: this.options.limit + 1,
        noformat: 1,
        srid: 25832
      }
      if (typeof this.options.kommunekode !== 'undefined' && this.options.kommunekode !== "*") 
        callParameters.kommunekode = this.options.kommunekode
        
      let data = await fetch2(serviceUrl + "/adgangsadresser/autocomplete", {data: callParameters})
      let directResponse = data
      directResponse = this.sortAdresseResponse(directResponse)
      callParameters.q = callParameters.q + " "
      let data2 = await fetch2(serviceUrl + "/adgangsadresser/autocomplete", {data: callParameters})
      let blankAppendixResponse = data2
      if (blankAppendixResponse.length === directResponse.length) {
        this.response = directResponse
      }else{
        for (let i = directResponse.length -1; i >=0; i--) {
          let directAdr = directResponse[i]
          for (let j=0;j < blankAppendixResponse.length; j++) 
            if (blankAppendixResponse[j].adgangsadresse.id === directAdr.adgangsadresse.id) 
              directResponse.splice(i, 1)
        }
        this.response = blankAppendixResponse.concat(directResponse)
      }
      return
    }
  }
	
  getAdresseCount() {
    return this.response.length
  }

  buildVejResult() {
    let knownvejkoder = []
    let results = []
    for (let item of this.response) {
      let vejkode = item.adgangsadresse.vejkode
      if (knownvejkoder.indexOf(vejkode) === -1) {
        knownvejkoder.push(vejkode)
        results.push(
          {type: "vej",
            vejnavn: item.adgangsadresse.vejnavn,
            postnummer: item.adgangsadresse.postnr,
            postdistrikt: item.adgangsadresse.postnrnavn,
            vejkode: item.adgangsadresse.vejkode}
        )
      }
    }
    return results
  }
    
  getUniqueRoadCount() {
    let knownvejkoder = []
    for (let item of this.response) {
      let vejkode = item.adgangsadresse.vejkode 
      if (knownvejkoder.indexOf(vejkode) === -1)
        knownvejkoder.push(vejkode)
    }
    return knownvejkoder.length
  }
    
    
	
  buildAdresseResult(limit) {
    const result = []
    for (let i = 0; (i < this.response.length) && (result.length < limit); i++) {
      const adr = this.response[i]
      let geometry = {
        "type": "Point",
        "coordinates": [
          adr.adgangsadresse.x,
          adr.adgangsadresse.y
        ]
      }
      adr.adgangsadresse.x
      result.push({type: "adgangsadresseinfo", tekst: adr.tekst, id: adr.adgangsadresse.id, geometry: geometry})
    }
    return result
  }
	
  sortAdresseResponse(response) {
    //	    {
    //	        "id": "0a3f507c-8d5d-32b8-e044-0003ba298018",
    //	        "href": "https://dawa.aws.dk/adgangsadresser/0a3f507c-8d5d-32b8-e044-0003ba298018",
    //	        "vejnavn": "Sortsøvej",
    //	        "husnr": "6",
    //	        "supplerendebynavn": null,
    //	        "postnr": "2730",
    //	        "postnrnavn": "Herlev",
    //	        "stormodtagerpostnr": null,
    //	        "stormodtagerpostnrnavn": null
    //	    }
    const sorter = function(r1, r2) {
      try {
        const h1 = parseInt(r1.adgangsadresse.husnr)
        const h2 = parseInt(r2.adgangsadresse.husnr)
        const p1 = parseInt(r1.adgangsadresse.postnr)
        const p2 = parseInt(r2.adgangsadresse.postnr)
        if (h1 === h2) 
          return (p1 - p2)
        else 
          return (h1 - h2)
          
      } catch (error) {
        return 0
      }
    }
    return response.sort(sorter)
  }
}

export class AdgangsAdresseGetter {
	
  async get(id) {
    let data = await fetch2(serviceUrl + "/adgangsadresser/" + id, {data: {srid: 25832, format: "geojson", struktur: "nestet"}})
    return data
  }

  async gets(ids) {
    let size = 100
    let features = []
    let getFeaturesResultPromises = []
    for (let i=0; i<ids.length; i+=size) {
      let idsToGet = ids.slice(i, i+size)
      getFeaturesResultPromises.push( fetch2(serviceUrl + "/adgangsadresser/", {data: {id: idsToGet.join("|"), srid: 25832, format: "geojson", struktur: "nestet"}}))
    }
    
    let getFeaturesResults = await Promise.all(getFeaturesResultPromises)
    for (let j=0;j<getFeaturesResults.length;j++) {
      let getFeaturesResult = getFeaturesResults[j]
      let newFeatures = getFeaturesResult.features 
      features = [...features, ...newFeatures]
    }
    //for (let getFeaturesResult of getFeaturesResults)
    let returnArray = features
    
    return {features: returnArray}
  }

  async gets_old(ids) {
    let size = 100
    let features = []
    for (let i=0; i<ids.length; i+=size) {
      let idsToGet = ids.slice(i, i+size)
      let toGetFeatures = await fetch2(serviceUrl + "/adgangsadresser/", {data: {id: idsToGet.join("|"), srid: 25832, format: "geojson", struktur: "nestet"}})
      features = features.concat(toGetFeatures.features)
    }
    return features
  }

  async sq_point(x, y) {
    let data =  await fetch2(serviceUrl + "/adgangsadresser/reverse", {data: {srid: 25832, format: "geojson", struktur: "nestet", x: x, y: y}})
    return data
  }

  async sq_polygon(polygonCoordinates) {
    let data =  await fetch2(serviceUrl + "/adgangsadresser", {data: {srid: 25832, format: "geojson", struktur: "nestet", polygon: polygonCoordinates}})
    return data
  }
  
  async sq(wkt) {
    let params = {
      servicename: 'WFS',
      typename: "aws4_wfs_secure:adgangsadresser",
      request: 'GetFeature',
      version: '1.0.0',
      outputformat: 'json',
      CQL_FILTER: 'intersects(geom,' + wkt + ')'
    }
    let result = await fetch2('https://kort.aws.dk/geoserver/wfs', {data: params, method: 'post'})
    let features = result.features
    let ids = []
    for (let feature of features)
      ids.push(feature.id.substring(16))
    let apiFeaturesResult = await this.gets(ids)
    return apiFeaturesResult.features
  }
}

export class EnhedsAdresseGetter {

  async get(id) {
    let data = await fetch2(serviceUrl + "/adresser/" + id, {data: {srid: 25832, format: "geojson", struktur: "nestet"}})
    return data
  }

  async gets(ids) {
    let data = await fetch2(serviceUrl + "/adresser/", {data: {id: ids.join("|"), srid: 25832, format: "geojson", struktur: "nestet"}})
    return data
  }
  
  async getEnhedsAdresserForAdresse(adresseId) {
    let data = await fetch2(serviceUrl + "/adresser/", {data: {adgangsadresseid: adresseId, srid: 25832, format: "geojson", struktur: "nestet"}})
    return data
  }
}

class VejQuery {
  constructor(options) {
    this.options = options
    this.response = []
    this.vejCount = null
  }
	
  async query() {
    let queryHasWordWhichIsNumber = / [\d]+(?![\D])/.test(this.options.query) 
    if (queryHasWordWhichIsNumber) {
      this.vejnavne = []
      this.vejstykker = []
    } else {
      const callParameters = {q: "", side: 1, per_side: this.options.limit + 1, noformat: 1}
      if (this.options.query !== "")
        callParameters.q = this.options.query + "*"

      if (typeof this.options.kommunekode !== 'undefined' && this.options.kommunekode !== "*")
        callParameters.kommunekode = this.options.kommunekode

      let vejnavnePromise = fetch2(serviceUrl + "/vejnavne", {data: callParameters})
      let vejstykkerPromise = fetch2(serviceUrl + "/vejstykker", {data: callParameters})
      await Promise.all([vejnavnePromise, vejstykkerPromise])
      this.vejnavne = await vejnavnePromise
      this.vejstykker = await vejstykkerPromise
    }
  }
	
  getVejCount() {
    if (this.vejCount === null) {
      let count = 0
      for (let vejstykke of this.vejstykker)
        count += vejstykke.postnumre.length
      this.vejCount = count
    }
    return this.vejCount
  }
	
  buildVejResult(limit) {
    const result = []
    for (let i = 0; (i < this.vejstykker.length) && (result.length < limit); i++) {
      let vejstykke = this.vejstykker[i]
      for (let j = 0; (j < vejstykke.postnumre.length) && (result.length < limit); j++) {
        let postnummer = vejstykke.postnumre[j]
        result.push({type: "vej", vejnavn: vejstykke.navn, postnummer: postnummer.nr, postdistrikt: postnummer.navn})
      }
    }
    return result
  }
	
  getVejnavnCount() {
    return this.vejnavne.length
  }
	
  buildVejnavnResult(limit) {
    const result = []
    for (let i = 0; (i < this.vejnavne.length) && (result.length < limit); i++) {
      let vejNavn = this.vejnavne[i]
      let postNummer
      if ((vejNavn.navn.toLowerCase() === this.options.query.toLowerCase()) && 1==2) {
        for (let j = 0; (j < vejNavn.postnumre.length) && (result.length < limit); j++) {
          postNummer = vejNavn.postnumre[j]
          result.push({type: "vej", vejnavn: vejNavn.navn, postnummer: postNummer.nr, postdistrikt: postNummer.navn})
        } 
      } else
      if (vejNavn.postnumre.length === 1) {
        postNummer = vejNavn.postnumre[0]
        result.push({type: "vej", vejnavn: vejNavn.navn, postnummer: postNummer.nr, postdistrikt: postNummer.navn})
      }else{
        result.push({type: "vejnavn", vejnavn: vejNavn.navn})
      }
        
    }
    return result
  }
}

export class VejStykkeGetter {
	
  async get(query) {
    query.srid = 25832
    query.format = "geojson"
    query.struktur="nestet"
    let data = await fetch2(serviceUrl + "/vejstykker", {data: query})
    return data.features
  }
}
