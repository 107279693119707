/**
 * @module
 */
import icons from '../resources/icons'
import {Query, VejStykkeGetter, AdgangsAdresseGetter, EnhedsAdresseGetter} from '../util/dawa.api'
import Searcher from './Searcher'
import {getString} from "../resources/strings"
import DetailsHandlerDef from "../details/DetailsHandlerDef"
import ResultType from "../ResultType"

/**
 *
 * Searches Dawa (http://dawa.aws.dk/)
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration: <a href='../examples/builder/'>Example</a></caption>
  _type: Septima.Search.DawaSearcher
  _options:
    kommunekode: 151
 * @example <caption> JS options: <a href='../examples/simple/'>Example</a></caption>
 * dawaSearchOptions = {
 *     kommunekode: "151",
 *     searchDelay: 200
 * };
 * @example <caption>js client:</caption>
 * <!-- Include septimaSearch -->
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.DawaSearcher(dawaSearchOptions))
 *
 * @example <caption>ES6:</caption>
 * import DawaSearcher from './searchers/DawaSearcher'
 * controller.addSearcher(new DawaSearcher(dawaSearchOptions))
 * @api
 */
export default class DawaSearcher extends Searcher {
  /**
   *
   * @param {Object} options DawaSearcher expects these properties:
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param {boolean} [options.showOneStreet=false]
   * @param {boolean} [options.showStreets=false]
   * @param {boolean} [options.allowDetails=false]
   * @param {boolean} [options.showDawaLinks=false]
   * @param {string} [options.source='Dawa']
   */

  constructor(options = {}) {
    super(Object.assign({
      usesGeoFunctions: true,
      iconURI:icons.searchers.dawa.result
    },
    options))

    //defaults    
    this.showOneStreet = false
    this.showStreets = false
    this.kommunekode = null
    this.source = "Dawa"
    this.allowDetails = false
    this.showDawaLinks = false
    
    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += '' 
      let kommunekoder = options.kommunekode.split(' ')
      this.kommunekode = kommunekoder.join('|')
    } 
    
    if (options.showOneStreet) 
      this.showOneStreet = options.showOneStreet

    if (options.showStreets)
      this.showStreets = options.showStreets

    if (options.allowDetails) 
      this.allowDetails = options.allowDetails

    if (options.showDawaLinks) 
      this.showDawaLinks = options.showDawaLinks

    if (options.source) 
      this.source = options.source

    let adresseType = new ResultType({
      id: "adresse",
      singular: "Adresse",
      plural: "Adresser"
    })
    this.registerType(this.source, adresseType)

    let enhedsAdresseType = new ResultType({
      id: "enhedsadresse",
      singular: "Enhedsadresse",
      plural: "Enhedsadresser",
      queryBehaviour: "none"
    })
    this.registerType(this.source, enhedsAdresseType)
  }

  async fetchData(query, caller) {
    const queryOptions = {
      limit: query.limit + 3,
      query: query.queryString,
      showStreets: this.showOneStreet || this.showStreets
    }

    if (this.kommunekode != null) 
      queryOptions.kommunekode = this.kommunekode

    try {
      let dawaResult = await new Query(queryOptions).query()
      caller.fetchSuccess(this.QueryResultFromDawaResult(query, dawaResult))
    } catch (error) {
      caller.fetchError(this, error)
    }
  }

  QueryResultFromDawaResult(query, dawaResult) {
    const queryResult = this.createQueryResult()
    const count = dawaResult.length

    if (["list", "cut", "no-cut"].indexOf(query.type) !== -1) {
      let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)
      for (let thisHit of dawaResult.slice(0, hitsShown))
        this.addHitToQueryResult(thisHit, queryResult)
      if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 ) {
        let newQuery
        if (hitsShown > 0)
          newQuery = queryResult.addNewQuery(this.source, "adresse", "Flere adresser", "", query.queryString, null, null)
        else
          newQuery = queryResult.addNewQuery(this.source, "adresse", "Adresser", "", query.queryString, null, null)
        newQuery.image = icons.searchers.dawa.result
      }
    }else{
      if (count > 0) {
        let newQuery = queryResult.addNewQuery(this.source, "adresse", "Adresser", "", query.queryString, null, null)
        newQuery.image = icons.searchers.dawa.result
      }
    }

    return queryResult
  }

  addHitToQueryResult(thisHit, queryResult) {
    let presentation
    let newQueryString
    let newQuery
    if (thisHit.type === "adgangsadresseinfo") {
      presentation = thisHit.tekst
      const result = queryResult.addResult(this.source, "adresse", presentation, null, thisHit.geometry, thisHit)
      result.id = thisHit.id
      result.isComplete = false
      result.image = icons.searchers.dawa.result
    } else if (thisHit.type === "vej") {
      newQueryString = thisHit.vejnavn + " <select></select>" + ' ' + thisHit.postnummer + ' ' + thisHit.postdistrikt
      presentation = thisHit.vejnavn + ' ' + thisHit.postnummer + ' ' + thisHit.postdistrikt
      newQuery = queryResult.addNewQuery(this.source, "adresse", presentation, null, newQueryString, null, thisHit)
      newQuery.image = icons.searchers.dawa.road
    } else if (thisHit.type === "vejnavn") {
      newQueryString = thisHit.vejnavn + " <select></select>"
      presentation = thisHit.vejnavn
      newQuery = queryResult.addNewQuery(this.source, "adresse", presentation, null, newQueryString, null, thisHit)
      newQuery.image = icons.searchers.dawa.road
    }
  }

  async sq(query) {
    let queryResult = this.createQueryResult()
    let querywkt = query.wkt
    if (querywkt) 
      if (querywkt.toLowerCase().indexOf("point") === 0) {
        /*
       let geom = this.translateWktToGeoJsonObject(querywkt)
        let x = geom.coordinates[0]
        let y = geom.coordinates[1]
        let data = await new AdgangsAdresseGetter().sq_point(x, y)
        if (data.type != 'Feature')
          throw new Error(data.title + '\n' + JSON.stringify(data))
        let result = queryResult.addResult(this.source, "adresse", data.properties.vejstykke.navn + " " + data.properties.husnr + ", " + data.properties.postnummer.nr + " " + data.properties.postnummer.navn, null, data.geometry, data)
        result.id = data.properties.id
        result.isComplete = true
        let xr = data.geometry.coordinates[0]
        let yr = data.geometry.coordinates[1]
        result.distance = Math.sqrt(Math.pow((x - xr), 2) + Math.pow((y - yr), 2))
        result.data.type = 'adgangsadresse'
        */
      } else if (querywkt.toLowerCase().indexOf("polygon") === 0) {
        let features = await new AdgangsAdresseGetter().sq(querywkt)
        const sorter = function(f1, f2) {
          try {
            const n1 = f1.properties.vejstykke.navn
            const n2 = f2.properties.vejstykke.navn
            if (n1 === n2) {
              const h1 = parseInt(f1.properties.husnr)
              const h2 = parseInt(f2.properties.husnr)
              if (h1 === h2)
                return (f1.properties.husnr.localeCompare(f2.properties.husnr))
              else
                return (h1 - h2)
            } else {
              return (n1.localeCompare(n2))
            }

          } catch (error) {
            return 0
          }
        }
        features = features.sort(sorter)
        
        for (let feature of features) {
          let result = queryResult.addResult(this.source, "adresse", feature.properties.vejstykke.navn + " " + feature.properties.husnr + ", " + feature.properties.postnummer.nr + " " + feature.properties.postnummer.navn, null, feature.geometry, feature)
          result.distance = 0
          result.id = feature.properties.id
          result.isComplete = true
          result.data.type = 'adgangsadresse'
        }
      }
    
    return queryResult
  }

  error(caller, jqXHR, textStatus, errorThrown) {
    caller.fetchError(this, errorThrown)
  }

  async get(id, typeId) {
    if (Array.isArray(id))
      return await this.gets(id, typeId)
    
    if (typeof typeId === 'undefined' || typeId === 'adresse') {
      let queryResult = this.createQueryResult()
      let data = await new AdgangsAdresseGetter().get(id)
      let result = queryResult.addResult(this.source, "adresse", data.properties.vejstykke.navn + " " + data.properties.husnr + ", " + data.properties.postnummer.nr + " " + data.properties.postnummer.navn, null, data.geometry, data)
      result.id = id
      result.isComplete = true
      result.data.type = 'adgangsadresse'
      return result
    } else if (typeId === 'enhedsadresse') {
      let queryResult = this.createQueryResult()
      let data = await new EnhedsAdresseGetter().get(id)
      let result = queryResult.addResult(this.source, "enhedsadresse", data.properties.adressebetegnelse, null, data.geometry, data)
      result.id = id
      result.isComplete = true
      result.data.type = 'enhedsadresse'
      return result
    } else {
      return null
    }
  }

  async getEnhedsAdresserForAdresse(adresseId) {
    let data = await new EnhedsAdresseGetter().getEnhedsAdresserForAdresse(adresseId)
    return data
  }
  
  async gets(ids, typeId) {
    let results = []
    if (typeof typeId === 'undefined' || typeId === 'adresse') {
      let queryResult = this.createQueryResult()
      let featureCollection = await new AdgangsAdresseGetter().gets(ids)
      for (let data of featureCollection.features) {
        let result = queryResult.addResult(this.source, "adresse", data.properties.vejstykke.navn + " " + data.properties.husnr + ", " + data.properties.postnummer.nr + " " + data.properties.postnummer.navn, null, data.geometry, data)
        result.id = data.properties.id
        result.isComplete = true
        result.data.type = 'adgangsadresse'
        results.push(result)
      }
    } else if (typeId === 'enhedsadresse') {
      let queryResult = this.createQueryResult()
      let featureCollection = await new EnhedsAdresseGetter().gets(ids)
      for (let data of featureCollection.features) {
        let result = queryResult.addResult(this.source, "enhedsadresse", data.properties.adressebetegnelse, null, data.geometry, data)
        result.id = data.properties.id
        result.isComplete = true
        result.data.type = 'enhedsadresse'
        results.push(result)
      }
    }
    return results
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      let data = await new AdgangsAdresseGetter().get(result.data.id)
      result.typeId = 'adresse'
      result.data.type = 'adgangsadresse'
      result.data = data
      result.geometry = data.geometry
      result.isComplete = true
      return result
    }
  }

  async onSelect(result) {
    //if (typeof result.newquery !== 'undefined') {
    if (result.isNewQuery()) {
      if (result.data && result.data.type) 
        if (result.data.type === 'vej') {
          let getOptions = {
            postnr: result.data.postnummer,
            navn: result.data.vejnavn
          }
          if (this.kommunekode != null) 
            getOptions.kommunekode = this.kommunekode
          
          let vejStykker = await new VejStykkeGetter().get(getOptions)
          let vejStykke = vejStykker[0]
          result.data = vejStykke
          result.data.type = 'vej'
          result.geometry = vejStykke.geometry
          result.data.vejkode = vejStykke.properties.kode
          this._onSelectCallback(result)
        }else if (result.data.type === 'vejnavn') {
          let getOptions = {
            navn: result.data.vejnavn
          }
          if (this.kommunekode != null) 
            getOptions.kommunekode = this.kommunekode
          
          let vejStykker = await new VejStykkeGetter().get(getOptions)
          let vejStykke = vejStykker[0]
          result.data = vejStykke
          result.data.type = 'vej'
          result.geometry = vejStykke.geometry
          result.data.vejkode = vejStykke.properties.kode
          this._onSelectCallback(result)
        }
      
    } else {
      this._onSelectCallback(result)
    }
  }

  getDetailHandlersForResult(result) {
    const detailHandlerDefs = []
    if (this.allowDetails) {
      let dagiDetailsHandler = (result) => {
        return new Promise(
          (resolve) => {
            let atSitePhrase = getString("at_site")
            const items = []

            if (this.showDawaLinks) 
              items.push({
                type: 'link',
                label: "Adressen",
                icon: icons.searchers.dawa.result,
                fixedIcons: [icons.jsonfile, icons.details.link],
                link: result.data.properties.href,
                linkTitle: 'Adressen ' + atSitePhrase + ' dawa.dk (json)'
              })

            const beliggenhed = this.getBeliggenhed(result.data)
            let bebyggelse
            let item
            let setGeometries = []
            if (beliggenhed.bebyggelse.length > 0) {
              bebyggelse = beliggenhed.bebyggelse[0]
              item = {
                type: "area",
                label: "Beliggenhed",
                icon: icons.searchers.dawa.result,
                value: bebyggelse.navn + " (" + bebyggelse.type + ")",
                hoverable: true,
                result: {
                  title: bebyggelse.navn,
                  href: bebyggelse.href
                }
              }
              setGeometries.push(this.setGeometry(item, bebyggelse.href))
              items.push(item)
              for (let i = 1; i < beliggenhed.bebyggelse.length; i++) {
                bebyggelse = beliggenhed.bebyggelse[i]
                item = {
                  type: "area",
                  label: " ",
                  value: bebyggelse.navn + " (" + bebyggelse.type + ")",
                  hoverable: true,
                  result: {
                    title: bebyggelse.navn,
                    href: bebyggelse.href
                  }
                }
                setGeometries.push(this.setGeometry(item, bebyggelse.href))
                items.push(item)
              }
            }

            item = {
              type: "area",
              label: "Sogn",
              icon: icons.searchers.dawa.result,
              value: result.data.properties.sogn.navn,
              hoverable: true,
              result: {
                title: result.data.properties.sogn.navn,
                href: result.data.properties.sogn.href
              }
            }
            setGeometries.push(this.setGeometry(item, result.data.properties.sogn.href))
            items.push(item)

            if (this.showDawaLinks) 
              items.push({
                type: 'link',
                label: " ",
                fixedIcons: [icons.jsonfile, icons.details.link],
                link: result.data.properties.sogn.href,
                linkTitle: 'Sognet ' + atSitePhrase + ' dawa.dk (json)'
              })

            item = {
              type: "area",
              label: "Ejerlav",
              icon: icons.info,
              value: result.data.properties.jordstykke.ejerlav.navn,
            }
            items.push(item)

            items.push({
              type: "area",
              label: "Zone",
              icon: icons.info,
              value: result.data.properties.zone,
            })

            item = {
              type: "area",
              icon: icons.searchers.dawa.road,
              label: "Vej",
              value: "(" + result.data.properties.vejstykke.kode + ") " + result.data.properties.vejstykke.navn,
              hoverable: true,
              result: {
                title: result.data.properties.kommune.kode + "/" + result.data.properties.vejstykke.kode
              }
            }
            setGeometries.push(this.setGeometry(item, result.data.properties.vejstykke.href))
            items.push(item)

            item = {
              type: "area",
              label: " ",
              value: "Vejpunkt",
              hoverable: true,
              result: {
                title: "Vejpunkt for " + result.title,
                geometry: {
                  type: "Point",
                  coordinates: result.data.properties.vejpunkt.koordinater
                }
              }
            }
            items.push(item)
            
            if (this.showDawaLinks) 
              items.push({
                type: 'link',
                label: " ",
                fixedIcons: [icons.jsonfile, icons.details.link],
                link: result.data.properties.vejstykke.href,
                linkTitle: 'Vejstykket ' + atSitePhrase + ' dawa.dk (json)'
              })
            
            if (this.showDawaLinks)
              items.push({
                type: 'link',
                label: "Øvrige",
                icon: icons.info,
                fixedIcons: [icons.jsonfile, icons.details.link],
                link: result.data.properties.jordstykke.href,
                linkTitle: 'Matrikelnr ' + atSitePhrase + ' dawa.dk (json)'
              })

            if (this.showDawaLinks)
              items.push({
                type: 'link',
                label: " ",
                fixedIcons: [icons.jsonfile, icons.details.link],
                link: result.data.properties.politikreds.href,
                linkTitle: 'Politikredsen ' + atSitePhrase + ' dawa.dk (json)'
              })

            if (this.showDawaLinks)
              items.push({
                type: 'link',
                label: " ",
                fixedIcons: [icons.jsonfile, icons.details.link],
                link: result.data.properties.retskreds.href,
                linkTitle: 'Retskredsen ' + atSitePhrase + ' dawa.dk (json)'
              })

            if (this.showDawaLinks)
              items.push({
                type: 'link',
                label: " ",
                fixedIcons: [icons.jsonfile, icons.details.link],
                link: result.data.properties.opstillingskreds.href,
                linkTitle: 'Opstillingskredsen ' + atSitePhrase + ' dawa.dk (json)'
              })

            Promise.all(setGeometries).then(
              () => {
                resolve(items)
              },
              () => {
                resolve(items)
              }
            )
          })
      }
      
      let detailHandlerDef = new DetailsHandlerDef(
        { "buttonText": "Supplerende DAWA info for " + result.title,
          "buttonImage": icons.folderIcon,
          "handler": dagiDetailsHandler}
      )
      
      detailHandlerDefs.push(detailHandlerDef)
    }

    return detailHandlerDefs
  }

  async setGeometry(item, href) {
    let data
    try{
      data = await this.fetch(href, {data: {format: "geojson", srid: "25832"}})
    }catch(e) {
      return
    }
    item.result.geometry = data.geometry
  }

  getBeliggenhed(hit) {
    const beliggenhed = {title: "Beliggenhed", "bebyggelse": [], "value": ""}
    let ranks = {
      "storby": 1,
      "by": 2,
      "bydel": 3,
      "spredtBebyggelse": 4,
      "sommerhusområde": 5,
      "sommerhusområdedel": 6,
      "industriområde": 7,
      "kolonihave": 8
    }
    let bebyggelser = hit.properties.bebyggelser
    bebyggelser.sort(function(a, b) {
      return ranks[a.type] - ranks[b.type]
    })
    beliggenhed.value = hit.properties.region.navn
    for (let i=0; i<bebyggelser.lenght; i++) {
      let thisBebyggelse = bebyggelser[i]
      beliggenhed.bebyggelse.push(thisBebyggelse)
      beliggenhed.value = beliggenhed.value + "->" + thisBebyggelse.navn
      if (i === bebyggelser.length - 1) 
        beliggenhed.title = thisBebyggelse.type
      
    }
    return beliggenhed
  }

}
