/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef"
import icons from "../../resources/icons"
/**
 * Viser link til datacvr.virk.dk for cvr.produktionsenhed resultater
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.CVR_enhedSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.RegnskaberForCvrNummerHandler
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * cvr_enhedSearcher.addDetailHandlerDef(new Septima.Search.RegnskaberForCvrNummerHandler({more: false}))
 *
 * @example <caption>ES6:</caption>
 * import CvrLinkHandler from './searchers/detailhandlers/CvrLinkHandler'
 * dawaSearcher.addDetailHandlerDef(new RegnskaberForCvrNummerHandler({more: false}))
 * @api
 **/
export default class RegnskaberForCvrNummerHandler extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {boolean} [options.more=true]
   **/
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "Regnskaber",
      buttonImage: icons.searchers.cvr,
      more: true
    }
    super(Object.assign(defaultOptions, options))

    this.handlerFunction = this.myHandler
    this.isApplicableFunction = (result) => {
      return (result.source === "cvr" && result.typeId === "produktionsenhed")
    }
  }

  async handler(result) {
    const url = `http://distribution.virk.dk/offentliggoerelser/_search?q=cvrNummer:${result.data.virksomhed_cvrnr || result.data.fields.virksomhed_cvrnr}&size=100`
    const regnskaber = (await (await fetch(url)).json()).hits.hits

    function hit2Items(h) {

      const doc = h._source.dokumenter.filter(d => d.dokumentMimeType === 'application/pdf' || d.dokumentMimeType === 'application/tiff')

      let r = {}
      if (doc.length > 0) {
        let link = doc[0].dokumentUrl
        let linktitle = `${h._source.regnskab.regnskabsperiode.startDato} - ${h._source.regnskab.regnskabsperiode.slutDato}`
        r = {
          type: 'link',
          link: link,
          linkTitle: linktitle
        }

      } else {

        let label = ''
        let value = `${h._source.regnskab.regnskabsperiode.startDato} - ${h._source.regnskab.regnskabsperiode.slutDato} (dokument ikke tilgængeligt)`
        r = {
          type: 'labelvalue',
          label: label,
          value: value
        }
      }
      return r

    }

    regnskaber.sort((a, b) => new Date(b._source.regnskab.regnskabsperiode.startDato) - new Date(a._source.regnskab.regnskabsperiode.startDato))
    const rows = regnskaber.map(hit2Items)
    return rows
  }
}
