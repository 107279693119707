/**
 * @module
 */

import {fetch2} from "../../utils"
import {getWKTParser} from "../../util/getWKTParser"
import OffentligeLinksProvider from "./OffentligeLinksProvider"
/**
 * Viser link til relevante offentlige hjemmesider for enten en adresse eller an matrikel
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.DawaSearcher
 * _options:
 *   kommunekode: '101'
 *   allowDetails: true
 * detailhandlers:
 *   - _type: Septima.Search.OffentligeLinksForAdresseMatrikel
 *     _options:
 *        links: ["jfa","bbrmeddelelse","ejendomsinfo.dk","boligejer.dk","vurdering","bbkort","ois"]
  * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * dawaSearcher.addDetailHandlerDef(new Septima.Search.OffentligeLinksForAdresseMatrikel({more: true}))
 * 
 * @example <caption>ES6:</caption>
 * import OffentligeLinksForAdresseMatrikel from './searchers/detailhandlers/OffentligeLinksForAdresseMatrikel'
 * dawaSearcher.addDetailHandlerDef(new OffentligeLinksForAdresseMatrikel({more: false}))
 * @api
 **/
export default class OffentligeLinksForAdresseMatrikel extends OffentligeLinksProvider {
/**
 * @param {Object} options
 * @param {Object} [options.links=["jfa","bbrmeddelelse","ejendomsinfo.dk","boligejer.dk","vurdering","bbkort","ois"]] Hvilke links skal vises.
 * Muligheder: "jfa","bbrmeddelelse","ejendomsinfo.dk","boligejer.dk","vurdering","bbkort","ois", "geokoder", "retbbr", "skraafoto", "skraafoto_js"
**/ 


  constructor(options= {}) {
    super(options)
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler
  }

  isApplicable(result) {
    return ((result.source === "Dawa" && result.typeId === "adresse") || (result.source === "Kortforsyningen" && result.typeId === "matrikelnumre"))
  }

  async myHandler(result) {
    let komnr
    let esrnr
    let pointGeom
    let elavskode
    let matrnr

    if (result.source === "Dawa" && result.typeId === "adresse") {
      komnr = result.data.properties.kommune.kode
      esrnr = result.data.properties.jordstykke.esrejendomsnr
      pointGeom = result.geometry
      elavskode = result.data.properties.ejerlav.kode
      matrnr = result.data.properties.jordstykke.matrikelnr
    }else {
      elavskode = result.data.elavskode
      matrnr = result.data.matrnr
      let res = await fetch2(`https://dawa.aws.dk/jordstykker/${elavskode}/${matrnr}`)
      komnr = res.kommune.kode
      esrnr = res.esrejendomsnr

      let wktParser = getWKTParser()
      pointGeom = wktParser.getInteriorPoint(result.geometry)
      
    }

    let items = this.createLinks(result, komnr, esrnr, pointGeom, elavskode, matrnr)
    this.getLogger().debug(items)
    return items
  }

}
