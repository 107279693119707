/**
 * @module
 */

import pino from 'pino'

import QueryBuilder from './QueryBuilder'
import DataFetcher from './DataFetcher'
import QueryResult from './QueryResult'

/**
 * F
 * @api
 */
export default class Controller {
  /**
   * @param {module:js/searchers/Searcher[]} [searchers] Array of searchers
   * @param {Object} [options]
   * @param {string} [options.onError]
   * @param {string} [options.blankBehavior]
   * @param {Object} [options.logger=pino({ level: 'error' })]
   * @param {String} [options.logLevel]
   */
  constructor(searcherRegs, options) {
    let searcherArgs = null
    let optionsArgs = null
    this.logLevel = 'error'
    this._searchers = []
    
    if (Array.isArray(searcherRegs)) {
      searcherArgs = searcherRegs
      optionsArgs = options
    } else {
      optionsArgs = searcherRegs
    }
    if (optionsArgs.logLevel)
      this.logLevel = optionsArgs.logLevel

    if (optionsArgs && optionsArgs.logger)
      this.setLogger (optionsArgs.logger)
    else
      this.setLogger (pino({ level: this.logLevel }))

    //Vars

    this.activeDataFetcher = null
    this.onErrorHandlers = []
    this.onAddSearcherHandlers = []
    this.onSelectHandlers = []
    this.onInspectHandlers = []
    this.onPeakHandlers = []

    this.blankBehavior = "none" //none, search
    //Options
    if (optionsArgs !== null) {
      if (typeof optionsArgs.onError === "function")
        this.addErrorHandler(optionsArgs.onError)
      if (optionsArgs.blankBehavior)
        this.blankBehavior = optionsArgs.blankBehavior
    }

    this.idSerial = 0
    if (searcherArgs !== null) {
      this.allSearchersProcessed = Promise.resolve('success')
      for (let searcherArg of searcherArgs)
        this.addSearcher(searcherArg)
    }
    this.getLogger().debug('constructed')
  }
  
  setLogger(logger) {
    let logLevelVal = '' + Math.min(logger.levelVal, logger.levels.values[this.logLevel])
    let logLevel = logger.levels.labels[logLevelVal]
    this._logger = logger.child({module: 'Controller'})
    this._logger.level = logLevel
    //raise level if higher than injected or created
    //if (this._logger.levels.values[this.logLevel] < this._logger.levels.values[logger.level])
    //  this._logger.level = this.logLevel
    for (let searcher of this._searchers)
      searcher.setLogger(this.getLogger())
  }

  getLogger() {
    return this._logger
  }

  set searchers(searchers) {
    this._searchers = []
    this.allSearchersProcessed = Promise.resolve('success')
    for (let searcher of searchers)
      this.addSearcher(searcher)
  }
  
  addSearcher(searcherReg) {
    let searcher = searcherReg
    if (typeof searcherReg.searcher !== 'undefined')
      searcher = searcherReg.searcher

    searcher.id = this.idSerial++
    searcher.setLogger (this.getLogger())
    this._searchers.push(searcher)
    this.allSearchersProcessed = Promise.all([this.allSearchersProcessed, this.thisSearcherProcessed(searcher)])
  }

  addOnSelectHandler(callback) {
    this.onSelectHandlers.push(callback)
  }
  
  async onSelect(result) {
    if (typeof result !== 'undefined')
      if (result.isNewQuery()) {
        this.callSelectHandlers(result)
      } else {
        let completedResult = await result.complete()
        this.callSelectHandlers(completedResult)
      }
  }

  callSelectHandlers(result) {
    if (result.searcher)
      result.searcher._onSelect(result)
    for (let onSelectHandler of this.onSelectHandlers)
      onSelectHandler(result)
  }

  addErrorHandler(callback) {
    this.onErrorHandlers.push(callback)
  }

  async thisSearcherProcessed(searcher) {
    try{
      await searcher.ready()
      this.onAddSearcher()
    }catch(e) {
      this.removeSearcher(searcher)
    }
  }

  removeSearcher(searcher) {
    for (let i=0; i<this._searchers.length; i++) {
      let thisSearcher = this._searchers[i]
      if (thisSearcher.id === searcher.id) {
        this._searchers.splice(i, 1)
        break
      }
    }
  }

  addOnAddSearcherHandler(callback) {
    this.onAddSearcherHandlers.push(callback)
  }

  onAddSearcher() {
    for (let onAddSearcherHandler of this.onAddSearcherHandlers)
      onAddSearcherHandler()
  }

  fetchData(queryString, completeCallback, limit, timeout, source, type) {
    //search, none
    let queryBuilder = new QueryBuilder().queryString(queryString).limit(limit).type("list")
    if (source)
      queryBuilder.target({
        source,
        type: type ? type : null
      })

    const query = queryBuilder.build()
    if ((query.queryString.length > 0) || (query.queryString.length === 0 && this.blankBehavior === "search")) {
      return this.fetchDataFromQueryObject(query, completeCallback)
    } else {
      const queryResult = new QueryResult(null)
      if (completeCallback)
        completeCallback(queryResult.getAllResults(), true)
      return Promise.resolve(queryResult)
    }

  }

  fetchDataFromQueryObject(callQuery, completeCallback) {
    //Is there an active DataFetcher? then cancel it
    if (this.activeDataFetcher !== null) {
      this.activeDataFetcher.cancel()
      this.activeDataFetcher = null
    }
    //Create new DataFetcher
    this.activeDataFetcher = new DataFetcher(this, completeCallback)

    let fetchQueries = []
    //The two next statements are only used to decide which searchers to call and what limit should be used in the query.
    let searchersToFetchFrom = this.getTargetedSearchersFromQuery(callQuery)
    let typeCount = callQuery.hasTarget ? 1 : this.getSearchTypeCount(searchersToFetchFrom)

    if (searchersToFetchFrom.length === 0) {
      const queryResult = new QueryResult(null)
      if (completeCallback)
        completeCallback(queryResult.getAllResults(), true)
      return Promise.resolve(queryResult)
      //return Promise.resolve([])
    } else if (typeCount === 0 || typeCount === 1) {
      let fetchQueryBuilder = new QueryBuilder(callQuery)
      fetchQueryBuilder.type("list").limit(100)
      let fetchQuery = fetchQueryBuilder.build()
      fetchQuery.searcher = searchersToFetchFrom[0]
      fetchQueries.push(fetchQuery)
    } else {
      let fairShare = Math.ceil(callQuery.limit / typeCount)
      for (let searcher of searchersToFetchFrom) {
        let fetchQueryBuilder = new QueryBuilder(callQuery)
        let minimumShowCount = searcher.minimumShowCount
        if (minimumShowCount === 0) {
          if (callQuery.isBlank)
            fetchQueryBuilder.type("collapse")
          else
            fetchQueryBuilder.type("no-cut").limit(fairShare)
        }else {
          let limit
          if (callQuery.isBlank && !searcher.showMinimumOnBlank)
            limit = fairShare
          else
            limit = Math.max(fairShare, minimumShowCount)
          if (limit < 3)
            limit = 0
          fetchQueryBuilder.type("cut").limit(limit)
        }

        let fetchQuery = fetchQueryBuilder.build()
        fetchQuery.searcher = searcher
        fetchQueries.push(fetchQuery)
      }
    }
    return this.activeDataFetcher.fetch(fetchQueries)
  }

  getTargetedSearchersFromQuery(query) {
    let targetedSearchers = []
    if (query.hasTarget) {
      for (let thisSearcher of this._searchers) 
        if (thisSearcher.hasTarget(query.target))
          if (query.isBlank && thisSearcher.blankBehavior === 'none')
            return []
          else
            targetedSearchers.push(thisSearcher)
      return targetedSearchers
    } else {
      //Only return searchers which are searchable
      for (let thisSearcher of this._searchers)
        if (thisSearcher.isSearchable())
          targetedSearchers.push(thisSearcher)
      return targetedSearchers
    }
  }

  getSearcherBySourceType(source, type) {
    for (let thisSearcher of this._searchers)
      if (thisSearcher.hasSource(source) && thisSearcher.hasType(type))
        return thisSearcher
    return null
  }

  sourceTypeIsKnown(source, type) {
    for (let thisSearcher of this._searchers)
      if (thisSearcher.hasSource(source) && thisSearcher.hasType(type))
        return true
    return false
  }

  getSearchTypeCount(searchers) {
    let count = 0
    for (let thisSearcher of searchers)
      for (let thisSource of thisSearcher.getSources())
        for (let type of thisSource.types)
          if (typeof type.queryBehaviour === 'undefined' || type.queryBehaviour === 'search')
            count++
    return count
  }
  onError(searcher, errorThrown) {
    for (let onErrorHandler of this.onErrorHandlers)
      onErrorHandler(searcher, errorThrown)
  }

  async get(source, type, id) {
    await this.allSearchersProcessed
    let searcher = this.getSearcherBySourceType(source, type)
    if (searcher === null) {
      throw (new Error("Controller.get: could not find searcher"))
    } else {
      await searcher.ready()
      let result = await searcher.get(id, type, source)
      if (typeof result === 'undefined' || result === null)
        throw (new Error("Controller.get: could not find object"))

      let completeResult = await result.searcher.completeResult(result)
      if (completeResult === null)
        throw (new Error("Controller.get: completeResult === null"))

      return completeResult
    }
  }

  cancelFetches() {
    //Is there an active DataFetcher? then cancel it
    if (this.activeDataFetcher !== null) {
      this.activeDataFetcher.cancel()
      this.activeDataFetcher = null
    }
  }

  async getSources() {
    await this.allSearchersProcessed
    let sources = []

    //Internal helper function
    let getSource = (sourceName) => {
      for (let i = 0; i < sources.length; i++)
        if (sources[i].source.toLowerCase() === sourceName.toLowerCase())
          return sources[i]
      //Else create the source
      let source = {source: sourceName, types: []}
      sources.push(source)
      return source
    }

    for (let thisSearcher of this._searchers) {
      let thisSearcherSources = thisSearcher.getSources()
      //[{source: "sss", types: [resultType..]}]
      for (let thisSearcherSource of thisSearcherSources) {
        let source = getSource(thisSearcherSource.source)
        source.types = source.types.concat(thisSearcherSource.types)
      }
    }
    return (sources)
  }

  getSearchers() {
    return this._searchers
  }

  getSearcherFromId(id) {
    for (let i = 0; i < this._searchers.length; i++)
      if (this._searchers[i].getId() == id)
        return this._searchers[i]


    return null
  }

  fetchError(dataFetcher, searcher, errorThrown) {
    if (dataFetcher === this.activeDataFetcher)
      this.onError(searcher, errorThrown)

  }
}






