/**
 * @module
 */
import Searcher from './Searcher'
import Icons from '../resources/icons'
import ResultType from "../ResultType"
import {register} from 'ol/proj/proj4.js'
import proj4 from 'proj4'
import Projection from 'ol/proj/Projection.js'
import { addProjection } from 'ol/proj.js'
import { WFS, WKT } from 'ol/format'
import GML3 from 'ol/format/GML3'
import GeoJSON from 'ol/format/GeoJSON'
import { intersects as intersectsFilter, equalTo as equalToFilter, and as andFilter } from 'ol/format/filter'

/**
 *
 * Searches gst geoservice (http://www.kortforsyningen.dk/dokumentation/geonoeglergeosearch)
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration: <a href='../examples/builder/'>Example</a></caption>
 _type: Septima.Search.GeoSearch
 _options:
 targets:
 - matrikelnumre
 - sogne
 authParamsKortforsyningen:
 token: xxxx
 authParamsDatafordeleren:
 username: xxxx
 password: xxxx
 kommunekode: '101 157'
 * @example <caption> JS options: <a href='../examples/simple.twomunicipalities/'>Example</a></caption>
 geoSearchOptions = {
    "targets": ['matrikelnumre', 'sogne'],
    "authParamsKortforsyningen": {
      "token": 'xxxxx'
    },
    "authParamsDatafordeleren": {
      "username": 'xxxxx',
      "password": 'xxxx'
    },
    kommunekode: '157 101'
  };
 * @example <caption>js client:</caption>
 * <!-- Include septimaSearch -->
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.GeoSearch(geoSearchOptions))
 *
 * @example <caption>ES6:</caption>
 * import GeoSearch from './searchers/GeoSearch'
 * controller.addSearcher(new GeoSearch(geoSearchOptions))
 * @api
 */
export default class GeoSearch extends Searcher {
  /**
   * @param {Object} options GeoSearch expects these properties:
   * @param {String} [options.kommunekode=*] "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @param options.targets {string[]} List of targets to search. The full list is ['kommuner', 'matrikelnumre', 'opstillingskredse', 'politikredse', 'postdistrikter', 'regioner', 'retskredse']
   * @param options.authParamsKortforsyningen {Object} Either {token: 'ttttt'}, where t is a ticket issued by kortforsyningen or {login: 'llll', password: 'pppp'}
   * @param options.authParamsDatafordeleren {Object} Tjenestebruger til Datafordeleren {username: 'uuuu', password: 'pppp'}
   */
  constructor(options) {

    super(Object.assign({
      usesGeoFunctions: true,
      iconURI:Icons.result.defaultIcon
    },
    options))

    this.serviceUrl =  'https://kortforsyningen.kms.dk'

    // https://services.datafordeler.dk/DAGIM/DAGI_10MULTIGEOM_GMLSFP/1.0.0/WFS?service=WFS&version=2.0.0&request=GetCapabilities&username=GVMHDDIEUG&password=dCu!FNMpnbf7DGm
    // https://services.datafordeler.dk/MATRIKEL/MatrikelGaeldendeOgForeloebigWFS/1.0.0/WFS?service=WFS&version=2.0.0&request=GetCapabilities&username=GVMHDDIEUG&password=dCu!FNMpnbf7DGm
    this.geoTypes = [
      {resource: 'matrikelnumre', returnType: "matrikelnummer", singular: 'Matrikelnummer', plural: 'Matrikelnumre', wfstypename: 'Jordstykke_Gaeldende'},
      {resource: 'kommuner', returnType: "kommune", singular: 'Kommune', plural: 'Kommuner', wfstypename: 'Kommuneinddeling'},
      {resource: 'opstillingskredse', returnType: "opstillingskreds", singular: 'Opstillingskreds', plural: 'Opstillingskredse', wfstypename: 'Opstillingskreds'},
      {resource: 'politikredse', returnType: "politikreds", singular: 'Politikreds', plural: 'Politikredse', wfstypename: 'Politikreds'},
      {resource: 'postdistrikter', returnType: "postdistrikt", singular: 'Postdistrikt', plural: 'Postdistrikter', wfstypename: 'Postnummerinddeling'},
      {resource: 'regioner', returnType: "region", singular: 'Region', plural: 'Regioner', wfstypename: 'Regionsinddeling'},
      {resource: 'retskredse', returnType: "retskreds", singular: 'Retskreds', plural: 'Retskredse', wfstypename: 'Retskreds'},
      {resource: 'sogne', returnType: "sogn", singular: 'Sogn', plural: 'Sogne', wfstypename: 'Sogneinddeling'}
    ]

    // Comments
    // - "returnType" is the type, that GeoSearch returns even though the service is called with "target". The value of "returnType" is differnt from "target/resource"
    // - "resource", "typeId" and "target" are all the same

    if (!options.source)
      options.source = "Kortforsyningen"

    this.source = options.source

    this.area = null
    if (options.kommunekode && options.kommunekode !== "*") {
      options.kommunekode += ''
      let municipalities = options.kommunekode.split(' ')
      for (let i = 0; i < municipalities.length; i++)
        municipalities[i] = "muncode0" + municipalities[i]

      this.area = municipalities.join()
    } else if (options.area) {
      this.area = options.area
    }
    this.authParamsKortforsyningen = options.authParamsKortforsyningen || options.authParams || {
      token: '22e4c7f57b6172b780abf7a447ebe7bd'
    }
    this.authParamsDatafordeler = options.authParamsDatafordeler || {
      username: 'GVMHDDIEUG',
      password: 'dCu!FNMpnbf7DGm'
    }

    this.myTypes = []
    if (!options.targets)
      options.targets = []

    const resources = []
    for (let type of this.geoTypes) {
      let targetFound = false
      for (let target of options.targets)
        if (type.resource === target || target === '*') {
          targetFound = true
          this.myTypes.push(type)
          resources.push(type.resource)
          let resultType = new ResultType({
            id: type.resource,
            singular: type.singular,
            plural: type.plural
          })
          this.registerType(this.source, resultType)
        }

      if (!targetFound) {
        let resultType = new ResultType({
          id: type.resource,
          singular: type.singular,
          plural: type.plural,
          queryBehaviour: "none"
        })
        this.registerType(this.source, resultType)
      }
    }
    this.resourcesParam = resources.join(',')

    this.iconURI = Icons.searchers.geoSearch.result

    this.keyvalues = {}

    const projection = new Projection({
      code: 'EPSG:25832',
      extent: [120000, 5661139.2, 958860.8, 6500000],
      units: 'm'
    })
    addProjection(projection)
    proj4.defs('EPSG:25832','+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs')
    proj4.defs('urn:ogc:def:crs:EPSG::25832', proj4.defs('EPSG:25832'))
    register(proj4)

  }

  async fetchData(query, caller) {
    switch (query.type) {
      case 'collapse': {
        caller.fetchSuccess(this.createQueryResultFromResources())
        break
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        let callParams = this.createCallParams(query)
        if (query.isBlank)
          callParams.search = "a b c d e f g h i j k l m n o p q r s t u v x y z æ ø å 0 1 2 3 4 5 6 7 8 9"
        try {
          let data = await this.callGeoSearch(callParams)
          data.query = query
          caller.fetchSuccess(this.parseResult(data.data, query))
        } catch (error) {
          caller.fetchError(this, error)
        }
      }
    }
  }

  createQueryResultFromResources() {
    const queryResult = this.createQueryResult()
    for (let type of this.myTypes)
      queryResult.addNewQuery(this.source, type.resource, type.plural, null, "", null, null)
    return queryResult
  }

  createCallParams(query) {
    const params = {}
    const type = this.getTypeFromQuery(query)
    if (type === null) {
      params.limit = (query.limit + 2) * this.myTypes.length
      if (params.limit > 100)
        params.limit = 100

      params.resources = this.resourcesParam
    } else {
      params.limit = query.limit +1
      params.resources = type.resource
    }
    if (this.area)
      params.area = this.area

    params.search = query.queryString
    return params
  }

  async callGeoSearch(params) {
    return await this.fetch(this.serviceUrl + '/Geosearch?service=GEO', {data: Object.assign(params, this.authParamsKortforsyningen)}, this._logger)
  }

  getTypeFromQuery(query) {
    if (query.hasTarget && this.hasType(query.target.type))
      for (let type of this.myTypes)
        if (query.target.type.toLowerCase() === type.resource.toLowerCase())
          return type
    return null
  }

  getTypeFromReturnType(returnType) {
    for (let type of this.geoTypes)
      if (returnType === type.returnType)
        return type
    return null
  }

  getTypeFromTypeId(typeId) {
    for (let type of this.geoTypes)
      if (typeId === type.resource)
        return type
    return null
  }

  parseResult(data, query) {
    const queryResult = this.createQueryResult()
    const resultTypes = []
    if (data !== null) {
      this.fixHits(data)
      let nextTypeIndex = 0
      while (nextTypeIndex > -1 && nextTypeIndex < data.length) {
        resultTypes.push(data[nextTypeIndex].type)
        nextTypeIndex = this.procesTypeFromIndex(data, nextTypeIndex, query, queryResult)
      }
    }
    if (!query.hasTarget && !query.isBlank)
      for (let thisType of this.myTypes)
        if (thisType.plural.toLowerCase().indexOf(query.queryString.toLowerCase()) === 0) {
          let foundInResultTypes = false
          for (let thisResultType of resultTypes)
            if ( thisType.returnType === thisResultType)
              foundInResultTypes = true
          if (!foundInResultTypes)
            queryResult.addNewQuery(this.source, thisType.resource, thisType.plural, null, "", null, null)
        }
    return queryResult
  }

  fixHits(hits) {
    for (let hit of hits)
      switch (hit.type) {
        case "matrikelnummer":
          hit.presentationString = hit.matrnr + ", " + hit.elavsnavn
          break
        case "opstillingskreds":
          hit.descriptionString = hit.storkredsNavn + "s storkreds"
          break
        case "postdistrikt":
          hit.presentationString = hit.id + " " + hit.name
          break
      }
  }

  procesTypeFromIndex(data, fromIndex, query, queryResult) {

    if (fromIndex < 0 || fromIndex >= data.length)
      return -1


    let index = fromIndex
    const returnType = data[index].type
    const typeHits = []

    while (index < data.length && data[index].type === returnType) {
      typeHits.push(data[index])
      index++
    }

    const type = this.getTypeFromReturnType(returnType)
    const count = typeHits.length
    let hitsShown = (count === 1) ? 1 : (query.type === 'no-cut' && count > query.limit) ? 0 : Math.min(count, query.limit)

    for (let thisHit of typeHits.slice(0, hitsShown))
      this.addResultFromHit(queryResult, thisHit, type.resource)
    if ( count > hitsShown && ["no-cut", "cut"].indexOf(query.type) !== -1 )
      queryResult.addNewQuery(this.source, type.resource, type.plural, null, query.queryString, null, null)

    return index
  }

  addResultFromHit(queryResult, thisHit, typeId) {
    let resultGeometry
    if (thisHit.hasOwnProperty("geometryWkt_detail"))
      resultGeometry = this.translateWktToGeoJsonObject(thisHit.geometryWkt_detail)
    else
      resultGeometry = this.translateWktToGeoJsonObject(thisHit.geometryWkt)

    let result = queryResult.addResult(this.source, typeId, thisHit.presentationString, thisHit.descriptionString ? thisHit.descriptionString : null, resultGeometry, thisHit)

    switch (thisHit.type) {
      case "matrikelnummer":
        result.isComplete = false
        result.id = thisHit.elavskode + '-' + thisHit.matrnr
        break
      case "kommune":
        result.id = thisHit.id
        break
      case "opstillingskreds":
        result.id = thisHit.id
        break
      case "politikreds":
        result.id = thisHit.myndighedsKode
        break
      case "postdistrikt":
        result.id = thisHit.id
        break
      case "retskreds":
        result.id = thisHit.myndighedsKode
        break
      case "sogn":
        result.id = thisHit.id
        break
    }
  }

  async geoKeys(method, params) {
    let data = await this.fetch(this.serviceUrl + '?servicename=RestGeokeys_v2&method=' + method + '&f=json&geometry=true', {data: Object.assign({}, params, this.authParamsKortforsyningen)})
    if (data.features && data.features.length > 0) {
      let properties = data.features[0].properties
      let geometry = data.features[0].geometry
      let wkt = this.translateGeoJsonObjectToWkt(geometry)
      let hit
      switch (method) {
        case "matrikelnr": {
          let ejd = await this.geoKeys("esrejendom", {ejkode: properties.ejerlav_kode, matnr: properties.matnr})
          hit = {
            "type": "matrikelnummer",
            "elavsnavn": properties.ejerlav_navn,
            "elavskode": properties.ejerlav_kode,
            "matrnr": properties.matnr,
            "presentationString": properties.matnr + ', ' + properties.ejerlav_navn,
            "centroid_x": properties.centroid_x,
            "centroid_y": properties.centroid_y,
            "geometryWkt": wkt,
            "geometryWkt_detail": wkt,
            "esrejdnr": ejd.esrejdnr,
            "sfeejdnr": ejd.sfeejdnr
          }
          break
        }
        case "esrejendom": {
          let ejd = {
            "esrejdnr": properties.esrejdnr,
            "sfeejdnr": properties.sfeejendom_sfeejdnr
          }
          return ejd
        }
        case "kommune": {
          hit = {
            "type": "kommune",
            "id": properties.kode,
            "name": properties.navn,
            "presentationString": properties.navn + ' kommune (' + properties.kode + ')',
            "geometryWkt": wkt,
            "geometryWkt_detail": wkt
          }
          break
        }
        case "opstillingskreds": {
          hit = {
            "type": "opstillingskreds",
            "id": properties.opstillingskreds_nummer,
            "name": properties.navn,
            "presentationString": properties.navn + "kredsen",
            "geometryWkt": wkt,
            "geometryWkt_detail": wkt,
            "valgkredsNr": properties.valgkreds_nummer,
            "storkredsNr": "",
            "storkredsNavn": properties.storkreds,
            "landsdelsNr": "",
            "landsdelsNavn": properties.landsdel,
            "descriptionString": properties.storkreds + " storkreds"
          }
          break
        }
        case "politikreds": {
          hit = {
            "type": "politikreds",
            "id": properties.kode,
            "name": properties.navn,
            "presentationString": properties.navn + "kreds",
            "geometryWkt": wkt,
            "geometryWkt_detail": wkt,
            "myndighedsKode": properties.CPR_NR
          }
          break
        }
        case "postdistrikt": {
          hit = {
            "type": "postdistrikt",
            "id": properties.kode,
            "name": properties.navn,
            "presentationString": properties.kode + ' ' + properties.navn,
            "geometryWkt": wkt,
            "geometryWkt_detail": wkt
          }
          break
        }
        case "retskreds": {
          hit = {
            "type": "retskreds",
            "id": properties.retskreds_nummer,
            "name": properties.navn,
            "presentationString": properties.navn,
            "geometryWkt": wkt,
            "geometryWkt_detail": wkt,
            "myndighedsKode": properties.retskreds_kode
          }
          break
        }
        case "sogn": {
          hit = {
            "type": "sogn",
            "id": properties.kode,
            "name": properties.navn,
            "presentationString": properties.navn,
            "geometryWkt": wkt,
            "geometryWkt_detail": wkt
          }
          break
        }
      }
      return {hit, geometry}
    } else {
      throw new Error("No " + method + " with " + JSON.stringify(params))
    }
  }


  async queryById(id, typeId) {
    let filter
    if (typeId === 'matrikelnumre') {
      const idSplit = id.split('-')
      filter = andFilter(
        equalToFilter('ejerlavskode', idSplit[0]),
        equalToFilter('matrikelnummer', idSplit[1])
      )
    } else {
      const ids = {
        kommuner: 'kommunekode',
        regioner: 'regionskode',
        sogne: 'sognekode',
        opstillingskredse: 'opstillingskredsnummer',
        politikredse: 'myndighedskode',
        postdistrikter: 'postnummer',
        retskredse: 'myndighedskode'
      }
      filter = equalToFilter(ids[typeId], id)
    }
    const geojson = await this.queryWithFilter(filter, typeId)
    return geojson
  }

  async sq(query) {
    const queryResult = this.createQueryResult()
    let geometry
    if (query.wkt) {
      geometry = new WKT().readGeometry(query.wkt)
    } else if (query.geometry) {
      geometry = new GeoJSON().readGeometry(query.geometry)
    }
    if (geometry) {
      if (query.target) {
        // Query a specific type
        const geojson = await this.queryByGeometry(geometry, query.target.type)
        this.addGeoJSONToQueryResult(queryResult, query.target.type, geojson)
      } else {
        // Query all selected types
        const types = this.geoTypes.filter((t) => this.resourcesParam.indexOf(t.resource) !== -1)
        await Promise.all(types.map(async (type) => {
          const geojson = await this.queryByGeometry(geometry, type.resource)
          this.addGeoJSONToQueryResult(queryResult, type.resource, geojson)
        }))
      }
    }
    return queryResult
  }

  async queryByGeometry(geometry, typeId) {
    const filter = intersectsFilter('geometri', geometry)
    const geojson = await this.queryWithFilter(filter, typeId)
    return geojson
  }

  async queryWithFilter(filter, typeId) {
    let { wfstypename } = this.getTypeFromTypeId(typeId)
    const params = Object.keys(this.authParamsDatafordeler).map(key => key + '=' + this.authParamsDatafordeler[key]).join('&')

    if (typeId === 'matrikelnumre' && !this.keyvalues.ejerlav) {
      this.keyvalues.ejerlav = await this.fetch('https://common.cdn.septima.dk/latest/data/kodeliste_ejerlavsnavn.json')
    }

    const featureNS = (typeId === 'matrikelnumre' ? 'http://data.gov.dk/schemas/matrikel/1' : 'http://data.gov.dk/schemas/dagi/2/gml3sfp')
    const featurePrefix = (typeId === 'matrikelnumre' ? 'mat' : 'dagi')

    // let path = (typeId === 'matrikelnumre' ? 'MATRIKEL/Matrikel_HIST_GML3_1_0' : 'DAGIM/DAGI_10MULTIGEOM_GMLSFP')
    let path = 'DAGIM/DAGI_250MULTIGEOM_GMLSFP'
    if (typeId === 'matrikelnumre') {
      path = 'MATRIKEL/MatrikelGaeldendeOgForeloebigWFS'
    } else if (typeId === 'postdistrikter') {
      path = 'DAGIM/DAGI_10MULTIGEOM_GMLSFP'
    }

    const featureRequest = new WFS().writeGetFeature({
      srsName: 'EPSG:25832',
      featureNS,
      featurePrefix,
      featureTypes: [wfstypename],
      outputFormat: 'xml',
      filter
    })

    let body = new XMLSerializer().serializeToString(featureRequest)
    //body = body.replace(/Intersects/g, 'Touches')
    // const url = `https://dfd-qgis-wfs-proxy.septima.dk/${path}/1.0.0/Wfs?${params}&SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&NAMESPACES=xmlns(${featurePrefix},${featureNS})`
    const url = `https://services.datafordeler.dk/${path}/1.0.0/Wfs?${params}&SERVICE=WFS&REQUEST=GetFeature&VERSION=2.0.0&NAMESPACES=xmlns(${featurePrefix},${featureNS})`
    let xml = await this.fetch(url, {
      method: 'post',
      expects: 'xml',
      body: body
    })
    xml = xml.replace('http://www.opengis.net/gml/3.2', 'http://www.opengis.net/gml')
    xml = xml.replace('xmlns:wfs="http://www.opengis.net/wfs/2.0"', 'xmlns:wfs="http://www.opengis.net/gml"')
    xml = xml.split('wfs:member').join('wfs:featureMember')
    xml = xml.replace(/(\d),0.0/g, '$1') // Remove z-values from coordinates
    xml = xml.replace(/(\d),(\d)/g, '$1 $2') // Remove , from coordinates
    xml = xml.replace(/<gml:coordinates/g, '<gml:posList srsDimension="2"')
    xml = xml.replace(/<\/gml:coordinates/g, '</gml:posList')

    const format = new GML3()
    const features = format.readFeatures(xml)
    const text = new GeoJSON().writeFeatures(features)
    const geojson = JSON.parse(text)
    geojson.features.forEach(f => this.removeZ(f.geometry.coordinates))
    return geojson
  }

  addGeoJSONToQueryResult(queryResult, typeId, geojson) {
    geojson.features.forEach(feature => {
      const data = this.featureToData(typeId, feature)
      if (data) {
        var result = queryResult.addResult(this.source, typeId, data.presentationString, null, feature.geometry, data)
        result.id = data.id
        result.distance = 0
      }
    })
  }

  featureToData(typeId, feature) {
    const p = feature.properties
    const wkt = this.translateGeoJsonObjectToWkt(feature.geometry)
    if (typeId === 'matrikelnumre') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "matrikelnummer",
        "id": p.ejerlavskode + '-' + p.matrikelnummer,
        "elavsnavn": this.keyvalues.ejerlav[p.ejerlavskode],
        "elavskode": p.ejerlavskode-0,
        "matrnr": p.matrikelnummer,
        "presentationString": p.matrikelnummer + ', ' + this.keyvalues.ejerlav[p.ejerlavskode],
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt,
        // "esrejdnr": p.esr_Ejendomsnummer,  // ESR Ejendomsnummer not awailable from the service on Datafordeleren
        "sfeejdnr": p.samletFastEjendomLokalId-0
      }
    } else if (typeId === 'kommuner') {
      const name = p.navn + (p.kommunekode === '0101' ? 's kommune' : ' kommune')
      return {
        "lokalId": p['id.lokalId'],
        "type": "kommune",
        "id": p.kommunekode,
        "name": p.navn,
        "presentationString": name + ' (' + p.kommunekode + ')',
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt
      }
    } else if (typeId === 'regioner') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "region",
        "id": p.regionskode,
        "name": p.navn,
        "presentationString": p.navn + ' (' + p.regionskode + ')',
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt
      }
    } else if (typeId === 'opstillingskredse') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "opstillingskreds",
        "id": p.opstillingskredsnummer,
        "name": p.navn,
        "presentationString": p.navn + "kredsen",
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt,
        "valgkredsNr": p.valgkredsnummer,
        "storkredsNr": p.storkredsnummer,
        // "storkredsNavn": p.Storkredsnummer_tekst,
        // "landsdelsNr": p.Landsdelsnummer,
        // "landsdelsNavn": p.Landsdelsnummer_tekst,
        // "descriptionString": p.Storkredsnummer_tekst + " storkreds"
      }
    } else if (typeId === 'politikredse') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "politikreds",
        "id": p.myndighedskode,
        "name": p.navn,
        "presentationString": p.navn + "kreds",
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt,
        "myndighedsKode": p.myndighedskode
      }
    } else if (typeId === 'postdistrikter') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "postdistrikt",
        "id": p.postnummer,
        "name": p.navn,
        "presentationString": p.postnummer + ' ' + p.navn,
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt
      }
    } else if (typeId === 'retskredse') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "retskreds",
        "id": p.myndighedskode,
        "name": p.navn,
        "presentationString": p.navn,
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt,
        "myndighedsKode": p.myndighedskode
      }
    } else if (typeId === 'sogne') {
      return {
        "lokalId": p['id.lokalId'],
        "type": "sogn",
        "id": p.sognekode,
        "name": p.navn,
        "presentationString": p.navn,
        "geometryWkt": wkt,
        "geometryWkt_detail": wkt
      }
    }
  }

  async get(id, typeId) {
    const queryResult = this.createQueryResult()
    const geojson = await this.queryById(id, typeId)
    if (geojson.features.length > 0) {
      const data = this.featureToData(typeId, geojson.features[0])
      if (data) {
        var result = queryResult.addResult(this.source, typeId, data.presentationString, null, geojson.features[0].geometry, data)
        result.id = id
        result.distance = 0
        return result
      }
    }
    return
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      result.isComplete = true

      if (result.data.type === "matrikelnummer") {

        let trueGeometryPromise = (async() => {
          let idents = result.id.split('-')
          let {hit: mat, geometry} = await this.geoKeys("matrikelnr", {ejkode: idents[0], matnr: idents[1]})
          result.geometry = geometry
          result.data.esrejdnr = mat.esrejdnr
          result.data.sfeejdnr = mat.sfeejdnr
        })()

        await Promise.all([trueGeometryPromise])
        return result
      } else {
        return result
      }
    }
  }

  removeZ(coordinates) {
    if (coordinates.length > 0 && Array.isArray(coordinates[0])) {
      for (let i = 0; i < coordinates.length; i++) {
        this.removeZ(coordinates[i])
      }
    } else {
      coordinates.length = 2
    }
  }
}