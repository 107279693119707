/**
 * @module
 */

import icons from "../resources/icons"
import pino from "pino"

/**
 * Decorates a result
 * @api
 */
export default class DetailsHandlerDef {

  /**
   * @param {Object} options
   * @param {boolean} [options.more=true] Indicates whether the details provided should be considered an extension of the result rather than related information (Layouts may show "more" handlers at the top without specific heading)
   * api
   **/
  constructor(options= {}) {

    this.more = false
    this.buttonText = ""
    this.buttonImage = icons.infoGrey
    this.description = ""
    this.logLevel = 'error'
    this.id = "_" + Math.floor(Math.random() * 999999999)
    this.detailHandlerDefs = []
    this.handlerFunction = async()=>{
      return[]
    }
    this.isApplicableFunction = ()=>{
      return true
    }
      
    if (options.id)
      this.id = options.id
      
    if (options.more) 
      this.more = options.more
        
    if (options.buttonText) 
      this.buttonText = options.buttonText
        
    if (options.buttonImage) 
      this.buttonImage = options.buttonImage
        
    if (options.description) 
      this.description = options.description
        
    if (options.handler) 
      this.handlerFunction = options.handler
        
    if (options.isApplicable) 
      this.isApplicableFunction = options.isApplicable

    if (options.logLevel)
      this.logLevel = options.logLevel

    if (options.renderHints)
      this.renderHints = options.renderHints
    this.setLogger (options.logger || pino({ level: 'error' }))
  }

  set detailhandlers(dhArray) {
    for (let detailhandler of dhArray)
      this.addDetailHandlerDef(detailhandler)
  }

  /**
   *
   * @param {module:js/details/DetailsHandlerDef} detailHandlerDef
   * @param typeId
   */
  addDetailHandlerDef(detailHandlerDef) {
    this.detailHandlerDefs.push(detailHandlerDef)
  }
  
  setLogger(logger) {
    this._logger = logger.child({module: this.buttonText})
    //raise level if higher than injected or created
    if (this._logger.levels.values[this.logLevel] < this._logger.levels.values[logger.level])
      this._logger.level = this.logLevel
  }
  
  getLogger() {
    return this._logger
  }

  getbuttonText() {
    return this.buttonText
  }

  getbuttonImage() {
    return this.buttonImage
  }
  
  async handler(result, renderAsMore) {
    try {
      let detailItems = await this.loggingRelationsHandler(result, renderAsMore)
      if (this.detailHandlerDefs.length>0)
        for (let item of detailItems)
          if (item.type === "result")
            await this.decorateResultItem(item)
          else if (item.type === "list" && item.itemType === 'result' && item.items.length > 0)
            await this.decorateResultList(item)
      return detailItems
    } catch(e) {
      this.getLogger().error(this.getbuttonText() + " " + e)
      throw e
    }
  }
  
  async loggingRelationsHandler(result, renderAsMore) {
    let logger = this.getLogger()
    if (result.searcher) {
      logger = result.searcher.getLogger()
      logger = logger.child({module: this.buttonText})
      if (logger.levels.values[this.logLevel] < logger.levels.values[logger.level])
        logger.level = this.logLevel
    }

    if (this.isApplicable(result)) {
      return await this._handle(result, renderAsMore, logger)
    } else {
      //Try parents
      let relations = await result.getRelations()
      let parentResults = relations.parents
      for (let parentResult of parentResults)
        if (this.isApplicable(parentResult))
          return await this._handle(parentResult, renderAsMore, logger)
    }
    return []
  }

  /**
   * @async
   * @param {module:js/Result} result
   * @param {Boolean} renderAsMore
   * @returns {module:js/DetailItem[]}
   * @throws {Error}
   * @api
   */
  async _handle(result, renderAsMore, logger) {
    //logger.trace({function:'_handle', result})
    //logger.debug({function:'_handle', result: result.summary()})
    let handlerResult
    if (typeof renderAsMore !== 'undefined' && renderAsMore !== this.more) {
      this.more = !this.more
      handlerResult = await this.handlerFunction(result, logger)
      this.more = !this.more
    }else {
      handlerResult = await this.handlerFunction(result, logger)
    }
    //logger.trace({function:'_handle', handlerResult})
    //logger.debug({function:'_handle', itemCount: handlerResult.length})
    return handlerResult
  }


  async decorateResultItem(resultItem) {
    for (let detailHandlerDef of this.detailHandlerDefs)
      if (detailHandlerDef.isApplicable(resultItem.result)) {
        let detailItems = await detailHandlerDef.handler(resultItem.result, true)
        let relevantDetailItems = detailItems.filter(item => (item.type !== "labelvaluelist" && item.type !== "resultlist" && item.type !== "list" && item.type !== "section_start" && item.type !== "section_end"))
        let infoItemsHeaders = this.extractItemHeaders(relevantDetailItems)
        resultItem.infoItemsHeaders = infoItemsHeaders
        resultItem.infoItems = relevantDetailItems
        break
      }
  }

  async decorateResultList(resultList) {
    let firstResultItem = resultList.items[0]
    for (let detailHandlerDef of this.detailHandlerDefs)
      if (detailHandlerDef.isApplicable(firstResultItem.result)) {
        let firstDetailItemsPromise = detailHandlerDef.handler(firstResultItem.result, true)
        let otherDetailItemsPromises = []
        let todoArray = []
        for (let j = 1; j < resultList.items.length; j++) {
          let resultItem = resultList.items[j]
          let detailItemsPromise = detailHandlerDef.handler(resultItem.result, true)
          otherDetailItemsPromises.push(otherDetailItemsPromises)
          todoArray.push({resultItem, detailItemsPromise})
        }

        let firstDetailItems = await firstDetailItemsPromise
        let relevantDetailItems = firstDetailItems.filter(item => (item.type !== "labelvaluelist" && item.type !== "resultlist" && item.type !== "list" && item.type !== "section_start" && item.type !== "section_end"))
        let infoItemsHeaders = this.extractItemHeaders(relevantDetailItems)
        resultList.infoItemsHeaders = infoItemsHeaders
        firstResultItem.infoItems = relevantDetailItems
        
        await Promise.all(otherDetailItemsPromises)
        for (let todo of todoArray) {
          let detailItems = await todo.detailItemsPromise
          let resultItem = todo.resultItem
          let relevantDetailItems = detailItems.filter(item => (item.type !== "labelvaluelist" && item.type !== "resultlist" && item.type !== "list" && item.type !== "section_start" && item.type !== "section_end"))
          resultItem.infoItems = relevantDetailItems
        }
        
        break
        
      }
  }

  extractItemHeaders(items) {
    let headerItems = []
    for (let item of items)
      if (item.type === "result") {
        let result = item.result
        let label = result.type.singular
        headerItems.push({
          type: "result",
          label: label
        })
      } else if(item.type === "labelvalue") {
        headerItems.push({
          type: "labelvalue",
          label: item.label,
          valueformat: item.valueformat
        })
      } else if (item.type === "link") {
        headerItems.push({
          type: "link",
          label: item.value
        })
      }else if (item.type === "image") {
        headerItems.push({
          type: "image"
        })
      }
    return headerItems
  }
  
  isApplicable(result) {
    return this.isApplicableFunction(result)
  }
}