import DetailsHandlerDef from "./DetailsHandlerDef"
import icons from "../resources/icons"

export default class ComposedDetailsHandler extends DetailsHandlerDef {
  constructor(options = {}) {
    
    super(options)
    this._detailHandlers = []

    if (options.detailhandlers)
      this._detailHandlers = options.detailhandlers

    //This is deprecated (spelling error. Keep backwards comp)
    if (options.detailshandlers)
      this._detailHandlers = options.detailshandlers

    this.handlerFunction = this.myHandler
    
    this.isApplicableFunction = this.isApplicable

  }

  set detailhandlers(dhCollection) {
    for (let detailhandler of dhCollection)
      this.addDetailsHandler(detailhandler)
  }
  
  //This is deprecated (spelling error. Keep backwards comp)
  set detailshandlers(dhCollection) {
    for (let detailhandler of dhCollection)
      this.addDetailsHandler(detailhandler)
  }

  get detailshandlers() {
    return this._detailHandlers
  }
    
  addDetailsHandler(detailHandler) {
    this._detailHandlers.push(detailHandler)
  }

  async myHandler(result) {
    let detailItems = []
    const calls = []
    for (let detailHandler of this._detailHandlers)
      calls.push({detailHandler, promise: detailHandler.handler(result, true)})
      //promises.push(detailHandler.handler(result, true))
    
    await Promise.allSettled(calls.map((call)=>{
      return call.promise
    }))
      
    for (let call of calls)
      try {
        let detailHandlerResult = await call.promise
        detailItems = [...detailItems, ...detailHandlerResult]
      } catch(e) {
        try {
          let label = call.detailHandler.getbuttonText(result)
          if (label !== "") {
            detailItems.push({
              "type": "error",
              "icon": icons.infoRed,
              "label": label,
              "value": "Der skete en fejl",
              "message": e.message
            })
          }
        } catch (e2) {
          throw e
        }
        // Stupid noop to fool eslint
      }
    return detailItems
  }

  isApplicable(result) {
    for (let detailHandler of this._detailHandlers) 
      if (detailHandler.isApplicable(result)) 
        return true
    return false
  }

}
