import Searcher from './Searcher'
import DetailsHandlerDef from "../details/DetailsHandlerDef"

/**
 *
 * @class Searches Septima Search Server (S3)
 * @extends module:js/searchers/Searcher
 *
 */

export default class S3Searcher extends Searcher {
  /**
   *
   * @param {Object} options S3Searcher expects these properties:
   * @param {string} options.host The S3 host, e.g. "http://s3.demo.septima.dk"
   * @param {string} options.service The configuration e.g. "/api/v1/organisations/septima/configurations/detailitemdemo"
   *
   */
  constructor(options= {}) {
    if (typeof options.host === "undefined" || typeof options.host === "undefined")
      throw "New Septima.Search.S3Searcher(options): Options host and/or service missing"
    super(options)
    
    this.indexedDatasources = null

    this.s3Host = options.host.replace(/\/$/, "") //remove trailing slash
    this.service = options.service
    
    this.endPoint = this.s3Host + this.service

    this.sources = null
    this.sourcesReady = this.getS3Sources()
  }

  async getS3Sources() {
    if (this.sources === null) {
      this.sources = []
      this.sources = await this.fetch(this.endPoint + '/sources', {})
      //The s3 response is controller.getSources() serialized
      for (let source of this.sources) {
        let sourceName = source.source
        for (let type of source.types) 
          this.registerType(sourceName, type)
      }
    }
  }

  ready() {
    return this.sourcesReady
  }

  async fetchData(query, caller) {
    try {
      await this.sourcesReady
      this.myFetchData(query, caller)
    } catch (error) {
      caller.fetchError()
    }
  }

  async sq(query) {
    let url
    let path = ''
    if (query.hasTarget)
      path = this.getPathFromTarget(query.target)
    url = this.endPoint + path + "?sq=" + query.wkt
    let data = await this.fetch(url)
    let queryResult
    const caller = {
      fetchSuccess(qr) {
        queryResult = qr
      }
    }
    data.query = query
    this.success(caller, data)
    return queryResult
  }

  async myFetchData(query, caller) {
    let url
    switch (query.type) {
      case 'collapse': {
        let path = ''
        if (query.hasTarget)
          path = this.getPathFromTarget(query.target)
        url = this.endPoint + path + "?q="
        break
      }
      case 'cut':
      case 'no-cut':
      case 'list': {
        let path = ''
        if (query.hasTarget)
          path = this.getPathFromTarget(query.target)
        url = this.endPoint + path + "?q=" + query.queryString
      }
    }
    try {
      let data = await this.fetch(url)
      data.query = query
      this.success(caller, data)
    } catch (error) {
      caller.fetchError()
    }
  }

  getPathFromTarget(target) {
    return "/sources/" + target.source + "/types/" + target.type
  }

  getSourcesAsNewQueries() {
    const queryResult = this.createQueryResult()
    for (let source of this.sources) {
      let sourceName = source.source
      for (let type of source.types) 
        queryResult.addNewQuery(sourceName, type, type, null, "", null, null)
      
    }
    return queryResult
  }

  success(caller, data) {
    const queryResult = this.createQueryResult()
    for (let thisItem of data) 
      if (thisItem.item === "newquery") {
        let newQuery = queryResult.addNewQuery(thisItem.source, thisItem.type, thisItem.title, thisItem.description, thisItem.newquery, null, null)
        newQuery.image = thisItem.image
      } else if (thisItem.item === "shortresult") {
        let result = queryResult.addResult(thisItem.source, thisItem.type, thisItem.title, thisItem.description, null, null)
        result._s3data = thisItem
        result.image = thisItem.image
        result.id = thisItem.id
        result.isComplete = false
      } else if (thisItem.item === "deepresult") {
        let result = queryResult.addResult(thisItem.source, thisItem.type, thisItem.title, thisItem.description, thisItem.geometry, null)
        result._s3data = thisItem
        result.image = thisItem.image
        if (thisItem.id) 
          result.id = thisItem.id
      }
    caller.fetchSuccess(queryResult)
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      result.isComplete = true
      let s3Result = await this.fetch( this.s3Host + result._s3data.href)
      result.data = s3Result.data
      result.geometry = s3Result.geometry
      return result
    }
  }

  hasdetailHandlerDefs(result) {
    if (result._s3data.details && result._s3data.details.length > 0) 
      return true
    else 
      return false
  }
  
  async get(id, type, source) {
    const queryResult = this.createQueryResult()
    let url = this.endPoint + "/sources/" + source + "/types/" + type + "/" + id
    let data = await this.fetch( url )
    let s3Result = data
    if (s3Result.item === "result") {
      let result = queryResult.addResult(s3Result.source, s3Result.type, s3Result.title, s3Result.description, s3Result.geometry, s3Result.data)
      result._s3data = s3Result
      result.image = s3Result.image
      if (s3Result.id) 
        result.id = s3Result.id
      return result
    }
  }

  convertResultdetailItemToResult(detailItem) {
    let queryResult = this.createQueryResult()
    let s3Result = detailItem.result
    let result = queryResult.addResult(s3Result.source, s3Result.type, s3Result.title, s3Result.description, null, s3Result.data)
    if (s3Result.geometry && s3Result.geometry !== null && s3Result.geometry !== "")
      result.geometry = s3Result.geometry

    result._s3data = s3Result
    result.isComplete = false
    result.image = s3Result.image
    result.id = s3Result.id
    result.distance = s3Result.distance
    return result
  }
  
  getDetailHandlersForResult(result) {
    const details = result._s3data.details
    let detailHandlerDefs = []
    if (typeof details !== 'undefined') 
      for (let thisDetail of details) 
        if (thisDetail.href) {
          let detailHandlerDef = new DetailsHandlerDef(
            { "buttonText": thisDetail.title,
              "buttonImage": thisDetail.image,
              "handler": async() => {
                let returnItems = []
                let response =  await this.fetch(this.s3Host + thisDetail.href)
                if (response.message === 'S3Error')
                  throw new Error('S3Error: ' + response.inner)
                for (let detailItem of response) {
                  if (detailItem.type === 'result') 
                    detailItem.result = this.convertResultdetailItemToResult(detailItem)
                  else if (detailItem.type === 'resultlist' || detailItem.type === 'list')
                    for (let resultItem of detailItem.items.filter(i => i.type === 'result')) {
                      resultItem.result = this.convertResultdetailItemToResult(resultItem)
                      if (resultItem.infoItems) 
                        for (let infoItem of resultItem.infoItems) 
                          if (infoItem.type === 'result')
                            infoItem.result = this.convertResultdetailItemToResult(infoItem)
                    }
                   
                  returnItems.push(detailItem)
                }
                return returnItems  
              },
              "renderHints": thisDetail.renderHints,
              "more": thisDetail.more}
          )
          detailHandlerDefs.push(detailHandlerDef)
        }
    return detailHandlerDefs
  }

}
