/**
 * @module
 */

import DetailsHandlerDef from "../../details/DetailsHandlerDef"
/**
 * Decorates any result with examples of all types of details
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
 * _type: Septima.Search.CVR_enhedSearcher
 * _options:
 *   kommunekode: '101'
 * detailhandlers:
 *   - _type: Septima.Search.DemoDetailsHandler
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * cvr_enhedSearcher.addDetailHandlerDef(new Septima.Search.DemoDetailsHandler())
 *
 * @example <caption>ES6:</caption>
 * import DemoDetailsHandler from './searchers/detailhandlers/DemoDetailsHandler'
 * dawaSearcher.addDetailHandlerDef(new DemoDetailsHandler())
 * @api
 **/
export default class ThrowingDetailsProvider extends DetailsHandlerDef {
  constructor(options = {}) {
    if (!options.buttonText)
      options.buttonText = "ThrowingDetailsProvider"
    super(options)
    this.handlerFunction = this.myHandler
  }

  async myHandler(result) {
    throw new Error("An exception was thrown for " + result.title)
  }
}