import DataSearcher from "./DataSearcher"
import SearchableData from "./local/SearchableData"
import DetailsHandlerDef from "../details/DetailsHandlerDef"
import icons from "../resources/icons"
import DetailItemsList from "../details/DetailItemsList"

export default class ClassSearcher extends DataSearcher {
  constructor(options) {
    let searchableData = new SearchableData({
      data: options.registry.getClasses(),
      displaynameProperty: "className",
      searchProperties: ["classdesc", "prefix"],
      singular: "Type",
      plural: "Types",
      idProperty: "className",
      descriptionProperty_not: "classdesc",
      getDescription: (type) => {
        return type.classdesc ? type.classdesc : type.prefix + type.className 
      }
    })
    super({
      searchableData,
      onSelect: options.onSelect
    })
  }

  getDetailHandlersForResult(result) {
    const detailHandlerDefs = []
    let constructorDetailsHandlerDef = new DetailsHandlerDef(
      { "buttonText": result.data.className,
        "more": true,
        "buttonImage": icons.infoGrey,
        "handler": this.constructorDetailsHandler})
    detailHandlerDefs.push(constructorDetailsHandlerDef)
    if (result.data.examples) {
      let examplesDetailsHandlerDef = new DetailsHandlerDef(
        { "buttonText": "Examples",
          "buttonImage": icons.folderIcon,
          "handler": this.examplesDetailsHandler})
      detailHandlerDefs.push(examplesDetailsHandlerDef)
    }
    return detailHandlerDefs
  }

  examplesDetailsHandler(result) {
    let extractCaptionExample = (example) =>{
      let caption = ""
      let exampleText = ""
      let captionEnd = example.indexOf("</caption>")
      if (captionEnd > -1) {
        caption = example.substring(0, captionEnd)
        exampleText = example.substr(captionEnd + 10)
      } else {
        exampleText = example
      }
      return {caption: caption, exampleText: exampleText}
    }
    
    let items = []
    for (let exampleContent of result.data.examples) {
      let extractedText = extractCaptionExample("" + exampleContent)
      items.push({
        type: "labelvalue",
        label: extractedText.caption,
        value: extractedText.exampleText,
        valueformat: "pre"
      })
    }
    return items
  }

  constructorDetailsHandler(result) {
    let items = []
    if (result.description)
      items.push({
        type: "labelvalue",
        value: result.description
      })
    items.push({
      type: "labelvalue",
      label: "_type",
      value: result.data.prefix + result.data.className
    })
    if (result.data.constructor.length > 1) {
      let otherOptionItems = []
      let detailItemsList = new DetailItemsList({
        itemType: "labelvalue",
        header: "_options",
        infoItemsHeaders: [
          {type: "labelvalue", label: "Type"},
          {type: "labelvalue", label: "Optional?"},
          {type: "labelvalue", label: "Default value"},
          {type: "labelvalue", label: "description"}]
      })
      for (let option of result.data.constructor)
        /*
        {
          "type": "string",
          "optional": true,
          "defaultvalue": "'*'",
          "description": "\"*\" Search all municipalities (Default)</br>Search specific municipalities eg. \"101\" or \"101|256\"",
          "name": "options.kommunekode"
        }
        */
        if (option.name == "options") {
          if (option.description) {
            let topDetailItem = {
              type: "labelvalue",
              value: "",
              infoItems: [
                {type: "labelvalue", value: ""},
                {type: "labelvalue", value: ""},
                {type: "labelvalue", value: ""},
                {type: "labelvalue", value: option.description}]
            }
            detailItemsList.append(topDetailItem)
          }
        } else {
          otherOptionItems.push({
            type: "labelvalue",
            value: option.name,
            infoItems: [
              {type: "labelvalue", value: option.type},
              {type: "labelvalue", value: option.optional ? "optional" : "mandatory"},
              {type: "labelvalue", value: option.defaultvalue},
              {type: "labelvalue", value: option.description}]
          })
        }

      for (let otherOptionItem of otherOptionItems)
        detailItemsList.append(otherOptionItem)
      items.push(detailItemsList.asItem())    
    }
    return items
  }
}