import { fetch2 } from '../utils'
import proj4 from 'proj4'

export default class RouteCalculator {
	
  initialize(options) {
    this.onlyPoints = true
		
    if (options.apikey) 
      this.apikey = options.apikey
    else
      throw 'Septima.Search.RouteCalculator: option.apikey is missing'
    
		
    this.profile = 'car'
    if (options.profile) 
      //    		'car'
      //    		'bus'
      //    		'bicycle'
      //    		'foot'
      this.profile = options.profile
    
		
    if (options.fromResult &&  options.fromResult.geometry) 
      //    	{type: , crs:{"type": "name","properties": {"name": "PPPPPP"}} coordinates}
      this.fromResult = options.fromResult
    else
      throw 'Septima.Search.RouteCalculator: option.fromGeometry is missing'
    
		
    if (options.toFeatureCollection) 
      //{type: "FeatureCollection", crs, features:[{type: "Feature", geometry:{type, coordinates}}]}
      this.toFeatureCollection = options.toFeatureCollection
    else
      throw 'Septima.Search.RouteCalculator: option.toFeatureCollection is missing'
    
		
  }
	
  loadLibs() {
    return Promise.resolve()
  }
	
  async calculate() {
    await this.loadLibs()
    return await this._calculate()
  }
	
  async _calculate() {
    const fromGeometry = this.fromResult.geometry
    const fromLatLonPoint = this.getLatLonPoint(fromGeometry)
    const toLatLonCoordinates = this.getLatLonCoordinates(fromGeometry, this.toFeatureCollection)
    let latlonstr = ""
    
    for(let i=0; i<toLatLonCoordinates.length; i++) 
      latlonstr += "&lat=" + toLatLonCoordinates[i][1].toFixed(6) + "&lon=" + toLatLonCoordinates[i][0].toFixed(6)                  
    
    
    const url = 'https://new-routing.septima.dk/' + this.apikey + '/one2many?engine=' + this.profile +
              '&returntype=json&fromlat=' + fromLatLonPoint[1].toFixed(6) + '&fromlon=' + fromLatLonPoint[0].toFixed(6) + latlonstr

    let data = await fetch2(url, {})
    // Old version uses 1e-5 for precision
    let precision = 1e-6
    if( data.version == 0.3) 
      precision = 1e-5
    
    const epsg = this.toFeatureCollection.crs.properties.name.replace(/^.*EPSG/, 'EPSG').replace(/::/, ':')
    if (typeof proj4.defs(epsg) === 'undefined') 
      if (epsg === 'EPSG:25832') {
        proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")
        proj4.defs('urn:x-ogc:def:crs:EPSG:25832', proj4.defs('EPSG:25832'))
      }
    

    for(let i=0; i<this.toFeatureCollection.features.length; i++) {
      let thisData = data[i]
      let feature = this.toFeatureCollection.features[i]
      if (thisData.status === 0 && !(feature.geometry.type !== 'Point' && this.onlyPoints)) 
        feature.route = {
          from: this.fromResult,
          time: thisData.route_summary.total_time,
          dist: thisData.route_summary.total_distance,
          geometry:{
            "type" : "LineString",
            "coordinates" : this.decode(thisData.route_geometry, epsg, precision)
          }
        }
      
    }
    return this.toFeatureCollection
  }
	
  getLatLonPoint(fromGeometry) {
    let fromPoint
    if (fromGeometry.type == 'Point') 
      fromPoint = fromGeometry.coordinates
    else if (fromGeometry.type == 'MultiPoint' || fromGeometry.type == 'LineString') 
      fromPoint = fromGeometry.coordinates[0]
    else 
      fromPoint = fromGeometry.coordinates[0][0]
    

    const fromEpsg = fromGeometry.crs.properties.name.replace(/^.*EPSG/, 'EPSG').replace(/::/, ':')
    if (typeof proj4.defs(fromEpsg) === 'undefined') 
      if (fromEpsg === 'EPSG:25832') {
        proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")
        proj4.defs('urn:x-ogc:def:crs:EPSG:25832', proj4.defs('EPSG:25832'))
      }
    
    return proj4(fromEpsg,'EPSG:4326', fromPoint)
  }
	
  getLatLonCoordinates(fromGeometry, toFeatureCollection) {
    // const fromEpsg = fromGeometry.crs.properties.name.replace(/^.*EPSG/, 'EPSG').replace(/::/, ':');

    const toEpsg = toFeatureCollection.crs.properties.name.replace(/^.*EPSG/, 'EPSG').replace(/::/, ':')
    if (typeof proj4.defs(toEpsg) === 'undefined') 
      if (toEpsg === 'EPSG:25832') {
        proj4.defs("EPSG:25832", "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs")
        proj4.defs('urn:x-ogc:def:crs:EPSG:25832', proj4.defs('EPSG:25832'))
      }
    
		
    const features = toFeatureCollection.features
    const coordinates = []
    for(let i=0; i<features.length; i++) {
      let thisFeature = features[i] 
      let coordinate
      if (thisFeature.geometry.type == 'Point') 
        coordinate = thisFeature.geometry.coordinates
      else if (thisFeature.geometry.type == 'MultiPoint' || thisFeature.geometry.type == 'LineString') 
        coordinate = fromGeometry.coordinates[0]
      else 
        coordinate = fromGeometry.coordinates[0][0]
      
      coordinate = proj4(toEpsg, 'EPSG:4326', coordinate)
      coordinates.push(coordinate)
    }
    return coordinates
  }

  decode(encoded, proj, precision) {
    const len = encoded.length
    let index = 0
    const array = []
    let lat = 0
    let lng = 0

    while (index < len) {
      let b
      let shift = 0
      let result = 0
      do {
        b = encoded.charCodeAt(index++) - 63
        result |= (b & 0x1f) << shift
        shift += 5
      } while (b >= 0x20)
      //noinspection UnnecessaryLocalVariableJS,JSBitwiseOperatorUsage
      const dlat = ((result & 1) ? ~(result >> 1) : (result >> 1))
      lat += dlat

      shift = 0
      result = 0
      do {
        b = encoded.charCodeAt(index++) - 63
        result |= (b & 0x1f) << shift
        shift += 5
      } while (b >= 0x20)
      //noinspection JSBitwiseOperatorUsage,UnnecessaryLocalVariableJS
      const dlng = ((result & 1) ? ~(result >> 1) : (result >> 1))
      lng += dlng

      const p = proj4('EPSG:4326', proj, [lng * precision, lat * precision])
      array.push(p)
    }

    return array
  }
}
