/**
 * @module
 */
import View from './View'
import AccordionDetailsLayout from './AccordionDetailsLayout'
import DetailItemsRenderer from './DetailItemsRenderer'

/**
 *
 * Default UI for Septima Search
 * @extends module:js/defaultview/View
 *
 * @example
 * var view = new DefaultView(
 *   {
 *     input : 'septimasearch',
 *     placeholder : 'Search'
 *   });
 * @api
 */
export default class extends View {

  constructor(options) {
    if (typeof options.mouseover === 'undefined') {
      let isSafari = !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/)
      if (isSafari) 
        options.mouseover = false
      else
        options.mouseover = true
      
    }
    options.detailsLayout =  new AccordionDetailsLayout(
      {
        detailItemsRenderer: new DetailItemsRenderer()
      }
    )
    
    super(options)
  }

}
