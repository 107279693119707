import Searcher from './Searcher'
import {reproject, reverse} from '../util/reproject'
import {geojsonPrecision} from '../util/geojson-precision'
import ResultType from "../ResultType"

export default class IsoChroneSearcher extends Searcher {

  constructor(searcherOptions) {
    let defaults =  {
      serviceUrl : 'https://otp.septima.dk/septima-OO47AF6JEX/otp/routers/denmark/isochrone',
      mode: 'CAR',
      modetext: 'kørsel',
      date: '11-14-2017',
      time: '8:00am',
      precisionMeters : 100,
      maxWalkDistance : 10,
      cutoffSecs : [120, 240, 360 , 480],
      source : 'fama.septima.dk',
      type : "isochrone",
      iconURI: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMywxQzEuODksMSAxLDEuODkgMSwzVjE0QzEsMTUuMTEgMS44OSwxNiAzLDE2SDdWMjBDNywyMS4xMSA3Ljg5LDIyIDksMjJIMjBDMjEuMTEsMjIgMjIsMjEuMTEgMjIsMjBWOUMyMiw3Ljg5IDIxLjExLDcgMjAsN0gxNlYzQzE2LDEuODkgMTUuMTEsMSAxNCwxSDNNMywzSDE0VjlIMjBWMjBIOVYxNEgzVjNaIiAvPjwvc3ZnPg=="
    }    
    
    let options = Object.assign({usesGeoFunctions: true}, defaults, searcherOptions ? searcherOptions : {})
    super(options)
    this.options = options

    this.type = new ResultType({
      id: this.options.type,
      queryBehaviour: 'none',
      geometrySupport: 'sq'
    })
    this.registerType(this.options.source,  this.type)

    this.registerType(this.options.source, this.options.type)
  }
  
  fetchData(query, caller) {
    caller.fetchSuccess(this.createQueryResult())
  }
  
  async sq(query) {
    let queryResult = this.createQueryResult()
    if (query.wkt && query.wkt.indexOf('POINT') > -1) {
      let geometry = this.translateWktToGeoJsonObject(query.wkt)
      //Voodoo to create plain object instead of terraformer - move to translateWktToGeoJsonObject?
      geometry = {type: geometry.type, coordinates: geometry.coordinates}
      geometry = this.toWgs84(geometry)
      let url = this.buildUrl(geometry)
      let featureCollection = await this.fetch(url)
      for (let i=featureCollection.features.length - 1; i > -1; i--) {
        let feature = featureCollection.features[i]
        if (feature.geometry.coordinates.length > 0) {
          feature.geometry = this.to25832(feature.geometry)
          let result = queryResult.addResult(this.options.source, this.options.type, this.secondsToHms(feature.properties.time) + 's ' + this.options.modetext, null, feature.geometry, feature)
          result.distance = 0
        }
      }
    }
    return queryResult
  }

  toWgs84(geometry) {
    let crss = {
      "EPSG:25832": "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
      "EPSG:4326": "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"
    }
    let toGeometry = reproject(geometry, "EPSG:25832", "EPSG:4326", crss)
    return toGeometry
  }

  to25832(geometry) {
    let crss = {
      "EPSG:25832": "+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
      "EPSG:4326": "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"
    }
    let toGeometry = reproject(geometry, "EPSG:4326", "EPSG:25832", crss)
    toGeometry = geojsonPrecision(toGeometry, 0)
    return toGeometry
  }

  secondsToHms(d) {
    d = Number(d)
    let h = Math.floor(d / 3600)
    let m = Math.floor(d % 3600 / 60)
    let s = Math.floor(d % 3600 % 60)

    let hDisplay = ""
    if (h > 0) 
      hDisplay = h + (h == 1 ? " time" : " timer")
    
    let mDisplay = ""
    if (m > 0) {
      if (h > 0) 
        mDisplay = " ,"
      
      mDisplay += m + (m === 1 ? " minut" : " minutter")
    }
    let sDisplay = ""
    if (s > 0) {
      if (m > 0) 
        sDisplay = " ,"
      
      sDisplay += s + (s === 1 ? " sekund" : " sekunder")
    }
    return hDisplay + mDisplay + sDisplay
  }

  buildUrl(geometry) {
    //http://fama.septima.dk:8080/otp/routers/denmark/isochrone?fromPlace=55.79153439014434,12.485555485973205&mode=CAR&date=11-14-2017&time=8:00am&precisionMeters=10&maxWalkDistance=10&cutoffSec=120&cutoffSec=240&cutoffSec=360&cutoffSec=480
    let reverseGeometry = reverse(geometry)
    let fromPlace = reverseGeometry.coordinates[0] + "," + reverseGeometry.coordinates[1]
    let cutoffSecParamValues = []
    for (let i=0; i < this.options.cutoffSecs.length; i++) 
      cutoffSecParamValues.push("cutoffSec=" + this.options.cutoffSecs[i])
    
    let cutoffSecsQueryString = cutoffSecParamValues.join('&')
    let url = `${this.options.serviceUrl}?fromPlace=${fromPlace}&mode=${this.options.mode}&date=${this.options.date}&time=${this.options.time}&precisionMeters=${this.options.precisionMeters}&maxWalkDistance=${this.options.maxWalkDistance}&${cutoffSecsQueryString}`
    return url
  }

}