/**
 * @module
 */
import ResultType from "../ResultType"
import Searcher from './Searcher'
import PlanSearcher from "./PlanSearcher"
/**
 *
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration: <a href='../examples/plansystemsearcher/'>Example</a></caption>
  _type: Septima.Search.PlanSystemSearcher
  _options:
    minimumShowCount: 3
    searchindexToken: TOKEN
    targets:
      - vedtagetlokalplan
      - kommuneplanramme
      - regionplan
      - byggefelt
      - delområde

 * @example <caption> JS options: </caption>
 options = {
    searchindexToken: 'TOKEN'
  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.PlanSystemSearcher(options))
 *
 * @example <caption>ES6:</caption>
 * import PlanSystemSearcher from './searchers/PlanSystemSearcher'
 * controller.addSearcher(new PlanSystemSearcher(options))
 */


export default class PlanSystemSearcher extends Searcher {
  /**
   *
   * @param {Object} options PlanSystemSearcher expects these properties:
   * @param {string} options.searchindexToken Please contact Septima to obtain a valid token
   * @param {string} [options.kommunekode='*']  "*" Search all municipalities (Default)</br>Search specific municipalities eg. "101" or "101|256"
   * @api
   */
  constructor(options) {
    super(options)
    this.source = "plansystem"
    if (options.source) 
      this.source = options.source
    
    this.targets = ['kommuneplanramme','vedtagetlokalplan']
    if (typeof options !== 'undefined' && typeof options.targets !== 'undefined')
      this.targets = options.targets
    
    this.types = {
      "lp":  new ResultType({id: "vedtagetlokalplan", singular: "Vedtaget lokalplan", plural: "Vedtagne lokalplaner", featuretype:"theme_pdk_lokalplan_vedtaget_v"}),
      "kpl": new ResultType({id: "kommuneplanramme", singular: "Kommuneplanramme", plural: "Kommuneplanrammer", queryBehaviour: "match", featuretype:"theme_pdk_kommuneplanramme_alle_vedtaget_v"}),
      "rp": new ResultType({id: "regionplan", singular: "Regionplan", plural: "Regionplaner", queryBehaviour: "match", featuretype:"regionplaner"}),
      "bf": new ResultType({id: "byggefelt", singular: "Byggefelt", plural: "Byggefelter", queryBehaviour: "match", featuretype:"theme_pdk_byggefelt_vedtaget_v"}),
      "do": new ResultType({id: "delområde", singular: "Delområde", plural: "Delområde", queryBehaviour: "match", featuretype:"theme_pdk_lokalplandelomraade_vedtaget_v"})
    }
    this.registerType(this.source, this.types.lp)
    this.registerType(this.source, this.types.kpl)
    this.registerType(this.source, this.types.rp)
    this.registerType(this.source, this.types.bf)
    this.registerType(this.source, this.types.do)
    
    //prepare plansearcher if necessary
    if (this.targets.indexOf('vedtagetlokalplan') > -1) {
      if (!options || !options.searchindexToken)
        throw new Error("PlansystemSearcher: options.searchindexToken is mandatory")
      let planSearcherOptions = {
        searchindexToken: options.searchindexToken
      }

      if (options.kommunekode && options.kommunekode !== "*")
        planSearcherOptions.kommunekode = options.kommunekode

      this.planSearcher = new PlanSearcher(planSearcherOptions)
    }
  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      let gotResult = await this.get(result.id, result.typeId)
      result.geometry = gotResult.geometry
      result.data = gotResult.data
      result.isComplete = true
      return result
    }
  }


  async fetchData(query, caller) {
    const queryResult = this.createQueryResult()

    let lpFetchDataPromise
    if ((!query.hasTarget || query.target.type === this.types.lp.id) && this.targets.indexOf('vedtagetlokalplan') > -1) 
      lpFetchDataPromise = this.planSearcher.asyncFetchData(query, this._searchDelay)
    else 
      lpFetchDataPromise = Promise.resolve(this.createQueryResult())
    

    let fetchPromises = []
    if (!query.hasTarget) {
      if (query.queryString !== "" && parseInt(query.queryString) && this.targets.indexOf('kommuneplanramme') > -1) 
        fetchPromises.push(this.fetchKommuneplanRammeById(query.queryString))
      if (query.queryString !== "" && this.targets.indexOf('regionplan') > -1) 
        fetchPromises.push(this.fetchRegionPlanById(query.queryString))
      if (query.queryString !== "" && parseInt(query.queryString) && this.targets.indexOf('byggefelt') > -1) 
        fetchPromises.push(this.fetchByggeFeltById(query.queryString))
      if (query.queryString !== "" && parseInt(query.queryString) && this.targets.indexOf('delområde') > -1) 
        fetchPromises.push(this.fetchDelomraadeById(query.queryString))
    }
    //Process fetch promises
    let featuresArray = await Promise.all(fetchPromises)
    for ( let features of featuresArray ) 
      if (features.length === 1)
        this.addFeatureToQueryResult(features[0], queryResult)

    //Process lpFetchDataPromise
    let lpQueryResult = await lpFetchDataPromise
    for (let lpResult of lpQueryResult.getResults()) {
      let result = queryResult.addResult(this.source, this.types.lp.id, lpResult.title, lpResult.description)
      result.id = lpResult.id
      result.isComplete = false
    }
    for (let newQuery of lpQueryResult.getNewQueries())
      queryResult.addNewQuery(this.source, this.types.lp.id, newQuery.title, newQuery.description, newQuery.newquery)

    caller.fetchSuccess(queryResult)
  }
  
  async get(id, type) {
    let queryResult = this.createQueryResult()
    if (type === this.types.lp.id) {
      let features = await this.fetchVedtagetLokalplanById(id)
      if (features.length === 1)
        this.addFeatureToQueryResult(features[0], queryResult)
    } else if (type === this.types.kpl.id) {
      let features = await this.fetchKommuneplanRammeById(id) 
      if (features.length === 1)
        this.addFeatureToQueryResult(features[0], queryResult)
    } else if (type === this.types.bf.id) {
      let features = await this.fetchByggeFeltById(id)
      if (features.length === 1)
        this.addFeatureToQueryResult(features[0], queryResult)
    }else if (type === this.types.do.id) {
      let features = await this.fetchDelomraadeById(id)
      if (features.length === 1)
        this.addFeatureToQueryResult(features[0], queryResult)
    } else if (type === this.types.rp.id) {
      let features = await this.fetchRegionPlanById(id)
      if (features.length === 1)
        this.addFeatureToQueryResult(features[0], queryResult)
    }else{
      throw new Error('Whoops! get(id) is not implemented for ' + type)
    }
    
    let results = queryResult.getResults()
    if (results.length === 1)
      return results[0]
  }
  
  addFeatureToQueryResult(feature, queryResult) {

    let typeId = this.featureToTypeId(feature)
    let title
    let description

    if (typeId === this.types.lp.id) {
      title = feature.properties.plannavn
      description = feature.properties.anvendelsegenerel

    } else if  (typeId === this.types.kpl.id) {
      title = feature.properties.plannavn
      description = feature.properties.anvendelsegenerel

    } else if  (typeId==this.types.bf.id) {
      title = `${feature.properties.planid} - ${feature.properties.delnr}`
      let anvendelsesTekst = this.getAnvendelsesTekstFraFeature(feature)
      description = `Byggefelt (${anvendelsesTekst}) til ${feature.properties.lp_plannavn}`

    } else if (typeId===this.types.do.id) {
      title = `${feature.properties.planid} - ${feature.properties.delnr}`
      let anvendelsesTekst = this.getAnvendelsesTekstFraFeature(feature)
      description = `Delområde (${anvendelsesTekst}) til ${feature.properties.lp_plannavn}`

    } else if (typeId===this.types.rp.id) {
      title = feature.properties.dokumentna.replace('Ã¸','ø').replace('tillg','tillæg').replace('Ã¦','æ').replace('Storstrom','Storstrøm').replace('Ribe_amt_samlet',' Ribe Amt samlet').replace('Snderjyllands','Sønderjyllands').replace('Ringkbing','Ringkøbing').replace('Vestsjlland','Vestsjælland')
      description = feature.properties.amtnavn.replace('Ã¸','ø').replace('Ã¦','æ').replace('Ã','Å')
    }

    let geometry = feature.geometry
    let result = queryResult.addResult(this.source, typeId, title, description, geometry, feature)
    if (typeId===this.types.rp.id)
      result.id = feature.id
    else
      result.id = feature.properties.planid
    return result
  }


  featureToTypeId(feature) {
    let featureType = feature.id.split('.')[0]
    let type
    Object.entries(this.types).map(obj => {      
      let thisType = obj[1]
      if (thisType.values.featuretype === featureType)
        type = thisType
    })
    return type.id
  }

  getTypenamesFromTargets(targets, types) {
    let typeNames = []
    Object.entries(types).forEach(entry => {
      let resultType = entry[1]
      if (targets.indexOf(resultType.id) > -1)
        typeNames.push(resultType.values.featuretype)
    })
    return typeNames
  }

  getTypenameFromTarget(target, types) {
    let typeName
    Object.entries(types).forEach(entry => {
      let resultType = entry[1]
      if (resultType.id === target)
        typeName = resultType.values.featuretype
    })
    return typeName
  }
  
  async sq(query) {
    let queryResult = this.createQueryResult()
    let fetchPromises = []
    let typeNamesAbekat = []
    let wkt = query.wkt
    if (this.targets.indexOf('vedtagetlokalplan') > -1)
      typeNamesAbekat.push(this.getTypenameFromTarget('vedtagetlokalplan', this.types))
    
    if (this.targets.indexOf('kommuneplanramme') > -1)
      typeNamesAbekat.push(this.getTypenameFromTarget('kommuneplanramme', this.types))
    
    if (this.targets.indexOf('byggefelt') > -1)
      typeNamesAbekat.push(this.getTypenameFromTarget('byggefelt', this.types))
    
    if (this.targets.indexOf('delområde') > -1)
      typeNamesAbekat.push(this.getTypenameFromTarget('delområde', this.types))
    
    if (typeNamesAbekat.length > 0)
      fetchPromises.push(this.fetchFeatures(typeNamesAbekat.join(","), 'intersects(geometri,' + wkt + ')', "get"))
    
    if (this.targets.indexOf('regionplan') > -1)
      fetchPromises.push(this.fetchFeatures(this.getTypenameFromTarget('regionplan', this.types), 'intersects(the_geom,' + wkt + ')', "get"))

    //Process fetch promises
    let featuresArray = await Promise.all(fetchPromises)
    for ( let features of featuresArray )
      for (let feature of features) {
        let result = this.addFeatureToQueryResult(feature, queryResult)
        result.distance = 0
      }
    return queryResult
  }

  async fetchVedtagetLokalplanById(id) {
    return await this.fetchFeatures("theme_pdk_lokalplan_vedtaget_v", `planid=${id}`)
  }
     
  async fetchKommuneplanRammeById(id) {
    return await this.fetchFeatures("theme_pdk_kommuneplanramme_alle_vedtaget_v", `planid=${id}`)
  }
    
  async fetchByggeFeltById(id) {
    return await this.fetchFeatures("theme_pdk_byggefelt_vedtaget_v", `planid=${id}`)
  }

  async fetchRegionPlanById(id) {
    let response =  await this.fetch(`https://geoserver.plandata.dk/geoserver/wfs?servicename=wfs&request=getFeature&typename=region:regionplaner&featureID=${id}&outputformat=json`)
    if (response && response.features)
      return response.features
    else 
      return []
  }
    
  async fetchDelomraadeById(id) {
    return await this.fetchFeatures("theme_pdk_lokalplandelomraade_vedtaget_v", `planid=${id}`)
  }
  
  async fetchFeatures(typename, cqlFilter, method="get") {
    let endPoint = "https://geoserver.plandata.dk/geoserver/wfs"
    let result
    if (method === 'get') {
      result = await this.fetch(`${endPoint}?request=getFeature&typename=${typename}&cql_filter=${cqlFilter}&outputFormat=json`)
    } else {
      let params = {
        servicename: 'WFS',
        typename: typename,
        request: 'GetFeature',
        version: '1.0.0',
        outputformat: 'json',
        CQL_FILTER: cqlFilter
      }
      result = this.fetch(endPoint, {data: params, method: 'post'})
    }
    return result.features
  }
  
  async getByggefeltByPlanId(id) {
    let queryResult = this.createQueryResult()
    let features = this.fetchFeatures("theme_pdk_byggefelt_vedtaget_v", `lokplan_id=${id}`)
    for (let feature of features)
      this.addFeatureToQueryResult(feature, queryResult)
    return queryResult
  }

  async getDelomraadeByPlanId(id) {
    let queryResult = this.createQueryResult()
    let features = this.fetchFeatures("theme_pdk_lokalplandelomraade_vedtaget_v", `lokplan_id=${id}`)
    for (let feature of features)
      this.addFeatureToQueryResult(feature, queryResult)
    return queryResult
  }

  async getRelationsForHit(result) {
    if (result.typeId === this.types.bf.id) {
      let planResult = await this.get(result.data.properties.lokplan_id, 'vedtagetlokalplan')
      return {children: [ ], parents: [planResult], siblings: []}
    }
    return {children: [], parents: [], siblings: []}
  }
  
  getAnvendelsesTekstFraFeature(feature) {
    let anvspecvals = []
    Object.entries(feature.properties).map(obj => {
      const key = obj[0]
      const value = obj[1]
      if (key.startsWith('anvspec') && value)
        anvspecvals.push(this.getAnvendelsesTekstFraKode(value))
    })
    return anvspecvals.join(', ')
  }

  getAnvendelsesTekstFraKode(kode) {
    const anvendelseskoder = {
      "1100": {
        "tekst": "Boligområde"
      },
      "1110": {
        "tekst": "Åben-lav boligbebyggelse"
      },
      "1120": {
        "tekst": "Tæt-lav boligbebyggelse"
      },
      "1130": {
        "tekst": "Etageboligbebyggelse"
      },
      "1140": {
        "tekst": "Blandet boligområde"
      },
      "1150": {
        "tekst": "Almene boliger"
      },
      "1160": {
        "tekst": "Fællesanlæg"
      },
      "2110": {
        "tekst": "Blandet byområde"
      },
      "2120": {
        "tekst": "Landsbyområde"
      },
      "2130": {
        "tekst": "Jordbrugsparceller"
      },
      "2140": {
        "tekst": "Blandet bolig og erhverv"
      },
      "3100": {
        "tekst": "Erhvervsområde"
      },
      "3110": {
        "tekst": "Kontor- og serviceerhverv"
      },
      "3115": {
        "tekst": "Industri"
      },
      "3120": {
        "tekst": "Let industri og håndværk"
      },
      "3130": {
        "tekst": "Tung industri"
      },
      "3140": {
        "tekst": "Virksomheder med særlige beliggenhedskrav"
      },
      "3150": {
        "tekst": "Havneerhvervsområde"
      },
      "3155": {
        "tekst": "Transport- og logistikvirksomheder"
      },
      "3160": {
        "tekst": "Erhvervsområde"
      },
      "3165": {
        "tekst": "Produktionsvirksomhed"
      },
      "3166": {
        "tekst": "Konsekvensområde omkring produktionsvirksomheder"
      },
      "3167": {
        "tekst": "Transformationsområde"
      },
      "3170": {
        "tekst": "Byomdannelsesområde"
      },
      "3180": {
        "tekst": "Oplevelsesøkonomisk center"
      },
      "3190": {
        "tekst": "Fødevarevirksomhed med besøgsfaciliteter"
      },
      "4110": {
        "tekst": "Bycenter"
      },
      "4115": {
        "tekst": "Bymidte"
      },
      "4120": {
        "tekst": "Bydelscenter"
      },
      "4130": {
        "tekst": "Butikker til lokalområdets forsyning"
      },
      "4135": {
        "tekst": "Butikker"
      },
      "4140": {
        "tekst": "Område til butiksformål"
      },
      "4150": {
        "tekst": "Skilte/facade regulering"
      },
      "4160": {
        "tekst": "Bygningsbevarende pladskrævende varer"
      },
      "4177": {
        "tekst": "Tankstation"
      },
      "4180": {
        "tekst": "Publikumsorienterede serviceerhverv"
      },
      "4190": {
        "tekst": "Aflastningsområde"
      },
      "5110": {
        "tekst": "Større rekreativt område"
      },
      "5111": {
        "tekst": "Koloni- og nyttehaver"
      },
      "5115": {
        "tekst": "Nærrekreativt område"
      },
      "5116": {
        "tekst": "Beplantning"
      },
      "5117": {
        "tekst": "Bevaringsværdig beplantning"
      },
      "5120": {
        "tekst": "Sports- og idrætsanlæg"
      },
      "5121": {
        "tekst": "Golfbaneanlæg"
      },
      "5122": {
        "tekst": "Støjende fritidsanlæg"
      },
      "5123": {
        "tekst": "Lystbådehavn"
      },
      "5130": {
        "tekst": "Ferie- og kongrescenter"
      },
      "5131": {
        "tekst": "Campingplads og vandrerhjem"
      },
      "5140": {
        "tekst": "Campingplads og vandrerhjem"
      },
      "5150": {
        "tekst": "Rekreativt område"
      },
      "5190": {
        "tekst": "Øvrige ferie- og fritidsformål"
      },
      "6110": {
        "tekst": "Sommerhus- og fritidsboligbebyggelse"
      },
      "7100": {
        "tekst": "Område til offentlige formål"
      },
      "7110": {
        "tekst": "Uddannelsesinstitutioner"
      },
      "7120": {
        "tekst": "Sundhedsinstitutioner"
      },
      "7130": {
        "tekst": "Sociale institutioner"
      },
      "7131": {
        "tekst": "Institutioner"
      },
      "7133": {
        "tekst": "Daginstitutioner"
      },
      "7135": {
        "tekst": "Døgninstitutioner"
      },
      "7140": {
        "tekst": "Kulturelle institutioner"
      },
      "7150": {
        "tekst": "Offentlig administration"
      },
      "7160": {
        "tekst": "Religiøse institutioner og gravpladser"
      },
      "7170": {
        "tekst": "Område til offentlige formål"
      },
      "7180": {
        "tekst": "Fængsler"
      },
      "8100": {
        "tekst": "Tekniske anlæg"
      },
      "8110": {
        "tekst": "Forsyningsanlæg"
      },
      "8111": {
        "tekst": "Vindmølleanlæg"
      },
      "8112": {
        "tekst": "Husstandsvindmølle"
      },
      "8120": {
        "tekst": "Deponeringsanlæg"
      },
      "8130": {
        "tekst": "Rensningsanlæg"
      },
      "8133": {
        "tekst": "Solenergianlæg"
      },
      "8134": {
        "tekst": "Regnvands- og klimaanlæg"
      },
      "8140": {
        "tekst": "Trafikanlæg"
      },
      "8150": {
        "tekst": "Tekniske anlæg"
      },
      "8160": {
        "tekst": "Biogasanlæg"
      },
      "8170": {
        "tekst": "Landskabstekniske anlæg"
      },
      "8180": {
        "tekst": "Trafikanlæg"
      },
      "8181": {
        "tekst": "Vejanlæg"
      },
      "8182": {
        "tekst": "Parkeringsanlæg"
      },
      "8183": {
        "tekst": "Stianlæg"
      },
      "8185": {
        "tekst": "Lufthavn"
      },
      "8186": {
        "tekst": "Helikopterlandingsplads"
      },
      "8190": {
        "tekst": "Trafikterminal"
      },
      "8195": {
        "tekst": "Transportkorridor"
      },
      "9100": {
        "tekst": "Landområde"
      },
      "9110": {
        "tekst": "Jordbrugsområde"
      },
      "9120": {
        "tekst": "Naturområde"
      },
      "9125": {
        "tekst": "Faunapassage"
      },
      "9130": {
        "tekst": "Militære anlæg"
      },
      "9140": {
        "tekst": "Råstofindvinding"
      },
      "9150": {
        "tekst": "Landområder"
      },
      "9170": {
        "tekst": "Industrielt landbrug"
      },
      "9610": {
        "tekst": "Andet"
      }
    }

    return anvendelseskoder[kode]["tekst"]
  }
}
