/**
 * @module
 */
import DetailsHandlerDef from "../../details/DetailsHandlerDef"
import DetailItemsList from "../../details/DetailItemsList"
/**
 * Viser virksomheder (produktionsenheder for en dawa-adresse)
 * @extends module:js/details/DetailsHandlerDef
 * @example <caption>YAML Declaration: <a href='../examples/details/'>Example</a></caption>
  dawa:
    _type: Septima.Search.DawaSearcher
    _options:
      kommunekode: '101'
      allowDetails: true
    detailhandlers:
      - _type: Septima.Search.CvrVirksomhederForAdresseProvider 
         _options:
          cvr_enhedSearcher:
            _ref: "$.mysearchers.cvr"
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
  dawaSearcher.addDetailHandlerDef(new Septima.Search.CvrVirksomhederForAdresseProvider({cvr_enhedSearcher: ...}))
 *
 * @example <caption>ES6:</caption>
 * import CvrVirksomhederForAdresseProvider from './searchers/detailhandlers/CvrVirksomhederForAdresseProvider'
 * dawaSearcher.addDetailHandlerDef(new CvrVirksomhederForAdresseProvider({cvr_enhedSearcher: ...}))
 * @api
 **/
export default class CvrVirksomhederForAdresseProvider extends DetailsHandlerDef {
  /**
   * @param {Object} options
   * @param {Object} options.cvr_enhedSearcher An instance of a Septima.Search.CVR_enhedSearcher
   * @param {Object} [options.mode=list] "list" for list of results / "count" for number of results 
   **/
  constructor(options= {}) {
    if (!options.cvr_enhedSearcher)
      throw "CvrVirksomhederForAdresseProvider needs a CVR_enhedSearcher"

    let defaultOptions = {
      buttonText: "Virksomheder på adressen",
      buttonImage: options.cvr_enhedSearcher.iconURI
    }
    super(Object.assign(defaultOptions, options))

    this.mode = "list"
    if (options.mode)
      this.mode = options.mode
    this.iconURI = options.cvr_enhedSearcher.iconURI
    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler
    this.cvr_enhedSearcher = options.cvr_enhedSearcher
  }

  isApplicable(result) {
    return (result.source === "Dawa" && result.typeId === "adresse")
  }

  async myHandler(result) {
    let vejkode = result.data.properties.vejstykke.kode
    let husnummer = result.data.properties.husnr
    let komkode = result.data.properties.kommune.kode
    let queryResult = await this.cvr_enhedSearcher.getVirksomhederForAdresse(vejkode, husnummer, komkode)
    let pResults = queryResult.getResults()
    let detailItemsList
    if (pResults.length > 0) 
      if (this.mode === "list") {
        if (this.more)
          detailItemsList = new DetailItemsList({itemType: "result", header: "Virksomheder på adressen", image: this.iconURI})
        else
          detailItemsList = new DetailItemsList({itemType: "result"})
        for (let pResult of pResults)
          detailItemsList.append({
            type: "result",
            result: pResult
          })
        return [detailItemsList.asItem()]
      } else {
        if (this.more)
          detailItemsList = new DetailItemsList({itemType: "labelvalue", header: "Virksomheder på adressen", image: this.iconURI})
        else
          detailItemsList = new DetailItemsList({itemType: "labelvalue"})
        let text = "" + pResults.length + (pResults.length === 1 ? " virksomhed på adressen" : " virksomheder på adressen")
        detailItemsList.append({
          type: "labelvalue",
          value: text
        })
        return [detailItemsList.asItem()]
      }
    else
    if (this.more)
      detailItemsList = new DetailItemsList({itemType: "labelvalue", header: "Virksomheder på adressen", image: this.iconURI})
    else
      detailItemsList = new DetailItemsList({itemType: "labelvalue"})
    detailItemsList.append({
      type: "labelvalue",
      value: "Ingen virksomheder på adressen"
    })
    return [detailItemsList.asItem()]
  }
  
}