import DetailsHandlerDef from "../../details/DetailsHandlerDef"
export default class DummyMoreHandler extends DetailsHandlerDef {
  constructor(options = {}) {
    let defaultOptions = {
      handler: async(result) => {
        return [{type: 'textarea', text: "Simple more handler for " + result.title}]
      },
      more: true
    }
    super(Object.assign(defaultOptions, options))
  }
}
