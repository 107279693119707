/** Refactored earlier lib jquery.better-autocomplete.js */

import domutil from "./domutil";
export default function (input, options, callbacks) {

  /**
   * The BetterAutocomplete constructor function. Returns a BetterAutocomplete
   * instance object.
   *
   * @private
   *
   * @constructor BetterAutocomplete
   * @name BetterAutocomplete
   *
   * @param {Object} $input
   *   A single html input element
   */
  var BetterAutocomplete = function ($input, options, callbacks) {
    var lastRenderedQuery = "",
      cache = {}, // Key-valued caching of search results
      cacheOrder = [], // Array of query strings, in the order they are added
      cacheSize = 0, // Keep count of the cache's size
      timer, // Used for options.delay
      activeRemoteCalls = [], // A flat array of query strings that are pending
      disableMouseHighlight = false, // Suppress the autotriggered mouseover event
      inputEvents = {},
      $results = domutil.createElement("ul", "better-autocomplete"),
      hiddenResults = true, // $results are hidden
      preventBlurTimer = null; // IE bug workaround, see below in code.
    var groups = {}; // Key is the group name, value is the heading element.

    options = domutil.extend(
      {
        charLimit: 3,
        delay: 350, // milliseconds
        caseSensitive: false,
        cacheLimit: 0, // Number of result objects
        remoteTimeout: 10000, // milliseconds
        crossOrigin: false,
        selectKeys: [9, 13], // [tab, enter]
        autoHighlight: true, // Automatically highlight the topmost result
      },
      options
    );

    callbacks = domutil.extend({}, defaultCallbacks, callbacks);
    callbacks.insertSuggestionList($results, $input);

    inputEvents.focus = function () {
      // If the blur timer is active, a  is redundant.
      preventBlurTimer || redraw(true);
    };

    inputEvents.blur = function () {
      // If the blur prevention timer is active, refocus the input, since the
      // blur event can not be cancelled.
      if (preventBlurTimer) {
        $input.focus();
      } else {
        // The input has already lost focus, so redraw the suggestion list.
        redraw();
      }
    };

    //TODO
    inputEvents.keydown = function (event) {
      var index = getHighlightedIndex();
      // If an arrow key is pressed and a result is highlighted
      if (
        [38, 40].indexOf(event.keyCode) >= 0 &&
        $results.children.length > 0
      ) {
        event.preventDefault();
        var newIndex;
        var size = domutil.findChildren($results, ".result").length;
        switch (event.keyCode) {
          case 38: // Up arrow
            newIndex = Math.max(0, index - 1);
            break;
          case 40: // Down arrow
            newIndex = Math.min(size - 1, index + 1);
            break;
        }
        disableMouseHighlight = true;
        setHighlighted(newIndex, "key", true);
        return false;
      } else if (
        [27].indexOf(event.keyCode) >= 0 &&
        !event.shiftKey &&
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey
      ) {
        event.preventDefault();
        $input.blur();
        return false;
        //Escape has been pressed - Blur
      } else if (
        [8].indexOf(event.keyCode) >= 0 &&
        $input.selectionStart == 0 &&
        $input.selectionEnd == 0 &&
        !event.shiftKey &&
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey
      ) {
        event.preventDefault();
        callbacks.backSpace();
        return false;
      }
      // A select key has been pressed
      else if (
        options.selectKeys.indexOf(event.keyCode) >= 0 &&
        !event.shiftKey &&
        !event.ctrlKey &&
        !event.altKey &&
        !event.metaKey
      ) {
        if (event.keyCode != 9) event.preventDefault();
        select();
        return event.keyCode == 9; // Never cancel tab
      }
    };

    inputEvents.keyup = inputEvents.click = reprocess;

    // Prevent blur when clicking on group titles, scrollbars etc.,
    // This event is triggered after the others because of bubbling.
    $results.addEventListener("mousedown", () => {
      // Bug in IE where clicking on scrollbar would trigger a blur event for the
      // input field, despite using preventDefault() on the mousedown event.
      // This workaround locks the blur event on the input for a small time.
      clearTimeout(preventBlurTimer);
      preventBlurTimer = setTimeout(function () {
        preventBlurTimer = null;
      }, 50);
      return false;
    });

    // If auto highlight is off, remove highlighting
    $results.addEventListener("mouseleave", () => {
      if (!options.autoHighlight) {
        setHighlighted(-1);
      }
    });

    /*
     * PUBLIC METHODS
     */

    this.getResultCount = function () {
      return domutil.findChildren($results, ".result").length;
    };

    /**
     * Enable this instance.
     */
    this.enable = function () {
      // Turn off the browser's autocompletion
      domutil.setAttributes($input,{
        autocomplete: "OFF",
        "aria-autocomplete": "list" }
      );
      domutil.bind($input, inputEvents);
    };

    /**
     * Disable this instance.
     */
    this.disable = function () {
      domutil.removeAttribute("autocomplete");
      domutil.removeAttribute("aria-autocomplete");
      domutil.hide($results);
      domutil.unbind($input, inputEvents);
    };

    /**
     * Disable and remove this instance. This instance should not be reused.
     */
    this.destroy = function () {
      $results.remove();
      domutil.unbind($input, inputEvents);
      $input.removeData("better-autocomplete");
    };

    /**
     * KPC.
     */
    this.doSearch = function (query) {
      $input.focus();
      clearCache();
      this.setInputText(query);
      reprocess(null);
    };

    this.repeatSearch = function () {
      $input.focus();
      clearCache();
      reprocess(null);
    };

    /**
     * KPC.
     */
    this.setInputText = function (text) {
      if (typeof text !== "undefined") {
        var selectStart = text.indexOf("<select>");
        if (selectStart > -1) {
          var selectEnd = text.indexOf("</select>");
          if (selectStart > -1) {
            var selectedLen = selectEnd - selectStart - "<select>".length;
            var realQuery =
              text.substring(0, selectStart) +
              text.substring(selectStart + "<select>".length, selectEnd) +
              text.substring(selectEnd + "</select>".length);
            $input.value = realQuery;
            $input.setSelectionRange(selectStart, selectStart + selectedLen);
          }
        } else {
          $input.value = text;
        }
      } else {
        $input.value = "";
      }
    };

    /*
     * PRIVATE METHODS
     */

    /**
     * Add an array of results to the cache. Internal methods always reads from
     * the cache, so this method must be invoked even when caching is not used,
     * e.g. when using local results. This method automatically clears as much of
     * the cache as required to fit within the cache limit.
     *
     * @param {String} query
     *   The query to set the results to.
     *
     * @param {Array[]} results
     *   The array of results for this query.
     */
    var cacheResults = function (query, results) {
      cacheSize += results.length;
      // Now reduce size until it fits
      while (
        cacheSize > options.cacheLimit &&
        cacheOrder.length &&
        cacheOrder[0] != query
      ) {
        var key = cacheOrder.shift();
        cacheSize -= cache[key].length;
        delete cache[key];
      }
      if (cache[query]) {
        var newTotalResults = cache[query].concat(results);
        cache[query] = newTotalResults;
      } else {
        cacheOrder.push(query);
        cache[query] = results;
      }
    };

    var clearCache = function () {
      cache = {};
      cacheOrder = [];
      cacheSize = 0;
    };

    /**
     * Set highlight to a specific result item
     *
     * @param {Number} index
     *   The result item's index, or negative if highlight should be removed.
     *
     * @param {String} [trigger]
     *   What triggered the highlight: "mouse", "key" or "auto". If index is
     *   negative trigger may be omitted.
     *
     * @param {Boolean} [autoScroll]
     *   (default=false) If scrolling of the results list should be automated.
     */
    var setHighlighted = function (index, trigger, autoScroll) {
      var prevIndex = getHighlightedIndex();
      var $resultList = domutil.findChildren($results, ".result");
      $resultList.forEach((res) => domutil.removeClass(res, "highlight"));
      if (index < 0) {
        return;
      }
      domutil.addClass($resultList[index], "highlight");

      if (prevIndex != index) {
        var result = getResultByIndex(index);
        callbacks.highlight(domutil.getData(result, "result"), $input, trigger);
      }

      // Scrolling
      var up = index == 0 || index < prevIndex;
      var $scrollTo = $resultList[index];

      if (!autoScroll) {
        return;
      }
      // Scrolling up, then make sure to show the group title
      if (domutil.is(domutil.prev($scrollTo), ".group") && up) {
        $scrollTo = domutil.prev($scrollTo);
      }
      // Is $scrollTo partly above the visible region?//TODO
      // if ($scrollTo.position().top < 0) {
      // console.log("scroll up plz")
      // $results.scrollTop = $scrollTo.position().top + $results.scrollTop;
      // }
      // Or is it partly below the visible region?//TODO
      // else if (
      //   $scrollTo.position().top + $scrollTo.outerHeight() >
      //   $results.height()
      // ) {
      //   $results.scrollTop =
      //     $scrollTo.position().top +
      //     $results.scrollTop +
      //     $scrollTo.outerHeight() -
      //     $results.height();
      // }
    };

    /**
     * Retrieve the index of the currently highlighted result item
     *
     * @returns {Number}
     *   The result's index or -1 if no result is highlighted.
     */
    var getHighlightedIndex = function () {
      var res = domutil.findChildren($results, ".result.highlight");
      if (res.length) res = res[0];
      else return -1;
      var ind = domutil.indexOf(res, ".result");
      return ind;
    };

    /**
     * Retrieve the result object with the specific position in the results list
     *
     * @param {Number} index
     *   The index of the item in the current result list.
     *
     * @returns {Object}
     *   The result object or null if index out of bounds.
     */
    var getResultByIndex = function (index) {
      var $result = domutil.findChildren($results, ".result")[index];
      if (!$result) {
        return; // No selectable element
      }
      return domutil.getData($result, "result");
    };

    /**
     * Select the current highlighted element, if any.
     */
    var select = function () {
      var highlighted = getHighlightedIndex(),
        result = getResultByIndex(highlighted);
      callbacks.select(result, $input);
    };

    /**
     * Fetch results asynchronously via AJAX.
     * Errors are ignored.
     *
     * @param {String} query
     *   The query string.
     */
    var fetchResults = function (query) {
      // Asynchronously fetch remote data
      activeRemoteCalls.push(query);
      callbacks.beginFetching($input);
      callbacks.fetchRemoteData(
        query,
        function (data, isLast) {
          var searchResults = Array.isArray(data) ? data : [];
          cacheResults(query, searchResults);
          if (isLast) {
            // Remove the query from active remote calls, since it's finished
            activeRemoteCalls = activeRemoteCalls.filter(function (value) {
              return value != query;
            });
            if (!activeRemoteCalls.length) {
              callbacks.finishFetching($input);
              if (
                domutil.findChildren($results, ".result").length == 0 &&
                query !== ""
              ) {
                var message = domutil.createElement("li", "result");
                var text = domutil.createElement("div");
                text.style[("margin-top", "5px")];
                text.style[("margin-bottom", "5px")];
                text.innerText = options.noResultsText;
                message.append(text);
                domutil.prepend($results, message);
              }
            }
          }
          redraw();
        },
        options.remoteTimeout,
        options.crossOrigin
      );
    };

    /**
     * Reprocess the contents of the input field, fetch data and redraw if
     * necessary.
     *
     * @param {Object} [event]
     *   The event that triggered the reprocessing. Not always present.
     */
    function reprocess(event) {
      // If this call was triggered by an arrow key, cancel the reprocessing.
      if (
        event &&
        typeof event == "object" &&
        event.type == "keyup" &&
        [38, 40].indexOf(event.keyCode) >= 0
      ) {
        return;
      }
      var query = callbacks.canonicalQuery($input.value, options.caseSensitive);
      clearTimeout(timer);
      // Indicate that timer is inactive
      timer = null;
      redraw();
      if (
        query.length >= options.charLimit &&
        !Array.isArray(cache[query]) &&
        activeRemoteCalls.indexOf(query) == -1
      ) {
        // Fetching is required
        emptyResults();
        timer = setTimeout(function () {
          fetchResults(query);
          timer = null;
        }, options.delay);
      }
    }

    /**
     * Redraws the autocomplete list based on current query and focus.
     *
     * @param {Boolean} [focus]
     *   (default=false) Force to treat the input element like it's focused.
     */
    var redraw = function (focus) {
      var highlightedIndex = getHighlightedIndex();

      var query = callbacks.canonicalQuery($input.value, options.caseSensitive);

      // The query does not exist in db
      if (!Array.isArray(cache[query])) {
        lastRenderedQuery = null;
        emptyResults();
      }
      // The query exists and is not already rendered
      else {
        if (lastRenderedQuery !== query) {
          lastRenderedQuery = query;
        }
        renderResults(cache[query]);
        if (highlightedIndex < 1) {
          if (
            options.autoHighlight &&
            domutil.findChildren($results, ".result").length > 0
          ) {
            setHighlighted(0, "auto");
          }
        }
      }
      // Finally show/hide based on focus and emptiness
      if (domutil.isFocus($input) || focus) {
        callbacks.show($results);
        $results.scrollTop = domutil.getData($results, "scroll-top"); // Reset the lost scrolling
      } else {
        callbacks.hide($results);
        domutil.setData($results, "scroll-top", $results.scrollTop); // Hiding it resets it's scrollTop
      }
    };

    /**
     * KPC.
     */

    var emptyResults = function () {
      domutil.findChildren($results, ".result").forEach((result) => {
        domutil.remove(result);
      });
      clearCache();
      callbacks.afterClear($results);
    };

    this.show = function () {
      domutil.findChildren($results, ".result").forEach((res) => {
        domutil.show(res);
      });
      if (hiddenResults) {
        hiddenResults = false;
      }
    };

    this.hide = function () {
      $results.children.forEach((res) => {
        domutil.hide(res);
      });
      if (!hiddenResults) {
        hiddenResults = true;
      }
    };

    /**
     * Regenerate the DOM content within the results list for a given set of
     * results. Heavy method, use only when necessary.
     *
     * @param {Array[]} results
     *   An array of result objects to render.
     */
    var renderResults = function (results) {
      //var groups = {} // Key is the group name, value is the heading element.
      results.forEach(function (result) {
        if (typeof result != "object") {
          return; // Continue
        }
        if (result.isRendered) {
          return; // Continue (Already rendered)
        }
        var themedResult = callbacks.themeResult(result);
        if (themedResult == null) {
          return; // Continue
        } else if (typeof themedResult == "string") {
          themedResult = domutil.createElementFromHtmlString(themedResult);
        } else if (typeof themedResult != "object") {
          return; // Continue
        }

        // Add the group if it doesn't exist
        var group = callbacks.getGroup(result);
        if (typeof group == "string" && !groups[group]) {
          var $groupHeading = domutil.createElement("li", "group");
          domutil.setAttribute($groupHeading, "aria-hidden", true);
          domutil.hide($groupHeading);
          $groupHeading.append(domutil.createElement("h3", "", group));
          $results.append($groupHeading);
          groups[group] = $groupHeading;
        }

        var $result = domutil.createElement("li", "result");
        if (result.addClass) {
          domutil.addClass($result, result.addClass);
        }
        $result.append(themedResult);
        domutil.setData($result, "result", result);

        if (options.mouseOver) {
          // When the user hovers a result with the mouse, highlight it.

          $result.addEventListener("mouseenter", function () {
            if (disableMouseHighlight) {
              return true;
            }
            // domutil.findChildren(domutil.selector(".result"))
            setHighlighted(domutil.indexOf(this, ".result"), "mouse");
            return true;
          });
          $result.addEventListener("mousemove", function () {
            // Enable mouseover again.
            disableMouseHighlight = false;
            return true;
          });
          $result.addEventListener("click", function () {
            select();
            return true;
          });
        } else {
          $result.addEventListener("mouseup", function () {
            setHighlighted(domutil.indexOf(this, ".result"), "mouse");
            select();
            return true;
          });
        }

        result.isRendered = true;

        // First groupless item
        if (
          typeof group != "string" &&
          !$results.children().first().is(".result")
        ) {
          $results.prepend($result);
          return; // Continue
        }
        var $traverseFrom =
          typeof group == "string"
            ? groups[group]
            : $results.children().first();
        let $target = domutil.nextUntil($traverseFrom, ".group");
        $target = $target.length ? $target[$target.length - 1]: $traverseFrom;
        domutil.insertAfter($result, $target);
      });
    };

    this.createGroups = function (groupsToCreate) {
      //Accepts an array of string
      for (var i = 0; i < groupsToCreate.length; i++) {
        var group = groupsToCreate[i];
        if (typeof group == "string" && !groups[group]) {
          var $groupHeading = domutil.createElement("li", "group");
          domutil.setAttribute($groupHeading, "aria-hidden", true);
          domutil.hide($groupHeading);
          var heading = domutil.createElement("h3");
          heading.innerText = group;
          $groupHeading.append(heading);
          $results.append($groupHeading);
          groups[group] = $groupHeading;
        }
      }
    };
  };

  /*
   * CALLBACK METHODS
   */

  /**
   * These callbacks are supposed to be overridden by you when you need
   * customization of the default behavior. When you are overriding a callback
   * function, it is a good idea to copy the source code from the default
   * callback function, as a skeleton.
   *
   *
   * @private
   * @name callbacks
   * @namespace
   */
  var defaultCallbacks = {
    /**
     * @lends callbacks.prototype
     */

    // Handled in View.js
    afterClear: function ($results) {},

    backSpace: function () {},

    /**
     * Handled in View.js
     */
    select: function (result, $input) {},

    /**
     * Gets fired when the a result is highlighted. This may happen either
     * automatically or by user action.
     *
     * <br /><br /><em>Default behavior: Does nothing.</em>
     *
     * @param {Object} result
     *   The result object that was selected.
     *
     * @param {Object} $input
     *   The input DOM element, wrapped in jQuery.
     *
     * @param {String} trigger
     *   The event which triggered the highlighting. Must be one of the
     *   following:
     *   <ul><li>
     *     "mouse": A mouseover event triggered the highlighting.
     *   </li><li>
     *     "key": The user pressed an arrow key to navigate amongst the results.
     *   </li><li>
     *     "auto": If options.autoHighlight is set, an automatic highlight of the
     *     first result will occur each time a new result set is rendered.
     *   </li></ul>
     */
    highlight: function (result, $input, trigger) {
      // Does nothing
    },

    /**
     * Handled in View.js
     */
    themeResult: function (result) {},

    /**
     * Handled in View.js
     */
    fetchRemoteData: function (url, completeCallback, timeout, crossOrigin) {},

    /**
     * Handled in View.js
     */
    getGroup: function (result) {},

    /**
     * Handled in View.js
     */
    beginFetching: function ($input) {},

    /**
     * Handled in View.js
     */
    finishFetching: function ($input) {},

    /**
     * Handled in View.js
     */
    show: function (results) {},

    /**
     * Handled in View.js
     */
    hide: function (results) {},

    /**
     * To ease up on server load, treat similar strings the same.
     *
     * <br /><br /><em>Default behavior: Trims the query from leading and
     * trailing whitespace.</em>
     *
     * @param {String} rawQuery
     *   The user's raw input.
     *
     * @param {Boolean} caseSensitive
     *   Case sensitive. Will convert to lowercase if false.
     *
     * @returns {String}
     *   The canonical query associated with this string.
     */
    canonicalQuery: function (rawQuery, caseSensitive) {
      var query = rawQuery.trim();
      if (!caseSensitive) {
        query = query.toLowerCase();
      }
      return query;
    },

    /**
     * Handled in View.js
     */
    insertSuggestionList: function ($results, $input) {},
  };

  /*
   * jQuery focus selector, required by Better Autocomplete.
   *
   * @see http://stackoverflow.com/questions/967096/using-jquery-to-test-if-an-input-has-focus/2684561#2684561
   */
  // var filters = $.expr[":"];
  // if (!filters.focus) {
  //   filters.focus = function (elem) {
  //     return elem === document.activeElement && (elem.type || elem.href);
  //   };
  // }
  //init
  // var $input = $(input)
  // var bac = new BetterAutocomplete($input, options, callbacks);
  domutil.setData(input, "better-autocomplete", bac);
  var bac = new BetterAutocomplete(input, options, callbacks);
  bac.enable();
  return bac;
}
