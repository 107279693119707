/**
 * @module
 */
import ResultType from "../ResultType"
import Searcher from './Searcher'


/**
 *
 * @extends module:js/searchers/Searcher
 * @example <caption>YAML Declaration: <a href='../examples/fkgsearcher/'>Example</a></caption>
  _type: Septima.Search.FkgSearcher
  _options:
    minimumShowCount: 3

    targets:
      - t_5710_born_skole_dis_t
 * @example <caption> JS options: </caption>
 options = {

  };
 * @example <caption>js client:</caption>
 * // Include septimaSearch
 * <script type="text/javascript" src="http://search.cdn.septima.dk/{version}/septimasearch.min.js"/>
 * controller.addSearcher(new Septima.Search.FkgSearcher(options))
 *
 * @example <caption>ES6:</caption>
 * import FkgSearcher from './searchers/FkgSearcher'
 * controller.addSearcher(new FkgSearcher(options))
 */


export default class FkgSearcher extends Searcher {
  /**
   *
   * @param {Object} options FkgSearcher expects these properties:

   * @api
   */
  constructor(options) {
    super(options)
    this.source = "fkg"
    if (options.source) 
      this.source = options.source
    
    this.targets = ['t_5710_born_skole_dis_t']
    if (typeof options !== 'undefined' && typeof options.targets !== 'undefined')
      this.targets = options.targets
    
    this.types = {
      "t_5710_born_skole_dis_t": new ResultType({id: "t_5710_born_skole_dis_t", singular: "Skoledistrikt", plural: "Skoledistrikter", queryBehaviour: "match", featuretype:"fkg.t_5710_born_skole_dis"})
    }

    this.registerType(this.source, this.types.t_5710_born_skole_dis_t)
    

  }

  async completeResult(result) {
    if (result.isComplete) {
      return result
    } else {
      let gotResult = await this.get(result.id, result.typeId)
      result.geometry = gotResult.geometry
      result.data = gotResult.data
      result.isComplete = true
      return result
    }
  }


  async fetchData(query, caller) {
    const queryResult = this.createQueryResult()
    

    let fetchPromises = []
    //if (!query.hasTarget) 
    //  fetchPromises.push(this.fetch_t_5710_born_skole_dis_t_ById(query.queryString))
    
    //Process fetch promises
    let featuresArray = await Promise.all(fetchPromises)
    for ( let features of featuresArray ) 
      if (features.length === 1)
        this.addFeatureToQueryResult(features[0], queryResult)


    caller.fetchSuccess(queryResult)
  }
  
  async get(id, type) {
    let queryResult = this.createQueryResult()

    if (type === this.types.t_5710_born_skole_dis_t.id) {
      let features = await this.fetch_t_5710_born_skole_dis_t_ById(id)
      if (features.length === 1)
        this.addFeatureToQueryResult(features[0], queryResult)
    }else{
      throw new Error('Whoops! get(id) is not implemented for ' + type)
    }
    
    let results = queryResult.getResults()
    if (results.length === 1)
      return results[0]
  }
  
  addFeatureToQueryResult(feature, queryResult) {

    let typeId = feature.properties.temanavn
    let title
    let description

   
    if  (typeId==this.types.t_5710_born_skole_dis_t.id) {
      title = feature.properties.udd_distrikt_navn
      description = `${feature.properties.udd_distrikt_type}: ${feature.properties.starttrin} - ${feature.properties.slutttrin} `
    }     
    let geometry = feature.geometry
    let result = queryResult.addResult(this.source, typeId, title, description, geometry, feature)
    result.id = feature.properties.objekt_id
    return result
  }


  getTypenameFromTarget(target, types) {
    let typeName
    Object.entries(types).forEach(entry => {
      let resultType = entry[1]
      if (resultType.id === target)
        typeName = resultType.values.featuretype
    })
    return typeName
  }
  
  async sq(query) {
    //console.log(query)
    let queryResult = this.createQueryResult()
    let fetchPromises = []
    let typeNamesAbekat = []
    let wkt = query.wkt
    

    if (this.targets.indexOf('t_5710_born_skole_dis_t') > -1)
      typeNamesAbekat.push(this.getTypenameFromTarget('t_5710_born_skole_dis_t', this.types))
    
    
    if (typeNamesAbekat.length > 0)
      fetchPromises.push(this.fetchFeatures(typeNamesAbekat.join(","), wkt))
    


    //Process fetch promises
    let featuresArray = await Promise.all(fetchPromises)
    for ( let features of featuresArray )
      for (let feature of features) {
        let result = this.addFeatureToQueryResult(feature, queryResult)
        result.distance = 0
      }
    return queryResult
  }

  async fetch_t_5710_born_skole_dis_t_ById(id) {
    let response = await this.fetch(`https://fkg.mapcentia.com/api/v2/sql/fkg?srs=25832&q=SELECT%20*%20FROM%20fkg.t_5710_born_skole_dis%20WHERE%20objekt_id='${id}'`)
    if (response && response.features)
      return response.features
    else 
      return []
  }
  
  async fetchFeatures(typename, wkt) {

    let endPoint  = "https://fkg.mapcentia.com/api/v2/sql/fkg"
    let q= `SELECT * FROM ${typename} WHERE st_intersects(geometri,st_setsrid(st_geometryFromText('${wkt}'),25832))`
    let result
    result = await this.fetch(`${endPoint}?srs=25832&q=${q}`)

    return result.features
  }
}
