export default class CoordinateSearchProvider {
  //This search provider returns a result if the query represents a coordinate.
  // The provider is projection agnostic:
  //  A result is returned if the query can be parsed as two numbers AND
  //  these numbers are within allowed boundaries


  constructor(options) {
    //options optional
    //options.boundaries (optional)
    // Boundaries object: Coordinates are validated
    // NULL: Coordinates are NOT validated
    if (options && typeof options.boundaries !== 'undefined') {
      this.boundaries = options.boundaries
    } else {
      //Default allowed boundaries (Extent of Denmark in epsg:25832)
      let defaultBoundaries = {
        firstLower: 430000,
        firstUpper: 950000,
        secondLower: 6000000,
        secondUpper: 6500000,
        description: 'Coordinate in epsg:25832 in Denmark' //Description is optional
      }
      this.boundaries = defaultBoundaries
    }
  }

  query(query) {
    let response = {status: 'ok', hits: 0, results: []}
    let coordinateArray = this.createValidCoordinateArray(query)
    if (coordinateArray !== null) {
      response.results.push(this.createCoordinateResult(query, coordinateArray))
      response.hits = 1
    }
    return response
  }

  createValidCoordinateArray(string) {
    let parts = string.split(' ')
    if (parts.length === 1) 
      parts = string.split(',')
    
    if (parts.length === 2) {
      let firstPart = parseFloat(parts[0])
      let secondPart = parseFloat(parts[1])
      if (!isNaN(firstPart) && !isNaN(secondPart)) 
        if (this.boundaries === null) 
          return [firstPart, secondPart]
        else if (
          firstPart  > this.boundaries.firstLower &&
          firstPart  < this.boundaries.firstUpper &&
          secondPart > this.boundaries.secondLower &&
          secondPart < this.boundaries.secondUpper) 
          return [firstPart, secondPart]
        
      
    }
    return null
  }

  get(coordinates) {
    let coordinateArray = this.createValidCoordinateArray(coordinates)
    return this.createCoordinateResult(coordinates, coordinateArray)
  }
  
  createCoordinateResult(title, coordinateArray) {
    let result = {
      title: title,
      geometry: {
        'type': 'Point',
        'coordinates': coordinateArray
      },
      id: coordinateArray.join(",")
    }
    if (this.boundaries !== null && this.boundaries.description) 
      result.description = this.boundaries.description
    
    return result
  }

}