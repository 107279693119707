import DetailsHandlerDef from "../../details/DetailsHandlerDef"
import {fetch2} from "../../utils"
import icons from "../../resources/icons"
import DetailItemsList from "../../details/DetailItemsList"

export default class TjekDitNetForAdresseProvider extends DetailsHandlerDef {
  constructor(options = {}) {
    let defaultOptions = {
      buttonText: "Tjek Dit Net",
      buttonImage: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyMzAuMDM0IDEyOS42NjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIzMC4wMzQgMTI5LjY2NTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGNpcmNsZSBzdHlsZT0iZmlsbDojMDAwMDAwOyIgY3g9IjExNC45MzQiIGN5PSIxMTQuNjgzIiByPSIxNC45ODMiLz4NCgkNCgkJPHJlY3QgeD0iMTU3LjM5OCIgeT0iMC4xMjEiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzcxNyAwLjYzNiAtMC42MzYgMC43NzE3IDcxLjg1OTYgLTkyLjMzODQpIiBzdHlsZT0iZmlsbDojMDAwMDAwOyIgd2lkdGg9IjE0LjI3NCIgaGVpZ2h0PSIxMDcuNTg0Ii8+DQoJPGc+DQoJCTxwYXRoIHN0eWxlPSJmaWxsOiMwMDAwMDA7IiBkPSJNMTcyLjUxLDE1LjAwOUMxNTUuNjA4LDUuMTEsMTM1LjkzNSwwLDExNC45MzQsMEM1MS45MTgsMCwwLjgzNCw1MC42NywwLjgzNCwxMTQuNjdIMjkuMDcNCgkJCWMwLTI0LDguOTMxLTQ1LjA2NywyNS4xNDktNjEuMjg0YzE2LjIxNy0xNi4yMTcsMzcuNzc5LTI1LjQzNCw2MC43MTQtMjUuNDM0YzE0LjAwNiwwLDI3LjQ5OCwzLjE5NCwzOS41NzUsOS40ODFMMTcyLjUxLDE1LjAwOXoiDQoJCQkvPg0KCTwvZz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojMDAwMDAwOyIgZD0iTTIwMS41ODcsMzkuMzExbC0xOC4zMTcsMjIuMjIzYzExLjM2NiwxNC44NzMsMTcuNTI2LDMzLjk1OCwxNy41MjYsNTIuOTk0aDI4LjIzNw0KCQlDMjI5LjAzNCw4Ni4xODYsMjE4LjY5Myw1OS4yNjQsMjAxLjU4NywzOS4zMTF6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==",
      more: false
    }
    super(Object.assign(defaultOptions, options))

    this.isApplicableFunction = this.isApplicable
    this.handlerFunction = this.myHandler
  }

  isApplicable(result) {
    return (result.source === "Dawa" && result.typeId === "adresse")
  }

  async myHandler(dawaResult) {

    let items = []
    
    let adresseId = dawaResult.data.properties.id
    let tjekDitNetResult = await fetch2('https://tjekditnet.dk/pls/wopdprod/tdn_feed?uid=651157&adgadrid=' + adresseId + '&format=json') 
    for (let daekningerArray of tjekDitNetResult.daekninger) {
      let daekningArray = daekningerArray.daekning
      for (let daekning of daekningArray) {
        let detailItemsList = new DetailItemsList({itemType: "labelvalue", header: daekning.teknologi, isHomogenous: false})
        if (daekning.download_udt_privat_mbits)
          detailItemsList.append({
            type: "labelvalue",
            label: "Download, privat",
            value: daekning.download_udt_privat_mbits,
            valueformat: "int"
          })
        if (daekning.upload_udt_privat_mbits)
          detailItemsList.append({
            type: "labelvalue",
            label: "Upload, privat",
            value: daekning.upload_udt_privat_mbits,
            valueformat: "int"
          })
        if (daekning.download_udt_erhverv_mbits)
          detailItemsList.append({
            type: "labelvalue",
            label: "Download, erhverv",
            value: daekning.download_udt_erhverv_mbits,
            valueformat: "int"
          })
        if (daekning.upload_udt_erhverv_mbits)
          detailItemsList.append({
            type: "labelvalue",
            label: "Upload, erhverv",
            value: daekning.upload_udt_erhverv_mbits,
            valueformat: "int"
          })
        items.push(detailItemsList.asItem())
      }
    }
    if (items.length > 0) {
      let headerItem ={
        type: "labelvalue",
        icon: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyMzAuMDM0IDEyOS42NjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIzMC4wMzQgMTI5LjY2NTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGNpcmNsZSBzdHlsZT0iZmlsbDojMDAwMDAwOyIgY3g9IjExNC45MzQiIGN5PSIxMTQuNjgzIiByPSIxNC45ODMiLz4NCgkNCgkJPHJlY3QgeD0iMTU3LjM5OCIgeT0iMC4xMjEiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzcxNyAwLjYzNiAtMC42MzYgMC43NzE3IDcxLjg1OTYgLTkyLjMzODQpIiBzdHlsZT0iZmlsbDojMDAwMDAwOyIgd2lkdGg9IjE0LjI3NCIgaGVpZ2h0PSIxMDcuNTg0Ii8+DQoJPGc+DQoJCTxwYXRoIHN0eWxlPSJmaWxsOiMwMDAwMDA7IiBkPSJNMTcyLjUxLDE1LjAwOUMxNTUuNjA4LDUuMTEsMTM1LjkzNSwwLDExNC45MzQsMEM1MS45MTgsMCwwLjgzNCw1MC42NywwLjgzNCwxMTQuNjdIMjkuMDcNCgkJCWMwLTI0LDguOTMxLTQ1LjA2NywyNS4xNDktNjEuMjg0YzE2LjIxNy0xNi4yMTcsMzcuNzc5LTI1LjQzNCw2MC43MTQtMjUuNDM0YzE0LjAwNiwwLDI3LjQ5OCwzLjE5NCwzOS41NzUsOS40ODFMMTcyLjUxLDE1LjAwOXoiDQoJCQkvPg0KCTwvZz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojMDAwMDAwOyIgZD0iTTIwMS41ODcsMzkuMzExbC0xOC4zMTcsMjIuMjIzYzExLjM2NiwxNC44NzMsMTcuNTI2LDMzLjk1OCwxNy41MjYsNTIuOTk0aDI4LjIzNw0KCQlDMjI5LjAzNCw4Ni4xODYsMjE4LjY5Myw1OS4yNjQsMjAxLjU4NywzOS4zMTF6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==",
        label: "Internethastighed (mbits)"
      }
      let footerItemItem ={
        type: "link",
        icon: icons.exlink,
        link: "http://tjekditnet.dk",
        linkTitle: "TjekDitNet.dk",
      }
      return [headerItem, ...items, footerItemItem]
    } else {
      items.push ({
        type: "labelvalue",
        icon: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE4LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAyMzAuMDM0IDEyOS42NjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDIzMC4wMzQgMTI5LjY2NTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPGNpcmNsZSBzdHlsZT0iZmlsbDojMDAwMDAwOyIgY3g9IjExNC45MzQiIGN5PSIxMTQuNjgzIiByPSIxNC45ODMiLz4NCgkNCgkJPHJlY3QgeD0iMTU3LjM5OCIgeT0iMC4xMjEiIHRyYW5zZm9ybT0ibWF0cml4KDAuNzcxNyAwLjYzNiAtMC42MzYgMC43NzE3IDcxLjg1OTYgLTkyLjMzODQpIiBzdHlsZT0iZmlsbDojMDAwMDAwOyIgd2lkdGg9IjE0LjI3NCIgaGVpZ2h0PSIxMDcuNTg0Ii8+DQoJPGc+DQoJCTxwYXRoIHN0eWxlPSJmaWxsOiMwMDAwMDA7IiBkPSJNMTcyLjUxLDE1LjAwOUMxNTUuNjA4LDUuMTEsMTM1LjkzNSwwLDExNC45MzQsMEM1MS45MTgsMCwwLjgzNCw1MC42NywwLjgzNCwxMTQuNjdIMjkuMDcNCgkJCWMwLTI0LDguOTMxLTQ1LjA2NywyNS4xNDktNjEuMjg0YzE2LjIxNy0xNi4yMTcsMzcuNzc5LTI1LjQzNCw2MC43MTQtMjUuNDM0YzE0LjAwNiwwLDI3LjQ5OCwzLjE5NCwzOS41NzUsOS40ODFMMTcyLjUxLDE1LjAwOXoiDQoJCQkvPg0KCTwvZz4NCgk8cGF0aCBzdHlsZT0iZmlsbDojMDAwMDAwOyIgZD0iTTIwMS41ODcsMzkuMzExbC0xOC4zMTcsMjIuMjIzYzExLjM2NiwxNC44NzMsMTcuNTI2LDMzLjk1OCwxNy41MjYsNTIuOTk0aDI4LjIzNw0KCQlDMjI5LjAzNCw4Ni4xODYsMjE4LjY5Myw1OS4yNjQsMjAxLjU4NywzOS4zMTF6Ii8+DQo8L2c+DQo8L3N2Zz4NCg==",
        label: "Internethastighed (mbits)"
      })
      items.push ({
        type: "labelvalue",
        label: "Desværre ingen information for adressen"
      })
      items.push ({
        type: "link",
        icon: icons.exlink,
        link: "http://tjekditnet.dk",
        linkTitle: "TjekDitNet.dk",
      })
      
      return items
    }
  }
}